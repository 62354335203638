<form [formGroup]="form" (ngSubmit)="navigateProductionOrder()">
  <p-table [value]="getUrdidoresArray.controls" dataKey="controls.id.value" [tableStyle]="{ 'min-width': '40rem' }"
    styleClass="p-datatable-sm" [style]="{
    'max-height': '70vh',
    'overflow-y': 'auto',
  }" [scrollable]="true" [rows]="size" [customSort]="true" scrollHeight="70vh" selectionMode="single">
    <ng-template pTemplate="header">
      <tr>
        <th class="td m-0 p-0" style="width: 20%">
          Articulo
        </th>
        <th class="td m-0 p-0" style="width: 20%">
          Género
        </th>
        <th class="td m-0 p-0" style="width: 20%">
          Cantidad
        </th>
        <th class="td m-0 p-0" style="width: 20%">
          Tejeduria
        </th>
        <th class="td m-0 p-0" style="width: 20%">
          Urdidor
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-articulo let-rowIndex="rowIndex" formArrayName="urdidoresArray">
      <tr [formGroupName]="rowIndex">
        <td class="td m-0 p-0 truncate fw-bold"> {{getUrdidoresArray.controls[rowIndex].get('articulo').value.nombre}}</td>
        <td class="td m-0 p-0 truncate"> {{getUrdidoresArray.controls[rowIndex].get('tela').value.nombre}}</td>
        <td class="td m-0 p-0 truncate">{{getUrdidoresArray.controls[rowIndex].get('cantidad').value.toFixed(2)}}</td>
        <td class="td m-0 p-0 truncate fw-bold">{{getUrdidoresArray.controls[rowIndex].get('tejeduria').value}}</td>
        <td class="td m-0 p-0">
          <div class="mt-3">
            <p-dropdown styleClass="p-dropdown p-dropdown-items height" [showClear]="articulo.controls.urdidor.value" appendTo="body" class="w-100"
              [options]="articulo.controls.urdidores.value || []"
              [style]="{ width: '100%',border: '2px solid #F0F47E' }" [placeholder]="
              '- Urdidor - '
            " optionLabel="nombre" formControlName="urdidor">
              <ng-template let-option pTemplate="item">
                <span [class]="'urdidor-' + option.id">{{
                  option.nombre
                  }}</span>
              </ng-template>
            </p-dropdown>
            <span class="danger" *ngIf="
              getUrdidoresArray?.controls[rowIndex]
                ?.get('urdidor')
                ?.hasError('required') &&
                getUrdidoresArray?.controls[rowIndex]?.get('urdidor')?.touched
            ">
              <small class="p-error">Este campo es <strong>requerido</strong></small>
            </span>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="loadingbody" let-rowData>
      <tr class="h-100 w-100">
        <td class="m-0 p-0" colspan="13">
          <div class="text-center">
            <span>
              <p-progressSpinner></p-progressSpinner>
            </span>
            <br />
            <span class="text-center">Cargando...</span>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" class="w-100">
      <tr>
        <td colspan="12">
          <p-messages class="w-100" [value]="[{ severity: 'info', detail: 'No hay pedidos' }]" [enableService]="false"
            [closable]="false">
          </p-messages>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <div class="row d-flex justify-content-center my-5">
    <div class="col-12 text-end">
      <p-button type="button" styleClass="p-button-rounded p-button-sm  p-button-secondary add-order-warped"
        class="my-3 mx-3" icon="pi pi-plus" iconPos="left" label="Agregar orden"
        (onClick)="addOrderWarped()"></p-button>
      <p-button [disabled]="form.invalid" type="submit" styleClass="p-button-sm p-button-secondary button-urdido"
        class="my-3 mx-3" icon="pi pi-arrow-right" iconPos="right" label="Asignar materia prima"></p-button>
    </div>
  </div>
  <p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>
</form>