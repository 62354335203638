import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CharRadarComponent } from './graphics/char-radar/char-radar.component';
import { ChartBarComponent } from './graphics/chart-bar/chart-bar.component';
import { ChartDoughnutComponent } from './graphics/chart-doughnut/chart-doughnut.component';
import { ChartLineComponent } from './graphics/chart-line/chart-line.component';
import { PrimengModule } from './primeng/primeng.module';
import { FiltersComponent } from './filters/filters.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReordableRowDrop } from './directives/reordable-row-drop.directive';
import { DepositosDisponiblesDirective } from './directives/repeat-element-dropdown.directive';
import { OrdenarPorNombreDirective } from './directives/order-deposits.directive';



@NgModule({
  declarations: [CharRadarComponent, ChartBarComponent, ChartDoughnutComponent, ChartLineComponent, FiltersComponent, ReordableRowDrop, DepositosDisponiblesDirective, OrdenarPorNombreDirective],
  imports: [
    CommonModule, PrimengModule, HttpClientModule, FormsModule, ReactiveFormsModule
  ],
  exports: [CharRadarComponent, ChartBarComponent, ChartDoughnutComponent, ChartLineComponent, FiltersComponent, PrimengModule, HttpClientModule, FormsModule, ReactiveFormsModule, ReordableRowDrop, DepositosDisponiblesDirective, OrdenarPorNombreDirective]
})
export class SharedModule { }
