import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService, SortEvent } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Item } from 'src/app/shared/models/model-forms/item.interface';
import { AddQuantityWeavingComponent } from '../../modals/add-quantity-weaving/add-quantity-weaving.component';
import { WeavingService } from 'src/app/api/services/production/weaving/weaving.service';
import { concat, of } from 'rxjs';

@Component({
  selector: 'app-table-production-weaving-planning',
  templateUrl: './table-production-weaving-planning.component.html',
  styleUrls: ['./table-production-weaving-planning.component.css'],
})
export class TableProductionWeavingPlanningComponent
  implements OnInit, AfterViewInit {
  @Input()
  telas: any = [];

  selectTelas: any[] = [];

  showPopUpAddOrder = false;
  loadingSelectOrders = false;

  @Input()
  size = 10;

  prioritys: Item[] = [
    {
      label: 'BAJA',
      value: 'BAJA',
    },
    {
      label: 'MEDIA',
      value: 'MEDIA',
    },
    {
      label: 'ALTA',
      value: 'ALTA',
    },
  ];

  @Output()
  eventRefreshItems: EventEmitter<any> = new EventEmitter()

  constructor(
    private messageService: MessageService,
    private router: Router,
    private dialogService: DialogService,
    private dialogRef: DynamicDialogRef,
    private weavingService: WeavingService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }
  ngAfterViewInit(): void { }

  ngOnInit(): void {
    this.selectTelas = [...this.telas];
  }

  loadOrderLazy(event: any) {
    //simulate remote connection with a timeout
    setTimeout(() => {
      //load data of required page
      let loadedOrders = this.telas.slice(
        event.first,
        event.first + event.rows
      );

      //populate page of virtual cars
      Array.prototype.splice.apply(this.selectTelas, [
        ...[event.first, event.rows],
        ...loadedOrders,
      ]);

      //trigger change detection
      event.forceUpdate();
    }, Math.random() * this.selectTelas.length + this.size);
  }

  showModalAddOrder() {
    this.showPopUpAddOrder = true;
  }

  showModalAddQuantity() {
    let objetoConMayorId = this.telas.reduce((objetoMaximo, objetoActual) => {
      return objetoActual.id > objetoMaximo.id ? objetoActual : objetoMaximo;
    }, this.telas[0]) || 0;

    this.dialogRef = this.dialogService.open(AddQuantityWeavingComponent, {
      width: '70%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: false,
      data: {
        objetoConMayorId
      },
    });

    this.dialogRef.onClose.subscribe((result) => {
      if (result && result.success && result.addTela) {
        let idMayor = this.telas[0]?.id || 0;

        for (let i = 1; i < this.telas.length; i++) {
          if (this.telas[i].id > idMayor) {
            idMayor = this.telas[i].id;
          }
        }

        const nuevoId = idMayor + 1;

        result.addTela.new = true;
        result.addTela.id = nuevoId;

        this.telas.unshift(result.addTela)
        // this.selectTelas.unshift(result.addTela)
        // this.eventRefreshItems.emit(true);
        // this.changeDetectorRef.detectChanges();

      }
    });
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) result = -1;
      else if (value1 != null && value2 == null) result = 1;
      else if (value1 == null && value2 == null) result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

      return event.order * result;
    });
  }

  navigateProductionOrder() {
    let ordenes = []
    let nuevas = []

    let ordenesList = []
    let ordenesNuevas = []
    concat(
      of(
        this.loadingSelectOrders = true
      ),
      of(
        ordenes = this.selectTelas.filter((element) => { return element.id && !element.new }),
        nuevas = this.selectTelas.filter((element) => { return element.id && element.new })
      ),
      of(
        ordenesList = ordenes.map((element) => {
          return element.ordenesList;
        }).flat(Infinity)

      ),
      of(
        ordenesNuevas = nuevas.map((element) => {
          return {
            cantidad: element.cantidad,
            telaId: element.tela.id
          }
        })
      ),
      of(
        console.log({ ordenesList, ordenesNuevas })
      ),
      of(
        
        this.messageService.add({
          severity: 'success',
          summary: 'Hecho!',
        }),
        this.router.navigate(['/production/weaving/order-planning'], {
          state: {
            object: { ordenesList, ordenesNuevas },
          },
        })
      )).subscribe()

    // this.weavingService.asignacionWeaving({ ordenesList, ordenesNuevas }).subscribe({
    //   next: (data) => {
    //     telas = data
    //     this.messageService.add({
    //       severity: 'success',
    //       summary: 'Asignado!',
    //     });
    //   }, error: (err) => {
    //     this.messageService.add({
    //       severity: 'error',
    //       summary: 'Error',
    //       detail: err.error ? err.error.message : 'Ups! Ocurrio un error',
    //     });
    //   },
    //   complete: () => {

    //   }
    // })


  }
}
