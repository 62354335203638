import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Item } from 'src/app/shared/models/model-forms/item.interface';
import { totalQuantityValidator } from '../../../../../shared/validators/totalQuantity.validator';

@Component({
  selector: 'app-assign-machine',
  templateUrl: './assign-machine.component.html',
  styleUrls: ['./assign-machine.component.css'],
})
export class AssignMachineComponent implements OnInit, OnDestroy {
  order: any;
  index:any;

  form: FormGroup;

  spinner = true;

  machines: Item[] = [
    {
      label: 'ATM',
      value: 'ATM',
    },
    {
      label: 'SOFT',
      value: 'SOFT',
    },
  ];

  constructor(
    private dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.order = this.dialogConfig.data.order;
    this.index = this.dialogConfig.data.index;

    this.form = new FormGroup(
      {
        divideBathroomsArray: new FormArray([this.createDivideBathRooms()]),
        originalQuantity: new FormControl(this.order.cantidad),
      },
      { validators: totalQuantityValidator }
    );

    this.spinner = false;
  }

  ngOnDestroy(): void {}

  createDivideBathRooms() {
    return new FormGroup({
      quantity: new FormControl('', [Validators.required, Validators.min(1)]),
      machine: new FormControl('', [Validators.required]),
    });
  }

  get getDivideBathRoomsArray(): FormArray {
    return this.form.get('divideBathroomsArray') as FormArray;
  }

  addDivideBathRooms() {
    this.getDivideBathRoomsArray.push(this.createDivideBathRooms());
  }

  deleteDivideBathRooms(index: any) {
    this.getDivideBathRoomsArray.removeAt(index);
  }

  divideBathroomsSubmit() {
    let assign = [];
    for (let i = 0; i < this.getDivideBathRoomsArray.controls.length; i++) {
      let cantidad = this.getDivideBathRoomsArray.controls[i].value.quantity;
      let maquina:any = this.getDivideBathRoomsArray.controls[i].value.machine;

      let banios = 1;
      let kilosPorBanio = this.getDivideBathRoomsArray.controls[i].value.machine.kilosPorBanio
  
      if (kilosPorBanio) {
        while ((kilosPorBanio * banios) < cantidad) {
          banios++;
        }
      }

      assign.push({
        "banios" : banios,
        "cantidad" : cantidad,
        "kilosPorBanio" : kilosPorBanio,
        "colorId": this.order.colorId,
        "maquinaId": maquina,
        "procesoId" : this.order.procesoId,
        "telaId": this.order.telaId,
        "tipo": this.order.tipo,
        "items" : this.order.items
      });
    }

    this.order.maquinaMock = assign;

    this.messageService.add({
      severity: 'success',
      summary: 'Hecho!',
    });

    
    this.dialogRef.close({ success: true, order: this.order,assign,index : this.index });
  }
}
