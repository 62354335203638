<div class="container-fluid background-container" [style.background-image]="'url(assets/img/' + backgroundName + ')'">
    <div class="row">
        <div class="col-1 my-auto mb-auto float-start">
            <a
            [routerLink]="routerBack || '/home'"
            class="text-dark text-decoration-none h4"
            pTooltip="Volver"
          >
            <i class="pi pi-arrow-left" [style.font-size]="fontSize  + 'rem'"></i>
          </a>
        </div>
        <div class="col-9 col-lg-11 text-end">
            <h1 [class]="'text-dark py-4 ' + sizeTitleClass">{{title}}</h1>
        </div>
        <!-- <div class="col-1 col-lg-2 float-end">
            <div class="dropdown py-4 text-end">
                <a href="#" class="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
                    <img src="https://github.com/mdo.png" alt="hugenerd" width="30" height="30" class="rounded-circle">
                    <span class="d-sm-inline mx-1">Name</span>
                </a>
                <ul class="dropdown-menu dropdown-menu-dark text-small shadow">
                    <li><a class="dropdown-item" href="#">Item 1</a></li>
                    <li><a class="dropdown-item" href="#">Item 2</a></li>
                    <li><a class="dropdown-item" href="#">Item 3...</a></li>
                    <li>
                        <hr class="dropdown-divider">
                    </li>
                    <li><a class="dropdown-item" href="#">Cerrar sesión</a></li>
                </ul>
            </div>
        </div> -->
    </div>
</div>
