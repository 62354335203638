import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { OrderService } from 'src/app/api/services/order/order.service';
import { Order } from 'src/app/shared/models/order/order.class';

@Component({
  selector: 'app-date-promise-modal',
  templateUrl: './date-promise-modal.component.html',
  styleUrls: ['./date-promise-modal.component.css'],
})
export class DatePromiseModalComponent {
  spinner = true;

  order: Order = new Order();

  form: FormGroup;

  constructor(
    public messageService: MessageService,
    public dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
    private orderService: OrderService,
    private datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.order = this.dialogConfig.data.order;
    this.startForm();
    this.spinner = false;
  }

  ngOnDestroy(): void {
    this.spinner = true;
  }

  startForm() {
    this.form = new FormGroup({
      observaciones: new FormControl('', []),
    });
  }

  sendForm() {
    let observaciones = this.form.get('observaciones').value;

    if (!observaciones) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Deben completar al menos un campo',
      });
      return;
    }

    this.orderService
      .updateDatePromiseOrder(this.order, { observaciones })
      .subscribe({
        next: (data) => {
          this.messageService.add({
            severity: 'success',
            summary: 'Guardado',
          });
        },
        error: (err)=> {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: err.error ? err.error.message : 'Ups! Ocurrio un error',
          });
        },
        complete : ()=> {
          this.dialogRef.close({ success: true });
        },
      });
  }
}
