<app-banner [sizeTitleClass]="'h6'" [title]="'Urdido'" [backgroundName]=""
  [fontSize]="1" [routerBack]="'/production'"></app-banner>


<div class="card text-center" *ngIf="spinner">
  <span>
    <p-progressSpinner></p-progressSpinner>
  </span>
  <br />
  <span class="text-center">Cargando...</span>
</div>



<div class="container-fluid mt-3" *ngIf="!spinner">
  <!-- <div class="row">
      <div class="col-12">
        <app-filters [filters]="filters"></app-filters>
      </div>
    </div> -->
  <div class="row mt-3">
    <div class="col-12">
      <app-table-production-warped-planning [size]="size" [articulos]="articulos">
      </app-table-production-warped-planning>
    </div>
  </div>
</div>