import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ProductionService } from 'src/app/api/services/production/production.service';
import { FilterEnum } from 'src/app/shared/filters/enum/filters.enum';
import { Method } from 'src/app/shared/filters/enum/method.enum';
import { FilterCustom, Filters } from 'src/app/shared/filters/interface/filters.interface';
import { FilterService } from 'src/app/shared/filters/services/filter.service';
import { DataService } from 'src/app/api/services/data/data.service';

@Component({
  selector: 'app-production-cleaning-planning',
  templateUrl: './production-cleaning-planning.component.html',
  styleUrls: ['./production-cleaning-planning.component.css'],
})
export class ProductionCleaningPlanningComponent implements OnInit {
  spinner = true;

  orders: any[] = [];

  paginate: any = 1;

  totalRecords = 1;

  size = 50;

  query: any = [];
  articles: any = [];
  colors: any = [];
  clientes: any = [];

  filters: Filters = {
    autoSend: true,
    method: Method.GET,
    filtersCustom: [
      {
        type: FilterEnum.DATE,
        col: 'col-12 col-md-4 col-lg-3 my-3 my-lg-0',
        title: '',
        placeholder: 'Fecha: desde - hasta',
        titleClass: '',
        inputStyle: {
          height: '20%',
          width: '100%',
          'border-radius': '50rem',
        },
        nameFilter: ['inicioFechaDeEntrega', 'finFechaDeEntrega'],
        valueFilter: '',
        order: 1,
        calendar: {
          modeCalendar: 'range',
          inputStyle: {
            height: '50%',
            'border-radius': '50rem',
          },
          inputStyleClass: 'height',
          dateFormat: 'dd/mm/yy',
          multipleFilter: true,
          showClear: true
        },
      },
      {
        type: FilterEnum.DROPDOWN,
        col: 'col-12 col-md-4 col-lg-3 my-3 my-lg-0',
        title: '',
        placeholder: 'Articulo',
        titleClass: '',
        styleGeneral: {
          width: '100%',
          'border-radius': '50rem',
        },
        nameFilter: 'telaId',
        valueFilter: '',
        order: 2,
        items: {
          label: 'nombre',
          value: 'id',
          search: true,
          items: this.articles,
          class: 'height',
          styleClass: "p-dropdown p-dropdown-items height"
        },
      },
      {
        type: FilterEnum.DROPDOWN,
        col: 'col-12 col-md-4 col-lg-3 my-3 my-lg-0',
        title: '',
        placeholder: 'Cliente',
        titleClass: '',
        styleGeneral: {
          width: '100%',
          'border-radius': '50rem',
        },
        nameFilter: 'cliente',
        valueFilter: '',
        order: 2,
        items: {
          label: 'label',
          value: 'value',
          search: true,
          items: this.clientes,
          class: 'height',
          styleClass: "p-dropdown p-dropdown-items height"
        },
      },
      {
        type: FilterEnum.DROPDOWN,
        col: 'col-12 col-md-4 col-lg-3 my-3 my-lg-0',
        title: '',
        placeholder: 'Color',
        titleClass: '',
        styleGeneral: {
          width: '100%',
          'border-radius': '50rem',
        },
        nameFilter: 'colorId',
        valueFilter: '',
        order: 3,
        items: {
          label: 'nombre',
          value: 'id',
          search: true,
          items: this.colors,
          class: 'height',
          styleClass: "p-dropdown p-dropdown-items height"
        },
      },
    ],
  };

  constructor(
    private productionService: ProductionService,
    private messageService: MessageService,
    private filterService: FilterService,
    private dataService: DataService
  ) { }

  ngOnInit(): void {
    this.getClientes();
    this.getColors();
    this.getTelas();
    this.getProductionPlan(true);
  }

  getProductionPlan(refresh = false) {
    this.spinner = refresh;
    if (refresh) {
      this.productionService.getProductionPlan(this.query, this.filters.method).subscribe({
        next: (data) => {
          this.orders = data.list;
          this.totalRecords = data.list.length;
        },
        error: (err) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: err.error ? err.error.message : 'Ups! Ocurrio un error',
          });
        },
        complete: () => {
          for (let i = 0; i < this.orders.length; i++) {
            if (!this.orders[i].prioridad) {
              this.orders[i].prioridad == 'ALTA';
            }
            this.orders[i].maquinaMock = [];
          }
          this.spinner = false;
        },
      });
    }
  }

  getColors() {
    this.dataService.getColors().subscribe({
      next: (data) => {
        this.colors = data;
        this.setValueFilter(this.searchFilterInFilters('colorId'), data);
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: err.error ? err.error.message : 'Ups! Ocurrio un error',
        });
      },
    });
  }

  getClientes() {
    this.dataService.getClientes().subscribe({
      next: (data) => {
        this.clientes = data.map((element) => {
          return {
            label: element,
            value: element
          }
        });
        this.setValueFilter(this.searchFilterInFilters('cliente'), this.clientes);
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: err.error ? err.error.message : 'Ups! Ocurrio un error',
        });
      },
    });
  }

  getTelas() {
    this.dataService.getTelas().subscribe({
      next: (data) => {
        this.articles = data.list;
        this.setValueFilter(this.searchFilterInFilters('telaId'), data.list);
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: err.error ? err.error.message : 'Ups! Ocurrio un error',
        });
      },
      complete() { },
    });
  }

  searchFilter(event: any) {
    this.query = this.filterService.getFilters;
    this.query.method = this.filters.method;
    this.getProductionPlan(true);
  }

  searchFilterInFilters(nameFilter: string) {
    let filter = this.filters.filtersCustom.find((element: FilterCustom) => {
      return element.nameFilter == nameFilter;
    });
    return filter;
  }

  setValueFilter(filter: FilterCustom, values: any) {
    filter.items.items = values;
  }
}
