<div class="card text-center" *ngIf="spinner">
  <span>
    <p-progressSpinner></p-progressSpinner>
  </span>
  <br />
  <span class="text-center">Cargando...</span>
</div>

<div>
  <div class="row text-center mt-3">
    <div class="col">
      <p class="text-dark fw-bold">Cliente: <span>{{ order.cliente }}</span></p>
    </div>
    <div class="col">
      <p class="text-dark fw-bold">Articulo: <span>{{ order.tela }}</span></p>
    </div>
    <div class="col">
      <p class="text-dark fw-bold">Color: <span>{{ order.color }}</span></p>
    </div>
    <div class="col">
      <p class="text-dark fw-bold">Cantidad: <span>{{ order.cantidad }}</span></p>
    </div>
  </div>
  <form [formGroup]="form" (ngSubmit)="sendForm()">
    <div class="row justify-content-center">
      <div class="col-12 col-md-6 mx-md-3">
        <label for="observaciones" class="text-dark">Observaciones: </label>
        <input type="text" pInputText formControlName="observaciones" [style]="{ width: '100%' }" />
        <span class="danger" *ngIf="
                form.get('observaciones').hasError('required') &&
                form.get('observaciones').touched
              ">
          <small class="p-error">Este campo es <strong>requerido</strong></small>
        </span>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col text-end">
        <p-button label="Guardar" styleClass="p-button p-button-secondary p-button-rounded date-promise-button"
          type="submit" [disabled]="form.invalid">
        </p-button>
      </div>
    </div>
  </form>
</div>