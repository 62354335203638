import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProductionItemsComponent } from './production-items.component';

const routes: Routes = [
  
  {
    path : '',
    children : [
      {path : '', component : ProductionItemsComponent},
      {path : 'production', component : ProductionItemsComponent},
      {
        path : 'cleaning',
        loadChildren : ()=>import('./production-cleaning/production-cleaning.module').then(m=>m.ProductionCleaningModule)
      },
      {
        path : 'warped',
        loadChildren : ()=>import('./production-warped/production-warped.module').then(m=>m.ProductionWarpedModule)
      },
      {
        path : 'weaving',
        loadChildren : ()=>import('./production-weaving/production-weaving.module').then(m=>m.ProductionWeavingModule)
      },
      {path : '**', redirectTo : ''}
    ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductionRoutingModule { }
