import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { MainComponent } from './main/main.component';
import { HomeComponent } from './home/home.component';
import { MenuComponent } from './menu/menu.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { BannerComponent } from './banner/banner.component';



@NgModule({
  declarations: [
    FooterComponent,
    MainComponent,
    HomeComponent,
    MenuComponent,
    BannerComponent
  ],
  imports: [
    CommonModule,RouterModule,SharedModule
  ],
  exports : [
    FooterComponent,
    MainComponent,
    HomeComponent,
    MenuComponent,
    BannerComponent
  ],
})
export class PublicModule { }
