<div class="card text-center" *ngIf="spinner || !form">
  <span>
    <p-progressSpinner></p-progressSpinner>
  </span>
  <br />
  <span class="text-center">Cargando...</span>
</div>

<div *ngIf="form && !spinner">
  <form [formGroup]="form" (ngSubmit)="sendForm()">
    <div class="row mt-1">
      <div class="col-12 col-md-4">
        <label for="nombre" class="text-dark">Nombre: </label>
        <input type="text" pInputText formControlName="nombre" [style]="{ width: '100%' }" />
        <span class="danger" *ngIf="
                form.get('nombre').hasError('required') &&
                form.get('nombre').touched
              ">
          <small class="p-error">Este campo es <strong>requerido</strong></small>
        </span>
      </div>
      <div class="col-12 col-md-4">
        <label for="nombre" class="text-dark">Código: </label>
        <input type="text" pInputText formControlName="codigo" [style]="{ width: '100%' }" />
        <span class="danger" *ngIf="
                form.get('codigo').hasError('required') &&
                form.get('codigo').touched
              ">
          <small class="p-error">Este campo es <strong>requerido</strong></small>
        </span>
      </div>
      <div class="col-12 col-md-3">
        <label for="nombre" class="text-dark">Código pigue: </label><br>
        <input type="text" formControlName="codigoPigue" pInputText [style]="{ width: '100%'}" />
        <span class="danger" *ngIf="
                form.get('codigoPigue').hasError('required') &&
                form.get('codigoPigue').touched
              ">
          <small class="p-error">Este campo es <strong>requerido</strong></small>
        </span>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12 col-md-4">
        <label for="nombre" class="text-dark">Coeficiente cruda: </label>
        <input min="1" type="number" pInputText formControlName="coeficienteCruda" [style]="{ width: '100%' }" />
        <span class="danger" *ngIf="
                form.get('coeficienteCruda').hasError('required') &&
                form.get('coeficienteCruda').touched
              ">
          <small class="p-error">Este campo es <strong>requerido</strong></small>
        </span>
      </div>
      <div class="col-12 col-md-4">
        <label for="number" class="text-dark">Coeficiente terminada: </label>
        <input type="number" pInputText formControlName="coeficienteTerminada" [style]="{ width: '100%' }" />
        <span class="danger" *ngIf="
                form.get('coeficienteTerminada').hasError('required') &&
                form.get('coeficienteTerminada').touched
              ">
          <small class="p-error">Este campo es <strong>requerido</strong></small>
        </span>
      </div>
      <div class="col-12 col-md-3">
        <label for="composicion" class="text-dark">Composición: </label>
        <p-multiSelect appendTo="body" [style]="{ width: '100%' }" formControlName="composicion" id="composicion"
          placeholder="Seleccione una opción..." [options]="compositions" defaultLabel="Seleccione una opción..."
          optionLabel="label" optionValue="value">
          <ng-template let-option pTemplate="item">
            <span [class]="'composition-' + option.value">
              {{ option.label }}
            </span>
          </ng-template>
        </p-multiSelect>

        <span class="danger" *ngIf="
                form.get('composicion').hasError('required') &&
                form.get('composicion').touched
              ">
          <small class="p-error">Este campo es <strong>requerido</strong></small>
        </span>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12 col-md-4">
        <label for="linea" class="text-dark">Linea: </label>
        <p-dropdown (onChange)="changeTejidoAll($event)" appendTo="body" [style]="{ width: '100%' }"
          formControlName="linea" id="linea" placeholder="Seleccione una opción..." [options]="lines"
          defaultLabel="Seleccione una opción..." optionLabel="label" optionValue="value">
          <ng-template let-option pTemplate="item">
            <span [class]="'line-' + option.value">
              {{ option.label }}
            </span>
          </ng-template>
        </p-dropdown>
        <span class="danger" *ngIf="
                form.get('linea').hasError('required') &&
                form.get('linea').touched
              ">
          <small class="p-error">Este campo es <strong>requerido</strong></small>
        </span>
      </div>
      <div class="col-12 col-md-4">
        <label for="tamanioDeLaPieza" class="text-dark">Tamaño de la pieza: </label>
        <input type="text" pInputText formControlName="tamanioDeLaPieza" [style]="{ width: '100%' }" />
        <span class="danger" *ngIf="
                form.get('unidadDeMedida').hasError('required') &&
                form.get('unidadDeMedida').touched
              ">
          <small class="p-error">Este campo es <strong>requerido</strong></small>
        </span>
      </div>
      <div class="col-12 col-md-3">
        <label for="unidadDeMedida" class="text-dark">Unidad de medida: </label>
        <p-dropdown appendTo="body" [style]="{ width: '100%' }" formControlName="unidadDeMedida" id="unidadDeMedida"
          placeholder="Seleccione una opción..." [options]="unitOfMeasurement" defaultLabel="Seleccione una opción..."
          optionLabel="label" optionValue="value">
          <ng-template let-option pTemplate="item">
            <span [class]="'unidadDeMedida-' + option.value">
              {{ option.label }}
            </span>
          </ng-template>
        </p-dropdown>

        <span class="danger" *ngIf="
                form.get('unidadDeMedida').hasError('required') &&
                form.get('unidadDeMedida').touched
              ">
          <small class="p-error">Este campo es <strong>requerido</strong></small>
        </span>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-11">
        <label for="codigo" class="text-dark">Detalle: </label>
        <textarea formControlName="detalle" rows="1" [style]="{ width: '100%' }" cols="30" [autoResize]="true"
          pInputTextarea></textarea>
      </div>
    </div>
    <div *ngIf="form" class="mt-3" formArrayName="colores">
      <h2>Color - Procesos</h2>
      <div class="border-dark border mt-3 p-3" *ngFor="
            let color of getColorsArray.controls;
            let i = index
          " [formGroupName]="i">
        <div class="row mt-3">
          <div class="col-12 col-md-5">
            <label for="color" class="text-dark">Color: </label>
            <p-dropdown appendTo="body" [style]="{ width: '100%' }" formControlName="color" id="color"
              placeholder="Seleccione una opción..." [options]="colors" defaultLabel="Seleccione una opción..."
              optionLabel="label" optionValue="value">
              <ng-template let-option pTemplate="item">
                <span [class]="'color-' + option.value">
                  {{ option.label }}
                </span>
              </ng-template>
            </p-dropdown>
            <span class="danger" *ngIf="
                color.get('color').hasError('required') &&
                color.get('color').touched
              ">
              <small class="p-error">Este campo es <strong>requerido</strong></small>
            </span>
          </div>
          <div class="col-12 col-md-6" formGroupName="procesos">
            <!-- <p-orderList  [value]="getProcessesArray(i).controls" [listStyle]="{ 'max-height': '30rem' }" [dragdrop]="true">
              <label for="">aasdsadasd</label>
              <ng-template let-process pTemplate="item">
                <p-dropdown appendTo="body" [style]="{ width: '100%' }" formControlName="proceso" id="color"
                placeholder="Seleccione una opción..." [options]="processes" defaultLabel="Seleccione una opción..."
                optionLabel="label" optionValue="value">
                <ng-template let-option pTemplate="item">
                  <span [class]="'proceso-' + option.value">
                    {{ option.label }}
                  </span>

                </ng-template>
              </p-dropdown>
              </ng-template>
            </p-orderList >  -->
            <div class="" *ngFor="let process of getProcessesArray(i).controls; let j = index;; let f = first"
              [formGroupName]="j">
              <div>
                <label for="proceso" class="text-dark" [ngClass]="{
                      'mt-3' : !f
                    }">Proceso {{j + 1}}: </label>
                <!-- <span *ngIf="getProcessesArray(i).controls[j].get('proceso').hasError('required')"> * </span> -->
                <p-button *ngIf="j == 0" styleClass="button-add-process mx-3" icon="pi pi-plus" class=""
                  (click)="addProcessItem(i)"></p-button>
                <p-button *ngIf="j != 0" styleClass="button-add-process mx-3" icon="pi pi-trash" class=""
                  (click)="deleteProcessItem(i,j)"></p-button>
                <br />
                <p-dropdown appendTo="body" [style]="{ width: '100%' }" formControlName="proceso" id="color"
                  placeholder="Seleccione una opción..." [options]="processes" defaultLabel="Seleccione una opción..."
                  optionLabel="label" optionValue="value">
                  <ng-template let-option pTemplate="item">
                    <span [class]="'proceso-' + option.value">
                      {{ option.label }}
                    </span>
                  </ng-template>
                </p-dropdown>
                <br />
              </div>
            </div>
          </div>
          <div class="col-1">
            <br>
            <p-button *ngIf="i == 0" (click)="addColorItem()" pTooltip="Añadir" styleClass="button-add-divide-cleaning"
              icon="pi pi-plus" tooltipPosition="top"></p-button>
            <p-button pTooltip="Eliminar" styleClass="button-add-divide-cleaning" icon="pi pi-trash"
              tooltipPosition="top" (click)="deleteColorItem(i)" *ngIf="i > 0"></p-button>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3" formArrayName="hiladoTejido">
      <h2>Formula tejido</h2>
      <div class="border-dark border mt-3 p-3" *ngFor="
            let tejido of getHiladoTejidoArray.controls;
            let i = index; let f = first; let l = last
          " [formGroupName]="i">
        <div class="row mt-3">
          <div [ngClass]="
          {'col-12 col-md-3' : tejido.get('exist').value?.id == 'Otro',
          'col-10' : tejido.get('exist').value?.id != 'Otro',
        
        }">
            <label for="codigo" class="text-dark">Nombre - Código: </label>
            <p-dropdown [filter]="true" appendTo="body" [style]="{ width: '100%' }" formControlName="exist" id="exist"
              placeholder="Seleccione una opción..." optionGroupLabel="{nombre,codigo}" [options]="hiladoTejido"
              filterBy="nombre,codigo" defaultLabel="Seleccione una opción...">
              <ng-template let-option pTemplate="item">
                <span [class]="'hilado-tejido-' + option.id">
                  {{ option.id == 'Otro' ? 'Otro' : option.nombre || 'Sin nombre' }}
                  {{option.codigo ? '-' : ''}}
                  {{ option.id == 'Otro' ? '' : option.codigo || 'Sin código'}}
                </span>
              </ng-template>
              <ng-template let-option pTemplate="selectedItem">
                <span [class]="'hilado-tejido-' + option.id">
                  {{ option.id == 'Otro' ? 'Otro' : option.nombre || 'Sin nombre' }}
                  {{option.codigo ? '-' : ''}}
                  {{ option.id == 'Otro' ? '' : option.codigo || 'Sin código'}}
                </span>
              </ng-template>
            </p-dropdown>
          </div>
          <div class="col-12 col-md-4" *ngIf="tejido.get('exist').value?.id == 'Otro'">
            <label for="codigo" class="text-dark">Código: </label>
            <input type="text" pInputText formControlName="codigo" [style]="{ width: '100%' }" />

            <span class="danger" *ngIf="
                tejido.get('codigo').hasError('required') &&
                tejido.get('codigo').touched
              ">
              <small class="p-error">Este campo es <strong>requerido</strong></small>
            </span>
          </div>
          <div class="col-12 col-md-3" *ngIf="tejido.get('exist').value?.id == 'Otro'">
            <label for="nombre" class="text-dark">Nombre: </label>
            <textarea formControlName="nombre" rows="1" [style]="{ width: '100%' }" cols="30" [autoResize]="true"
              pInputTextarea></textarea>
            <span class="danger" *ngIf="
                        tejido.get('nombre').hasError('required') &&
                        tejido.get('nombre').touched
                      ">
              <small class="p-error">Este campo es <strong>requerido</strong></small>
            </span>
          </div>
          <div class="col-2">
            <br>
            <p-button *ngIf="i == 0" (click)="addHiladoTejidoItem()" pTooltip="Añadir"
              styleClass="button-add-divide-cleaning" icon="pi pi-plus" tooltipPosition="top"></p-button>
            <p-button pTooltip="Eliminar" styleClass="button-add-divide-cleaning mx-2" icon="pi pi-trash"
              tooltipPosition="top" (click)="deleteHiladoTejidoItem(i)"></p-button>
          </div>
        </div>
        <div class="row">
          <div [ngClass]="{
            'col-12 col-md-4' : !tejido.get('exist').value?.nombre.includes('CAD'),
            'col-12 col-md-6' : tejido.get('exist').value?.nombre.includes('CAD')
          }" *ngIf="!tejido.get('exist').value?.nombre.includes('CAD')">
            <label for="pesoPorBob" class="text-dark">Peso por bobina: </label>
            <input type="number" pInputText formControlName="pesoPorBob" [style]="{ width: '100%' }" />
            <span class="danger" *ngIf="
                        tejido.get('pesoPorBob').hasError('required') &&
                        tejido.get('pesoPorBob').touched
                      ">
              <small class="p-error">Este campo es <strong>requerido</strong></small>
            </span>
          </div>
          <div [ngClass]="{
            'col-12 col-md-3' : !tejido.get('exist').value?.nombre.includes('CAD'),
            'col-12 col-md-6' : tejido.get('exist').value?.nombre.includes('CAD')
          }" *ngIf="form.get('linea').value != 'PLANO'">
            <label for="porcentaje" class="text-dark">Porcentaje: </label>
            <input type="number" pInputText formControlName="porcentaje" [style]="{ width: '100%' }" />
            <span class="danger" *ngIf="
                        tejido.get('porcentaje').hasError('required') &&
                        tejido.get('porcentaje').touched
                      ">
              <small class="p-error">Este campo es <strong>requerido</strong></small>
            </span>
          </div>
          <div class="col-12 col-md-4"
            *ngIf="form.get('linea').value == 'PLANO' && tejido.get('exist').value?.nombre.includes('CAD')">
            <label for="kgXm" class="text-dark">Kilo por metro: </label>
            <input type="number" pInputText formControlName="kgXm" [style]="{ width: '100%' }" />
            <span class="danger" *ngIf="
                        tejido.get('kgXm').hasError('required') &&
                        tejido.get('kgXm').touched
                      ">
              <small class="p-error">Este campo es <strong>requerido</strong></small>
            </span>
          </div>
          <div class="col-12 col-md-4" *ngIf="form.get('linea').value == 'PLANO'">
            <label for="consumoXMetro" class="text-dark">Consumo por metro: </label>
            <input type="number" pInputText formControlName="consumoXMetro" [style]="{ width: '100%' }" />
            <span class="danger" *ngIf="
                        tejido.get('consumoXMetro').hasError('required') &&
                        tejido.get('consumoXMetro').touched
                      ">
              <small class="p-error">Este campo es <strong>requerido</strong></small>
            </span>
          </div>
          <div [ngClass]="{
            'col-12 col-md-2' : form.get('linea').value == 'PLANO',
            'col-12 col-md-4' : form.get('linea').value != 'PLANO' && tejido.get('exist').value?.nombre.includes('CAD'),
            'col-12 col-md-3' : form.get('linea').value != 'PLANO' && !tejido.get('exist').value?.nombre.includes('CAD'),
          }">
            <label for="numeroComponente" class="text-dark">Número de componente: </label>
            <p-dropdown appendTo="body" [style]="{ width: '100%' }" formControlName="numeroComponente"
              id="numeroComponente" placeholder="Seleccione una opción..." [options]="numeroComponente"
              defaultLabel="Seleccione una opción..." optionLabel="label" optionValue="value">
              <ng-template let-option pTemplate="item">
                <span [class]="'numeroComponente-' + option.value">
                  {{ option.label }}
                </span>
              </ng-template>
            </p-dropdown>
            <span class="danger" *ngIf="
                  tejido.get('numeroComponente').hasError('required') &&
                  tejido.get('numeroComponente').touched
                ">
              <small class="p-error">Este campo es <strong>requerido</strong></small>
            </span>
            <span class="danger" *ngIf="
                  form.get('hiladoTejido').hasError('nonUniqueComponentNumber') &&
                  tejido.get('numeroComponente').touched
                ">
              <small class="p-error">No se puede <strong>repetir</strong> el número de componente</small>
            </span>
          </div>
        </div>
        <div class="row" *ngIf="!f && !l">
          <div class="col-11">
            <p-divider></p-divider>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3" formArrayName="hiladoUrdido">
      <h2>Formula urdido</h2>
      <div class="border-dark border mt-3 p-3" *ngFor="
            let urdido of getHiladoUrdidoArray.controls;
            let i = index
          " [formGroupName]="i">
        <div class="row mt-3">
          <div [ngClass]="
          {'col-12 col-md-4' : urdido.get('exist').value?.id == 'Otro',
          'col-10' : urdido.get('exist').value?.id != 'Otro',
        
        }">
            <label for="codigo" class="text-dark">Nombre - Código: </label>
            <p-dropdown [filter]="true" appendTo="body" [style]="{ width: '100%' }" formControlName="exist" id="exist"
              placeholder="Seleccione una opción..." optionGroupLabel="{nombre,codigo}" [options]="hiladoUrdido"
              filterBy="nombre,codigo" defaultLabel="Seleccione una opción...">
              <ng-template let-option pTemplate="item">
                <span [class]="'hilado-tejido-' + option.id">
                  {{ option.id == 'Otro' ? 'Otro' : option.nombre || 'Sin nombre' }}
                  {{option.codigo ? '-' : ''}}
                  {{ option.id == 'Otro' ? '' : option.codigo || 'Sin código'}}
                </span>
              </ng-template>
              <ng-template let-option pTemplate="selectedItem">
                <span [class]="'hilado-tejido-' + option.id">
                  {{ option.id == 'Otro' ? 'Otro' : option.nombre || 'Sin nombre' }}
                  {{option.codigo ? '-' : ''}}
                  {{ option.id == 'Otro' ? '' : option.codigo || 'Sin código'}}
                </span>
              </ng-template>
            </p-dropdown>
          </div>
          <div class="col-12 col-md-3" *ngIf="urdido.get('exist').value?.id == 'Otro'">
            <label for="codigo" class="text-dark">Código: </label>
            <input type="text" pInputText formControlName="codigo" [style]="{ width: '100%' }" />

            <span class="danger" *ngIf="
                urdido.get('codigo').hasError('required') &&
                urdido.get('codigo').touched
              ">
              <small class="p-error">Este campo es <strong>requerido</strong></small>
            </span>
          </div>
          <div class="col-12 col-md-3" *ngIf="urdido.get('exist').value?.id == 'Otro'">
            <label for="nombre" class="text-dark">Nombre: </label>
            <textarea formControlName="nombre" rows="1" [style]="{ width: '100%' }" cols="30" [autoResize]="true"
              pInputTextarea></textarea>
            <span class="danger" *ngIf="
                        urdido.get('nombre').hasError('required') &&
                        urdido.get('nombre').touched
                      ">
              <small class="p-error">Este campo es <strong>requerido</strong></small>
            </span>
          </div>
          <div class="col-2">
            <br>
            <p-button pTooltip="Eliminar" styleClass="button-add-divide-cleaning mx-2" icon="pi pi-trash"
              tooltipPosition="top" (click)="deleteHiladoUrdidoItem(i)"></p-button>
            <p-button *ngIf="i == 0" (click)="addHiladoUrdidoItem()" pTooltip="Añadir" class="p-button-sm"
              styleClass="button-add-divide-cleaning" icon="pi pi-plus" tooltipPosition="top"></p-button>
            <br>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-5">
            <label for="pesoPorBob" class="text-dark">Peso por bobina: </label>
            <input type="text" pInputText formControlName="pesoPorBob" [style]="{ width: '100%' }" />
            <span class="danger" *ngIf="
                        urdido.get('pesoPorBob').hasError('required') &&
                        urdido.get('pesoPorBob').touched
                      ">
              <small class="p-error">Este campo es <strong>requerido</strong></small>
            </span>
          </div>
          <div class="col-5">
            <label for="numeroComponente" class="text-dark">Número de componente: </label>
            <p-dropdown appendTo="body" [style]="{ width: '100%' }" formControlName="numeroComponente"
              id="numeroComponente" placeholder="Seleccione una opción..." [options]="numeroComponente"
              defaultLabel="Seleccione una opción..." optionLabel="label" optionValue="value">
              <ng-template let-option pTemplate="item">
                <span [class]="'numeroComponente-' + option.value">
                  {{ option.label }}
                </span>
              </ng-template>
            </p-dropdown>
            <span class="danger" *ngIf="
                  urdido.get('numeroComponente').hasError('required') &&
                  urdido.get('numeroComponente').touched
                ">
              <small class="p-error">Este campo es <strong>requerido</strong></small>
            </span>
            <span class="danger" *ngIf="
                  form.get('hiladoTejido').hasError('nonUniqueComponentNumber') &&
                  urdido.get('numeroComponente').touched
                ">
              <small class="p-error">No se puede <strong>repetir</strong> el número de componente</small>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-11 text-end">
        <p-button [label]="title" styleClass="p-button p-button-secondary p-button-rounded date-promise-button"
          type="submit" [disabled]="form.invalid">
        </p-button>
      </div>
    </div>
  </form>
</div>