import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { MessageService, SortEvent } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Item } from 'src/app/shared/models/model-forms/item.interface';
import { Order } from 'src/app/shared/models/order/order.class';
import { OrderService } from 'src/app/api/services/order/order.service';

@Component({
  selector: 'app-table-production-cleaning-planning',
  templateUrl: './table-production-cleaning-planning.component.html',
  styleUrls: ['./table-production-cleaning-planning.component.css'],
})
export class TableProductionCleaningPlanningComponent
  implements OnInit, AfterViewInit
{
  @Input()
  orders: Order[] = [];

  selectOrders: Order[] = [];
  newsOrders: any = [];

  showPopUpAddOrder = false;
  loadingSelectOrders = false;

  @Input()
  size = 10;

  prioritys: Item[] = [
    {
      label: 'BAJA',
      value: 'BAJA',
    },
    {
      label: 'MEDIA',
      value: 'MEDIA',
    },
    {
      label: 'ALTA',
      value: 'ALTA',
    },
  ];

  @Output()
  eventModalAddOrder: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private messageService: MessageService,
    private router: Router,
    private dialogService: DialogService,
    private dialogRef: DynamicDialogRef,
    private orderService: OrderService,
    private changeDetector: ChangeDetectorRef
  ) {}
  ngAfterViewInit(): void {}

  ngOnInit(): void {
    console.log(this.orders);
    
    this.selectOrders = this.orders;
  }

  loadOrderLazy(event: any) {
    //simulate remote connection with a timeout
    setTimeout(() => {
      //load data of required page
      let loadedOrders = this.orders.slice(
        event.first,
        event.first + event.rows
      );

      //populate page of virtual cars
      Array.prototype.splice.apply(this.orders, [
        ...[event.first, event.rows],
        ...loadedOrders,
      ]);

      //trigger change detection
      event.forceUpdate();
    }, Math.random() * this.orders.length + this.size);
  }

  showModalAddOrder() {
    this.showPopUpAddOrder = true;
  }

  addOrderEmit(order: Order) {
    this.orders.unshift(order);
    this.selectOrders = [...this.orders];
    this.showPopUpAddOrder = false;
    this.changeDetector.detectChanges();
  }

  deleteAddOrder(order: any, index: any) {
    this.orderService.deleteOrder(order).subscribe({
      next: (data) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Eliminado!',
        });
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: err.error ? err.error.message : 'Ups! Ocurrio un error',
        });
      },
      complete: () => {
        this.eventModalAddOrder.emit(true);
      },
    });
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) result = -1;
      else if (value1 != null && value2 == null) result = 1;
      else if (value1 == null && value2 == null) result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

      return event.order * result;
    });
  }

  navigateProductionOrder() {
    this.loadingSelectOrders = true;
    if (this.selectOrders.length <= 0) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Seleccione al menos una orden',
      });
      return;
    }

    let items = [];
    for (let i = 0; i < this.selectOrders.length; i++) {
      if (this.selectOrders[i].id) {
        let id = this.selectOrders[i].id;
        items.push(id);
      }
    }

    this.router.navigate(['/production/cleaning/order-planning'], {
      state: {
        items: items,
      },
    });
  }
}
