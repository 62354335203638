import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-detail-process',
  templateUrl: './detail-process.component.html',
  styleUrls: ['./detail-process.component.css']
})
export class DetailProcessComponent {

  spinner = true;

  process:any = {}

  constructor(
    private messageService: MessageService,
    public dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig
  ) {}


  ngOnInit(): void {
    this.process = history.state.process
    this.spinner = false;
  }

  ngOnDestroy(): void {
    this.spinner = true;
  }

}
