<div class="card text-center" *ngIf="spinner">
  <span>
    <p-progressSpinner></p-progressSpinner>
  </span>
  <br />
  <span class="text-center">Cargando...</span>
</div>

<div class="container" *ngIf="!spinner">
  <div class="row">
    <div class="col text-center">
      <h3 class="title">Dividir baños</h3>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col text-center">
      <p class="text-dark">Tela: {{ order.articulo }}</p>
    </div>
    <div class="col text-center">
      <p class="text-dark">Color: {{ order.color }}</p>
    </div>
    <div class="col text-center">
      <p class="text-dark">Proceso: {{ order.proceso || "TEÑIDO" }}</p>
    </div>
    <div class="col text-center">
      <p class="text-dark">Total: {{ order.cantidad }}</p>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12">
      <form [formGroup]="form" (ngSubmit)="divideBathroomsSubmit()">
        <div formArrayName="divideBathroomsArray">
          <div
            class="row mt-3"
            *ngFor="
              let getDivideBathRoom of getDivideBathRoomsArray.controls;
              let i = index
            "
            [formGroupName]="i"
          >
            <div class="col-2 text-end order-3 order-md-1">
              <br />
              <p-button
                pTooltip="Añadir"
                (click)="addDivideBathRooms()"
                styleClass="p-button-rounded button-add-divide-cleaning"
                *ngIf="i == 0"
                icon="pi pi-plus"
                tooltipPosition="top"
              ></p-button>
              <p-button
                pTooltip="Eliminar"
                (click)="deleteDivideBathRooms(i)"
                styleClass="p-button-rounded button-add-divide-cleaning"
                *ngIf="i > 0"
                icon="pi pi-trash"
                tooltipPosition="top"
              ></p-button>
            </div>
            <div class="col-12 col-md-5 order-1 order-md-2">
              <label for="quantity" class="">Cantidad (Kg): </label><br />
              <input
                type="number"
                formControlName="quantity"
                pInputText
                [style]="{ width: '100%',border: '2px solid #C6C5FF' }"
              />
              <br />
              <span
                class="danger"
                *ngIf="
                  getDivideBathRoom.get('quantity').hasError('required') &&
                  getDivideBathRoom.get('quantity').touched
                "
              >
                <small class="p-error"
                  >Este campo es <strong>requerido</strong></small
                >
              </span>
              <span
                class="danger"
                *ngIf="
                  getDivideBathRoom.get('quantity').hasError('min') &&
                  getDivideBathRoom.get('quantity').touched
                "
              >
                <small class="p-error"
                  >La cantidad mínima debe ser mayor a <strong>0</strong></small
                >
              </span>
            </div>
            <div class="col-12 col-md-5 order-2 order-md-3">
              <label for="machine" class="">Máquina: </label><br />
              <p-dropdown
                formControlName="machine"
                class="w-100 ml-5"
                [options]="order.maquinasList"
                [style]="{ width: '100%',border: '2px solid #C6C5FF' }"
                [placeholder]="order.maquinasList.length > 1 ? '- Máquina - ' : null"
                optionLabel="nombre"
                [showClear]="true"
              >
                <ng-template let-option pTemplate="item">
                  <span [class]="'machine -' + option.id">{{
                    option.nombre
                  }}</span>
                </ng-template>
              </p-dropdown>

              <br />
              <span
                class="danger"
                *ngIf="
                  getDivideBathRoom.get('machine').hasError('required') &&
                  getDivideBathRoom.get('machine').touched
                "
              >
                <small class="p-error"
                  >Este campo es <strong>requerido</strong></small
                >
              </span>
            </div>
          </div>
          <div class="row mt-3">
            <div class="offset-0 col-12 offset-md-2 col-md-10">
              <span
                class="danger"
                *ngIf="form.hasError('totalExceeded') && form.touched"
              >
                <small class="p-error"
                  >No puede superar la cantidad <strong>original</strong></small
                >
              </span>
              <span
                class="danger"
                *ngIf="form.hasError('totalMissing') && form.touched"
              >
                <small class="p-error"
                  >El conjunto de números a dividir no puede ser menor a la
                  <strong>original</strong></small
                >
              </span>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-12 text-end">
              <p-button
                type="submit"
                styleClass="p-button-rounded p-button-sm p-button-secondary save-order-machine-cleaning"
                label="Guardar"
                [disabled]="form.invalid"
              ></p-button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
