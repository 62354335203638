<app-banner
  [sizeTitleClass]="'h6'"
  [title]="'BOMs'"
  [backgroundName]=""
  [fontSize]="1"
  [routerBack]="'/home'"
></app-banner>

<div class="card text-center" *ngIf="spinner">
    <span>
      <p-progressSpinner></p-progressSpinner>
    </span>
    <br />
    <span class="text-center">Cargando...</span>
  </div>
  
  <div class="container-fluid mt-1 p-2">
    <div class="row m-auto">
      <div class="col-12 col-sm-11">
        <div class="panel">
          <a routerLink="/boms/fabrics" class="text-decoration-none">
            <h1 class="text-dark text-decoration-none h6 fw-bold">Telas</h1>
          </a>
        </div>
      </div>
      <div class="col-12 col-sm-11">
        <div class="panel">
          <a routerLink="/boms/machines" class="text-decoration-none">
            <h1 class="text-dark text-decoration-none h6 fw-bold">Máquinas</h1>
          </a>
        </div>
      </div>
      <!-- <div class="col-12 col-sm-11">
        <div class="panel">
          <a routerLink="/boms/processes" class="text-decoration-none">
            <h1 class="text-dark text-decoration-none h2 fw-bold">Procesos</h1>
          </a>
        </div>
      </div> -->
    </div>
  </div>
  