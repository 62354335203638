import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { OrderService } from '../../../../api/services/order/order.service';
import {
  FilterCustom,
  Filters,
} from 'src/app/shared/filters/interface/filters.interface';
import { Method } from 'src/app/shared/filters/enum/method.enum';
import { FilterEnum } from 'src/app/shared/filters/enum/filters.enum';
import { Order } from 'src/app/shared/models/order/order.class';
import { OrderFileService } from '../../../../api/services/download/OrderFileService.service';
import { DataService } from 'src/app/api/services/data/data.service';
import { FilterService } from 'src/app/shared/filters/services/filter.service';

@Component({
  selector: 'app-list-order',
  templateUrl: './list-order.component.html',
  styleUrls: ['./list-order.component.css'],
})
export class ListOrderComponent implements OnInit {
  orders: Order[] = [];

  spinner = true;

  paginate: any = 1;

  totalRecords = 1;

  size = 50;

  articles: any = [];
  colors: any = [];
  clientes: any = [];

  filters: Filters = {
    autoSend: true,
    method: Method.GET,
    filtersCustom: [
      {
        type: FilterEnum.DATE,
        col: 'col-12 col-md-4 col-lg-3 my-3 my-lg-0',
        title: '',
        placeholder: 'Fecha: desde - hasta',
        titleClass: '',
        inputStyle: {
          height : '20%',
          width: '100%',
          'border-radius': '50rem',
        },
        nameFilter: ['inicioFechaDeEntrega', 'finFechaDeEntrega'],
        valueFilter: '',
        order: 1,
        calendar: {
          modeCalendar: 'range',
          inputStyle: {
            height: '50%',
            'border-radius': '50rem',
          },
          inputStyleClass : 'height',
          dateFormat: 'dd/mm/yy',
          multipleFilter: true,
          showClear: true
        },
      },
      {
        type: FilterEnum.DROPDOWN,
        col: 'col-12 col-md-4 col-lg-3 my-3 my-lg-0',
        title: '',
        placeholder: 'Articulo',
        titleClass: '',
        styleGeneral: {
          width: '100%',
          'border-radius': '50rem',
        },
        nameFilter: 'telaId',
        valueFilter: '',
        order: 2,
        items: {
          label: 'nombre',
          value: 'id',
          search: true,
          items: this.articles,
          class: 'height',
          styleClass:"p-dropdown p-dropdown-items height"
        },
      },
      {
        type: FilterEnum.DROPDOWN,
        col: 'col-12 col-md-4 col-lg-3 my-3 my-lg-0',
        title: '',
        placeholder: 'Cliente',
        titleClass: '',
        styleGeneral: {
          width: '100%',
          'border-radius': '50rem',
        },
        nameFilter: 'cliente',
        valueFilter: '',
        order: 2,
        items: {
          label: 'label',
          value: 'value',
          search: true,
          items: this.clientes,
          class: 'height',
          styleClass:"p-dropdown p-dropdown-items height"
        },
      },
      {
        type: FilterEnum.DROPDOWN,
        col: 'col-12 col-md-4 col-lg-3 my-3 my-lg-0',
        title: '',
        placeholder: 'Color',
        titleClass: '',
        styleGeneral: {
          width: '100%',
          'border-radius': '50rem',
        },
        nameFilter: 'colorId',
        valueFilter: '',
        order: 3,
        items: {
          label: 'nombre',
          value: 'id',
          search: true,
          items: this.colors,
          class: 'height',
          styleClass:"p-dropdown p-dropdown-items height"
        },
      },
    ],
  };

  loadingButtonExcel: boolean = false;

  query: any = [];

  constructor(
    private messageService: MessageService,
    private orderService: OrderService,
    private orderFileService: OrderFileService,
    private dataService: DataService,
    private filterService: FilterService
  ) { }
  ngOnInit(): void {
    this.getTelas();
    this.getClientes();
    this.getColors();
    this.getOrders(true);
  }

  getOrders(refresh: boolean = false) {
    this.spinner = refresh;
    if (refresh) {
      this.orders = [];
      this.orderService.getOrders(this.query, this.filters.method).subscribe({
        next: (data) => {
          this.orders = data.list;
          this.totalRecords = this.orders.length;
        },
        error: (err) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: err.error ? err.error.message : 'Ups! Ocurrio un error',
          });
        },
        complete: () => {
          this.spinner = false;
        },
      });
    }
  }

  getColors() {
    this.dataService.getColors().subscribe({
      next: (data) => {
        this.colors = data;
        this.setValueFilter(this.searchFilterInFilters('colorId'), data);
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: err.error ? err.error.message : 'Ups! Ocurrio un error',
        });
      },
    });
  }

  getClientes() {
    this.dataService.getClientes().subscribe({
      next: (data) => {
        this.clientes = data.map((element)=>{
          return {
            label : element,
            value : element
          }
        });
        this.setValueFilter(this.searchFilterInFilters('cliente'), this.clientes);
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: err.error ? err.error.message : 'Ups! Ocurrio un error',
        });
      },
    });
  }

  getTelas() {
    this.dataService.getTelas().subscribe({
      next: (data) => {
        this.articles = data.list;
        this.setValueFilter(this.searchFilterInFilters('telaId'), data.list);
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: err.error ? err.error.message : 'Ups! Ocurrio un error',
        });
      },
      complete() { },
    });
  }

  searchFilterInFilters(nameFilter: string) {
    let filter = this.filters.filtersCustom.find((element: FilterCustom) => {
      return element.nameFilter == nameFilter;
    });
    return filter;
  }

  setValueFilter(filter: FilterCustom, values: any) {
    filter.items.items = values;
  }

  exportExcel() {
    this.loadingButtonExcel = true;

    this.orderFileService.downloadOrderExcel('').subscribe({
      next: (data) => {
        var downloadURL = URL.createObjectURL(data);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = `orders.xlsx`;
        link.click();
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: err.error ? err.error.message : 'Ups! Ocurrio un error',
        });
      },
      complete: () => {
        this.loadingButtonExcel = false;
      },
    });
  }

  searchFilter(event: any) {
    this.query = this.filterService.getFilters;
    this.query.method = this.filters.method;
    this.getOrders(true);
  }

  paginateOrders(event: any) {
    this.paginate = event.page + 1;
    this.size = event.rows;
    this.spinner = true;
    this.getOrders();
  }
}
