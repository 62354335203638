<p-table [value]="orders" dataKey="id" [tableStyle]="{ 'min-width': '40rem' }" styleClass="p-datatable-sm" [style]="{
    'max-height': '70vh',
    'overflow-y': 'auto',
  }" scrollHeight="70vh" [scrollable]="true" [rows]="size" (sortFunction)="customSort($event)" [customSort]="true"
  class="" selectionMode="single">
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 4.33%" pSortableColumn="idInterno" class="td m-0 p-0">
        Nro <p-sortIcon class="p-icon icon-filter" field="idInterno"></p-sortIcon>
      </th>
      <th style="width:  9.33%" pSortableColumn="fechaDeEntrega" class="td m-0 p-0">
        Fecha creación <p-sortIcon field="fechaDeEntrega"></p-sortIcon>
      </th>
      <th style="width:  4.33%" pSortableColumn="fechaDeEntrega" class="td m-0 p-0">
        LT <p-sortIcon field="fechaDeEntrega"></p-sortIcon>
      </th>
      <th style="width: 16.33%" pSortableColumn="cliente" class="td m-0 p-0">
        Cliente <p-sortIcon field="cliente"></p-sortIcon>
      </th>
      <th style="width: 8.33%" pSortableColumn="tela" class="td m-0 p-0">
        Articulo <p-sortIcon field="tela"></p-sortIcon>
      </th>
      <th style="width: 8.33%" pSortableColumn="color" class="td m-0 p-0">
        Color <p-sortIcon field="color"></p-sortIcon>
      </th>
      <th style="width:  8.33%" pSortableColumn="indicacion" class="td m-0 p-0">
        Indicación <p-sortIcon field="indicacion"></p-sortIcon>
      </th>
      <th style="width:  5.33%" pSortableColumn="cantidad" class="td m-0 p-0">
        Cantidad <p-sortIcon field="cantidad"></p-sortIcon>
      </th>
      <th style="width:  5.33%" pSortableColumn="cantidad" class="td m-0 p-0">
        Estado <p-sortIcon field="cantidad"></p-sortIcon>
      </th>
      <th style="width:  10.33%" pSortableColumn="fechaPrometida" class="td m-0 p-0">
        Fecha prometida <p-sortIcon field="fechaPrometida"></p-sortIcon>
      </th>
      <th style="width:  9.33%" pSortableColumn="observaciones" class="td m-0 p-0">
        Observaciones <p-sortIcon field="observaciones"></p-sortIcon>
      </th>
      <th style="width: 4.33%" class="td">Detalle</th>
      <th style="width:  6.33%" pSortableColumn="stock" class="td m-0 p-0">
        Asignar
        <p-sortIcon field="stock"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-order>
    <tr>
      <td class="td truncate m-0 p-0 fw-bold">{{ order.idInterno }}</td>
      <td class="td truncate m-0 p-0">{{ order.fechaDePedido | date : "dd/MM/yyyy" }}</td>
      <td class="td truncate m-0 p-0">{{ order?.lt | number}}</td>
      <td class="td truncate m-0 p-0 fw-bold">{{ order.cliente }}</td>
      <td class="td truncate m-0 p-0">{{ order.tela }}</td>
      <td class="td truncate m-0 p-0 fw-bold">{{ order.color }}</td>
      <td class="td truncate m-0 p-0">{{ order.indicacion }}</td>
      <td class="td truncate m-0 p-0 fw-bold">{{ order.cantidad.toFixed(2) | number}}</td>
      <td class="td truncate m-0 p-0">{{ order.estado || "" }}</td>
      <td [pEditableColumn]="order.fechaPrometida" pEditableColumnField="fechaPrometida"
        class="td truncate m-0 p-0 fw-bold">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <p-calendar (onSelect)="changeDatePromise($event,order)" dateFormat="dd/mm/yy" appendTo="body"
              inputId="basic" [yearNavigator]="true" [style]="{ width: '75%' }" inputStyleClass="height"
              [inputStyle]="{border: '2px solid #FFE2D4' }"></p-calendar>
          </ng-template>
          <ng-template #totalKgs pTemplate="output" class="">
            {{ order.fechaPrometida | date : "dd/MM/yyyy" }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td class="td truncate m-0 p-0" (click)="showModalDatePromiseOrder(order)">{{ order.observaciones ?
        order.observaciones.substring(0,25) + '...' : '' }}</td>
      <td class="td truncate m-0 p-0">
        <p-button icon="pi pi-eye" class=""
          styleClass="p-button-sm p-button-secondary p-button-outlined p-button-text padding-0 icon-width-2" pTooltip="Ver detalle"
          tooltipPosition="bottom" (onClick)="showModalDetailOrder(order)">
        </p-button>
      </td>
      <td class="td truncate m-0 p-0">
        <p-button *ngIf="order.estado == 'Asignado'" class=""
          styleClass="p-button-sm p-button-success p-button-outlined p-button-text padding-0 icon-width-2 bi bi-box-seam"
          [pTooltip]="order.estado" tooltipPosition="bottom" (onClick)="showModal(order)">
        </p-button>
        <p-button *ngIf="order.estado == 'Parcialmente Asignado'" class=""
          styleClass="p-button-sm p-button-primary p-button-outlined p-button-text padding-0 icon-width-2 bi bi-box-seam"
          [pTooltip]="order.estado" tooltipPosition="bottom" (onClick)="showModal(order)">
        </p-button>
        <p-button *ngIf="order.hayStock && order.estado === 'Pendiente'" class=""
          styleClass="p-button-sm p-button-danger p-button-outlined p-button-text padding-0 icon-width-2 bi bi-box-seam"
          pTooltip="Asignar" tooltipPosition="bottom" (onClick)="showModal(order)">
        </p-button>
        <!-- <p-button *ngIf="order.hayStock && order.estado === 'Pendiente' && !calculateIsStock(order)"
          icon="bi bi-box-seam" class="" styleClass="p-button-sm p-button-secondary p-button-outlined p-button-text padding-0"
          pTooltip="Asignar" tooltipPosition="bottom" (onClick)="showModalOrderWarning(order)">
        </p-button> -->
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="loadingbody" let-rowData>
    <tr class="h-100 w-100">
      <td colspan="13">
        <div class="text-center">
          <span>
            <p-progressSpinner></p-progressSpinner>
          </span>
          <br />
          <span class="text-center">Cargando...</span>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage" class="w-100">
    <tr>
      <td colspan="12">
        <p-messages class="w-100" [value]="[{ severity: 'info', detail: 'No hay pedidos' }]" [enableService]="false"
          [closable]="false">
        </p-messages>
      </td>
    </tr>
  </ng-template>
</p-table>