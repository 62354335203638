<form [formGroup]="form" (ngSubmit)="navigateProductionOrder()">
  <p-table [value]="getOrderArray.controls" dataKey="id" [tableStyle]="{ 'min-width': '40rem' }"
    styleClass="p-datatable-sm" [style]="{
      'max-height': '70vh',
      'overflow-y': 'auto',
    }" [scrollable]="true" [rows]="size" [(selection)]="selectTelas" (sortFunction)="customSort($event)"
    [customSort]="true" rowGroupMode="subheader" groupRowsBy="controls.id.value" *ngIf="form" scrollHeight="70vh"
    selectionMode="single">
    <ng-template pTemplate="header">
      <tr>
        <th class="td m-0 p-0" style="width: 12.5%">
          Articulo
        </th>
        <th class="td m-0 p-0" style="width: 12.5%">
          Tipo
        </th>
        <th class="td m-0 p-0" style="width: 12.5%">
          Total Mts
        </th>
        <th class="td m-0 p-0" style="width: 12.5%">Tejeduria</th>
        <th class="td m-0 p-0" style="width: 12.5%">Máquina</th>
        <!-- <th class="td m-0 p-0" style="width: 11.1%">Total real</th> -->
        <th class="td m-0 p-0" style="width: 12.5%">Cadena/Trama</th>
        <th class="td m-0 p-0" style="width: 12.5%">OP</th>
        <th class="td m-0 p-0" style="width: 12.5%">Acciones</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="groupheader" let-tela let-rowIndex="rowIndex">
      <tr pRowGroupHeader>
        <td class="m-0 p-0 truncate fw-bold" colspan="1">
            <span class="">
                {{getOrderArray?.controls[rowIndex]?.get('nombre').value}} -
                {{getOrderArray?.controls[rowIndex]?.get('codigo').value}}
            </span>
        </td>
        <td class="m-0 p-0 truncate" colspan="6"></td>
        <td class="m-0 p-0 truncate" colspan="2">
            <p class="p-error" *ngIf="getOrderArray?.controls[rowIndex].hasError('totalExceeded')">No puede
                superar la
                cantidad <strong>original</strong></p>
            <p class="p-error"
                *ngIf="getOrderArray?.controls[rowIndex].hasError('totalMaxExceeded') && !getOrderArray?.controls[rowIndex].hasError('totalExceeded')">
                La suma de los
                totales no puede superar
                cantidad <strong>original ({{tela.get('totalKgsOriginCopy').value}})</strong></p>

        </td>
    </tr>
    </ng-template>
    <ng-template pTemplate="body" let-tela let-rowIndex="rowIndex" formArrayName="orderArray">
      <tr [formGroupName]="rowIndex">
        <td class="td m-0 p-0 fw-bold truncate">{{getOrderArray?.controls[rowIndex]?.get('nombre').value}}</td>
        <td class="td m-0 p-0 fw-bold truncate">{{getOrderArray?.controls[rowIndex]?.get('tipo').value}}</td>
        <td class="td m-0 p-0 truncate" [pEditableColumn]="tela.totalKgs" pEditableColumnField="totalKgs">
          <p-cellEditor>
              <ng-template pTemplate="input">
                  <p-inputNumber [min]="0" formControlName="totalKgs"
                      (onInput)="restTotalKgs($event,tela,rowIndex,getOrderArray?.controls[rowIndex])"
                      *ngIf="enableChangeTotalKgsOrigin(tela,rowIndex); else totalKgs" inputId="integeronly"
                      class="w-75 height" inputStyleClass="height w-75">
                  </p-inputNumber>
                  <p class="danger" *ngIf="
                       getOrderArray.controls[rowIndex].get('totalKgs').hasError('min') &&
                       getOrderArray.controls[rowIndex].get('totalKgs').touched">
                      <small class="p-error">El total Kgs debe ser mayor a <strong>0</strong></small>
                  </p>
              </ng-template>
              <ng-template #totalKgs pTemplate="output" class="">
                  {{getOrderArray?.controls[rowIndex]?.get('totalKgs').value?.toFixed(1) || '0'}}
              </ng-template>
          </p-cellEditor>
      </td>
        <td class="td m-0 p-0 fw-bold truncate">
          <p-dropdown formControlName="tejedorSelect" appendTo="body" placeholder="----" class=""
            [options]="tela.get('tejedores').value" [style]="{ width : '75%', border: '2px solid #F6CACA' }"
            optionLabel="nombre" [showClear]="tela.get('tejedorSelect').value"
            (onChange)="enableMachineSelect(rowIndex,$event)" (onClear)="disabledMachineSelect(rowIndex)"
            styleClass="height">
            <ng-template let-option pTemplate="item">
              <span [class]="'tejedor-' + option.id">{{ option.nombre }}</span>
            </ng-template>
          </p-dropdown>
        </td>
        <td class="td m-0 p-0 truncate">
          <p-dropdown formControlName="maquinaSelect" appendTo="body" placeholder="----" class=""
            [options]="tela.get('maquina').value" [style]="{ width : '75%', border: '2px solid #F6CACA' }"
            optionLabel="nombre" [showClear]="true" styleClass="height">
            <ng-template let-option pTemplate="item">
              <span [class]="'machine-' + option.id">{{ option.nombre }}</span>
            </ng-template>
          </p-dropdown>
        </td>
        <!-- <td class="td m-0 p-0 fw-bold truncate">
          {{ getOrderArray?.controls[rowIndex]?.get('totalReal').value.toFixed(1) || '-' }}
        </td> -->
        <td class="td m-0 p-0 truncate">
          <!-- Si no hay hilados disponibles por asignar -->
          <p-button *ngIf="
            (tela.get('objectComplete').value.hilado1List.length <= 0 
            && tela.get('objectComplete').value.hilado2List.length <= 0 
            && tela.get('objectComplete').value.hilado3List.length <= 0)"
            [disabled]="!getOrderArray?.controls[rowIndex]?.get('maquinaSelect').value"
            styleClass="p-button-sm p-button-secondary p-button-outlined p-button-text padding-0 icon-width-2 bi bi-box-seam"
            pTooltip="Asignar hilado" tooltipPosition="bottom">
          </p-button>

          <!-- Si hay hilados disponibles por asignar pero el usuario no asigno ninguno -->
          <p-button [disabled]="!getOrderArray?.controls[rowIndex]?.get('maquinaSelect').value" *ngIf="tela.get('estado').value == 'CADENA-PLANO-NO-ASIGNADO'                
                    &&  (tela.get('objectComplete').value.hilado1List.length > 0 
                        || tela.get('objectComplete').value.hilado2List.length > 0 
                        || tela.get('objectComplete').value.hilado3List.length > 0)" class=""
            (onClick)="showModalAssignYarn(tela,rowIndex)"
            styleClass="p-button-sm p-button-warning p-button-outlined p-button-text padding-0 icon-width-2 bi bi-box-seam"
            pTooltip="Asignar hilado" tooltipPosition="bottom">
          </p-button>

          <!-- Si los hilados están disponibles para ser asignados (es el por defecto) -->
          <p-button [disabled]="!getOrderArray?.controls[rowIndex]?.get('maquinaSelect').value" *ngIf="tela.get('estado').value != 'CADENA-PLANO-ASIGNADO' && tela.get('estado').value != 'CADENA-PLANO-NO-ASIGNADO' 
                    && tela.get('estado').value != 'CADENA-PLANO-PARCIALMENTE-ASIGNADO'                
                    &&  (tela.get('objectComplete').value.hilado1List.length > 0 
                        || tela.get('objectComplete').value.hilado2List.length > 0 
                        || tela.get('objectComplete').value.hilado3List.length > 0)" class=""
            (onClick)="showModalAssignYarn(tela,rowIndex)"
            styleClass="p-button-sm p-button-danger p-button-outlined p-button-text padding-0 icon-width-2 bi bi-box-seam"
            pTooltip="Asignar hilado" tooltipPosition="bottom">
          </p-button>

          <!-- Cuando se asigno al menos uno de los hilados -->
          <p-button *ngIf="tela.get('estado').value == 'CADENA-PLANO-PARCIALMENTE-ASIGNADO'"
            [disabled]="!getOrderArray?.controls[rowIndex]?.get('maquinaSelect').value"
            (onClick)="showModalAssignYarn(tela,rowIndex)"
            styleClass="p-button-sm p-button-primary p-button-outlined p-button-text padding-0 icon-width-2 bi bi-box-seam"
            pTooltip="Asignar hilado" tooltipPosition="bottom">
          </p-button>

          <!-- Cuando se asignaron todos los hilados correspondientes -->
          <p-button *ngIf="tela.get('estado').value == 'CADENA-PLANO-ASIGNADO'"
            [disabled]="!getOrderArray?.controls[rowIndex]?.get('maquinaSelect').value"
            (onClick)="showModalAssignYarn(tela,rowIndex)"
            styleClass="p-button-sm p-button-success p-button-outlined p-button-text padding-0 icon-width-2 bi bi-box-seam"
            pTooltip="Asignar hilado" tooltipPosition="bottom">
          </p-button>

        </td>
        <td class="td m-0 p-0 truncate fw-bold">
          <div class="row m-0 p-0">
            <div class="col m-0 p-0">
              <p-button *ngIf="tela.get('claves').value && tela.get('claves').value.length > 0" icon="pi pi-eye"
                class=""
                styleClass="p-button-sm p-button-secondary p-button-outlined p-button-text padding-0 icon-width-1"
                pTooltip="Ver detalle" tooltipPosition="bottom" (onClick)="showModalOrderProduction(tela,rowIndex)">
              </p-button>
            </div>
          </div>
        </td>
        <td class="td m-0 p-0">
          <div class="row m-0 p-0">
            <div class="col m-0 p-0">
              <p-button icon="" id="circular"
                styleClass="p-button-sm  p-button-secondary p-button-outlined p-button-text padding-0 icon-width-2 bi bi-arrows-expand-vertical cursor"
                pTooltip="Dividir" tooltipPosition="bottom" (onClick)="addItemForm(tela,rowIndex,true)"></p-button>
            </div>
            <div class="col m-0 p-0">
              <p-button *ngIf="getOrderArray?.controls[rowIndex]?.get('copy').value === false" icon="pi pi-undo"
                class=""
                styleClass="p-button-sm  p-button-secondary p-button-outlined p-button-text padding-0 icon-width-2"
                pTooltip="Borrar divisiones" tooltipPosition="bottom"
                (onClick)="restartItemForm(tela,rowIndex)"></p-button>

              <p-button *ngIf="getOrderArray?.controls[rowIndex]?.get('copy').value === true" icon="pi pi-trash"
                class=""
                styleClass="p-button-sm  p-button-secondary p-button-outlined p-button-text padding-0 icon-width-2"
                pTooltip="Eliminar" tooltipPosition="top" (onClick)="deleteItemForm(tela,rowIndex)"></p-button>
            </div>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="loadingbody" let-rowData>
      <tr class="h-100 w-100">
        <td class="td m-0 p-0 truncate" colspan="13">
          <div class="text-center">
            <span>
              <p-progressSpinner></p-progressSpinner>
            </span>
            <br />
            <span class="text-center">Cargando...</span>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" class="w-100">
      <tr>
        <td colspan="12">
          <p-messages class="w-100" [value]="[{ severity: 'info', detail: 'No hay pedidos' }]" [enableService]="false"
            [closable]="false">
          </p-messages>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <div class="row d-flex justify-content-center my-5">
    <div class="col-6 text-start m-0 p-0">
      <!-- <p-button (click)="resetForm()" styleClass="p-button-sm p-button-success" class="mx-3" label="Reset"
          [loading]="loadingSelectOrders"></p-button> -->
    </div>
    <div class="col-6 text-end">
      <p-button type="submit" [disabled]="form.invalid"
        styleClass="p-button-rounded p-button-sm p-button-secondary programmer-machine-weaving padding-1"
        class="my-3 mx-3" label="Programar máquinas" [loading]="loadingSelectOrders"></p-button>
    </div>
  </div>
</form>