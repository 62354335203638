import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ProductionService } from 'src/app/api/services/production/production.service';
import { FilterEnum } from 'src/app/shared/filters/enum/filters.enum';
import { Method } from 'src/app/shared/filters/enum/method.enum';
import { Filters } from 'src/app/shared/filters/interface/filters.interface';
import { Item } from 'src/app/shared/models/model-forms/item.interface';
import { DataService } from 'src/app/api/services/data/data.service';
import { WeavingService } from 'src/app/api/services/production/weaving/weaving.service';

@Component({
  selector: 'app-production-weaving-planning',
  templateUrl: './production-weaving-planning.component.html',
  styleUrls: ['./production-weaving-planning.component.css']
})
export class ProductionWeavingPlanningComponent implements OnInit {
  spinner = true;

  orders: any[] = [];

  paginate: any = 1;

  totalRecords = 1;

  size = 50;

  customers: Item[] = [
    {
      label: 'TODOS',
      value: '0',
    },
    {
      label: '01000901 BEA ALLER ATUCHA S.R.L',
      value: '1',
    },
    {
      label: '01000774 SWEET VICTORIAN S.A',
      value: '2',
    },
    {
      label: '01000118 C Y L S.R.L',
      value: '3',
    },
  ];

  fabric: Item[] = [
    {
      label: 'TODOS',
      value: 'all',
    },
    {
      label: 'MALAGA',
      value: '1',
    },
    {
      label: 'MADRID FRISADO',
      value: '2',
    },
  ];

  prioritys: Item[] = [
    {
      label: 'TODOS',
      value: 'all',
    },
    {
      label: 'BAJA',
      value: 'BAJA',
    },
    {
      label: 'MEDIA',
      value: 'MEDIA',
    },
    {
      label: 'ALTA',
      value: 'ALTA',
    },
  ];

  color: Item[] = [
    {
      label: 'TODOS',
      value: 'all',
    },
    {
      label: 'Rojo',
      value: 'Rojo',
    },
    {
      label: 'Azul',
      value: 'Azul',
    },
    {
      label: 'Amarillo',
      value: 'Amarillo',
    },
  ];

  filters: Filters = {
    autoSend: true,
    method: Method.GET,
    filtersCustom: [
      {
        type: FilterEnum.INPUTTEXT,
        col: 'col-12 col-md-4 col-lg-2 my-3 my-lg-0',
        title: '',
        placeholder: 'Buscar',
        titleClass: '',
        inputClass: 'rounded-pill',
        inputStyle: {
          width: '100%',
        },
        order: 1,
        nameFilter: 'search',
        valueFilter: '',
      },
      {
        type: FilterEnum.MULTISELECT,
        col: 'col-12 col-md-4 col-lg-2 my-3 my-lg-0',
        title: '',
        placeholder: 'Cliente',
        titleClass: '',
        inputStyle: {
          width: '100%',
          'border-radius': '50rem',
        },
        nameFilter: 'fabric',
        valueFilter: '',
        order: 2,
        items: {
          label: 'label',
          value: 'value',
          search: true,
          items: this.customers,
        },
      },
      {
        type: FilterEnum.MULTISELECT,
        col: 'col-12 col-md-4 col-lg-2 my-3 my-lg-0',
        title: '',
        placeholder: 'Tela',
        titleClass: '',
        inputStyle: {
          width: '100%',
          'border-radius': '50rem',
        },
        nameFilter: 'fabric',
        valueFilter: '',
        order: 3,
        items: {
          label: 'label',
          value: 'value',
          search: true,
          items: this.fabric,
        },
      },
      {
        type: FilterEnum.MULTISELECT,
        col: 'col-12 col-md-4 col-lg-2 my-3 my-lg-0',
        title: '',
        placeholder: 'Prioridad',
        titleClass: '',
        inputStyle: {
          width: '100%',
          'border-radius': '50rem',
        },
        nameFilter: 'fabric',
        valueFilter: '',
        order: 4,
        items: {
          label: 'label',
          value: 'value',
          search: true,
          items: this.prioritys,
        },
      },
      {
        type: FilterEnum.MULTISELECT,
        col: 'col-12 col-md-4 col-lg-2 my-3 my-lg-0',
        title: '',
        placeholder: 'Color',
        titleClass: '',
        inputStyle: {
          width: '100%',
          'border-radius': '50rem',
        },
        nameFilter: 'fabric',
        valueFilter: '',
        order: 5,
        items: {
          label: 'label',
          value: 'value',
          search: true,
          items: this.color,
        },
      },
    ],
  };


  telas: any = []

  constructor(
    private productionService: ProductionService,
    private messageService: MessageService,
    private dataService: DataService,
    private weavingService: WeavingService
  ) { }

  ngOnInit(): void {
    this.getPedidosTejeduria(true);
  }

  refresh(event: any) {
    this.spinner = true;
    setTimeout(() => {
      this.spinner = false
    }, 500);
  }

  getPedidosTejeduria(refresh = false) {
    this.spinner = refresh
    if (refresh) {
      this.weavingService.getPedidosTejeduria().subscribe(({
        next: (data) => {
          this.telas = data.list
        }, error: (err) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: err.error ? err.error.message : 'Ups! Ocurrio un error',
          });
        },
        complete: () => {
          this.spinner = false;
        }
      }))
    }

  }



}
