import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PublicModule } from './public/public.module';
import { SharedModule } from './shared/shared.module';
import { PrimengModule } from './shared/primeng/primeng.module';
import { MessageService } from 'primeng/api';
import { AuthModule } from './modules/auth/auth.module';
import { OrderModule } from './modules/order/order.module';
import { ProductionModule } from './modules/production/production.module';
import { ProductionWeavingModule } from './modules/production/production-weaving/production-weaving.module';
import { ProductionCleaningModule } from './modules/production/production-cleaning/production-cleaning.module';
import { ProductionWarpedModule } from './modules/production/production-warped/production-warped.module';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DatePipe } from '@angular/common';
import { BomsModule } from './modules/boms/boms.module';
import { FabricsModule } from './modules/boms/fabrics/fabrics.module';
import { MachineModule } from './modules/boms/machines/machine.module';
import { ProcessModule } from './modules/boms/processes/process.module';
import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en'; 
registerLocaleData(localeEn);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    PublicModule,
    AuthModule,
    OrderModule,
    ProductionModule,
    ProductionCleaningModule,
    ProductionWeavingModule,
    ProductionWarpedModule,
    BomsModule,
    FabricsModule,
    MachineModule,
    ProcessModule,
    SharedModule,
    PrimengModule,
  ],
  providers: [MessageService,DialogService,DynamicDialogRef,DynamicDialogConfig,DatePipe,{ provide: LOCALE_ID, useValue: 'en' }],
  bootstrap: [AppComponent],
  exports : [BrowserModule,BrowserAnimationsModule]
})
export class AppModule { }
