import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Order } from '../../../../../shared/models/order/order.class';
import { DataService } from 'src/app/api/services/data/data.service';
import { MessageService } from 'primeng/api';
import { OrderService } from 'src/app/api/services/order/order.service';

@Component({
  selector: 'app-add-order',
  templateUrl: './add-order.component.html',
  styleUrls: ['./add-order.component.css'],
})
export class AddOrderComponent implements OnInit{

  form:FormGroup;

  @Output()
  eventEmitterNewOrder:EventEmitter<any> = new EventEmitter()

  articles:any = [];
  colors:any = [];
  indications:any = [
    {nombre : "IMPER + RESINA", id:'IR'},
    {nombre : "FRISADO", id:'F'},
    {nombre : "IMPER", id:'I'},
    {nombre : "LISO", id:'L'},
  ]

  constructor(private datasService:DataService, private messageService:MessageService,private orderService:OrderService){

  }

  ngOnInit(): void {
    this.form = new FormGroup({
      article : new FormControl("",[Validators.required]),
      color : new FormControl("",[Validators.required]),
      indication : new FormControl("",[Validators.required]),
      quantity : new FormControl("",[Validators.required]),
    })
    this.getColors()
    this.getTelas();
  }


  getColors(){
    this.datasService.getColors().subscribe(({
      next : (data)=>{  
        this.colors = data;
      }, error : (err)=>{
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: err.error ? err.error.message : 'Ups! Ocurrio un error',
        });
      }
    }))
  }

  getTelas(){
    this.datasService.getTelas().subscribe(({
      next : (data)=>{  
        this.articles = data.list;
      }, error : (err)=>{
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: err.error ? err.error.message : 'Ups! Ocurrio un error',
        });
      }
    }))
  }

  addOrderSubmit(){
    let order = new Order();
    order.tela = this.form.get('article').value.nombre;
    order.telaId = this.form.get('article').value;
    order.color = this.form.get('color').value.nombre;
    order.colorId = this.form.get('color').value.id;
    order.cantidad = this.form.get('quantity').value;
    order.indicacion = this.form.get('indication').value;

    this.orderService
    .addNewOrder({
      cantidad: order.cantidad,
      idColor: order.colorId,
      indicacion: order.indicacion,
      tela: order.tela,
    })
    .subscribe({
      next: (data) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Orden agregada!',
          detail: '',
        });
        this.eventEmitterNewOrder.emit(data);
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: err.error ? err.error.message : 'Ups! Ocurrio un error',
        });
      },
      complete: () => {},
    });


    this.form.reset();
  }



}
