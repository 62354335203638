import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProductionWarpedRoutingModule } from './production-warped-routing.module';
import { TableProductionWarpedPlanningComponent } from './components/table-production-warped-planning/table-production-warped-planning.component';
import { OrderModule } from '../../order/order.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { PublicModule } from 'src/app/public/public.module';
import { ProductionWarpedPlanningComponent } from './pages/production-warped-planning/production-warped-planning.component';
import { ProductionWarpedAllocateRawMaterialComponent } from './pages/production-warped-allocate-raw-material/production-warped-allocate-raw-material.component';
import { TableProductionWarpedAllocateRawMaterialComponent } from './components/table-production-warped-allocate-raw-material/table-production-warped-allocate-raw-material.component';
import { ProductionWarpedAssignMpModalComponent } from './modals/production-warped-assign-mp-modal/production-warped-assign-mp-modal.component';
import { AddOrderWarpedComponent } from './modals/add-order-warped/add-order-warped.component';


@NgModule({
  declarations: [
    TableProductionWarpedPlanningComponent,
    ProductionWarpedPlanningComponent,
    ProductionWarpedAllocateRawMaterialComponent,
    TableProductionWarpedAllocateRawMaterialComponent,
    ProductionWarpedAssignMpModalComponent,
    AddOrderWarpedComponent
  ],
  imports: [
    CommonModule,
    ProductionWarpedRoutingModule,
    OrderModule,
    SharedModule,
    PublicModule
  ],
  exports : [
    TableProductionWarpedPlanningComponent,
  ]
})
export class ProductionWarpedModule { }
