import { Component, OnInit, OnDestroy } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { OrderService } from 'src/app/api/services/order/order.service';
import { Order } from 'src/app/shared/models/order/order.class';

@Component({
  selector: 'app-details-order-modal',
  templateUrl: './details-order-modal.component.html',
  styleUrls: ['./details-order-modal.component.css'],
})
export class DetailsOrderModalComponent implements OnInit, OnDestroy {


  spinner = true;

  order: Order = new Order()

  constructor(
    private messageService: MessageService,
    public dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
    private orderService:OrderService
  ) { }


  ngOnInit(): void {
    this.order = this.dialogConfig.data.order;
    this.spinner = false;
  }

  ngOnDestroy(): void {
    this.spinner = true;
  }

  totalDepositoCantidad(stocksAsignado: any) {
    let total = 0;
    stocksAsignado.forEach(element => {
      total += element.cantidad
    });
    return total;
  }

  deleteDeposit(index) {
    this.order.stockAsignado.splice(index, 1)
  }

  sendDeposits(){
    let ordenes = []
    let articuloId = this.order.id;
    this.order.stockAsignado.forEach((element, i) => {
      ordenes.push(
        {
          depositoId: element.depositoId,
          articuloId: this.order.id,
          cantidad :  element.cantidad,
          order: i + 1
        }
      )
    })

    this.orderService.assignOrderMultiple(ordenes.length > 0 ? ordenes : [{articuloId}]).subscribe({
      next: (data) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Guardado',
        });
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: err.error ? err.error.message : 'Ups! Ocurrio un error',
        });
      },
      complete: () => {
        this.dialogRef.close({ success: true, order: this.order })
      },
    });
  }


}
