import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, of } from 'rxjs';
import { Method } from 'src/app/shared/filters/enum/method.enum';
import { FilterService } from 'src/app/shared/filters/services/filter.service';
import { ServerResponse } from 'src/app/shared/models/model-forms/server.response.interface';
import { Order } from 'src/app/shared/models/order/order.class';
import { environment } from 'src/environments/environment.development';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  orders: Order[] = [];

  serverResponse: ServerResponse<Order> = {
    currentPage: 1,
    list: this.orders,
    totalElements: this.orders.length,
    totalPages: 10,
  };

  constructor(private httpClient: HttpClient,private filterService:FilterService) {}

  getOrders(query:any = [],methodSearch:Method = Method.GET): Observable<ServerResponse<Order>> {

    let filtersAccept = ["telaId","colorId","maquinaId","inicioFechaDeEntrega","finFechaDeEntrega","cliente"]

    let queryBuild = methodSearch === Method.POST ? this.filterService.getFiltersForPost(query,filtersAccept)
                    : this.filterService.getFiltersForGet(query,filtersAccept)

    let headers = new HttpHeaders();

    headers = headers.append(
      'Authorization',
      'Bearer ' + localStorage.getItem('token')
    );

    return this.httpClient
      .get(`${environment.apiUrl}/pedidos/lista?${queryBuild}`, { headers: headers })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  assignMachines(orders: any): Observable<ServerResponse<any>> {
    let headers = new HttpHeaders();

    headers = headers.append(
      'Authorization',
      'Bearer ' + localStorage.getItem('token')
    );

    return this.httpClient
      .post(`${environment.apiUrl}/asignacion-maquinas/lista`, orders,{
        headers: headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  addNewOrder(orders: any): Observable<ServerResponse<any>> {
    let headers = new HttpHeaders();

    headers = headers.append(
      'Authorization',
      'Bearer ' + localStorage.getItem('token')
    );

    return this.httpClient
      .post(`${environment.apiUrl}/pedidos/nuevo`, orders,{
        headers: headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }


  deleteOrder(order: any): Observable<ServerResponse<any>> {
    let headers = new HttpHeaders();

    headers = headers.append(
      'Authorization',
      'Bearer ' + localStorage.getItem('token')
    );

    return this.httpClient
      .post(`${environment.apiUrl}/pedidos/borrar/${order.id}`, {},{
        headers: headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  assignOrder(articuloId:any,depositoId:any): Observable<ServerResponse<any>> {
    let headers = new HttpHeaders();

    headers = headers.append(
      'Authorization',
      'Bearer ' + localStorage.getItem('token')
    );

    return this.httpClient
      .post(`${environment.apiUrl}/stock/asignar`, {articuloId,depositoId},{
        headers: headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  assignOrderMultiple(orders): Observable<ServerResponse<any>> {
    let headers = new HttpHeaders();

    headers = headers.append(
      'Authorization',
      'Bearer ' + localStorage.getItem('token')
    );

    return this.httpClient
      .post(`${environment.apiUrl}/stock/asignar-multiples`, orders,{
        headers: headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }


  getDetailOrder(id: string): Observable<Order> {
    let headers = new HttpHeaders();

    // headers = headers.append(
    //   'Authorization',
    //   "Bearer" + localStorage.getItem('token')
    // );

    return this.httpClient
      .get(`${environment.apiUrl}/order/${id}`, { headers: headers })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  updateDatePromiseOrder(order: any,data:any): Observable<ServerResponse<any>> {
    let headers = new HttpHeaders();

    headers = headers.append(
      'Authorization',
      'Bearer ' + localStorage.getItem('token')
    );

    return this.httpClient
      .post(`${environment.apiUrl}/pedidos/fecha-prometida/${order.id}`, data,{
        headers: headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
}
