<div class="card text-center" *ngIf="spinner">
  <span>
    <p-progressSpinner></p-progressSpinner>
  </span>
  <br />
  <span class="text-center">Cargando...</span>
</div>

<div *ngIf="form && !spinner">
  <form [formGroup]="form" (ngSubmit)="sendForm()">
    <div class="row mt-1">
      <div class="col-12 col-md-4">
        <label for="nombre" class="text-dark">Nombre: </label>
        <input type="text" pInputText formControlName="nombre" [style]="{ width: '100%' }" />
        <span class="danger" *ngIf="
                form.get('nombre').hasError('required') &&
                form.get('nombre').touched
              ">
          <small class="p-error">Este campo es <strong>requerido</strong></small>
        </span>
      </div>
      <div class="col-12 col-md-7">
        <label for="nombre" class="text-dark">Descripción: </label><br>
        <textarea formControlName="descripcion" rows="1" [style]="{ width: '100%' }" cols="30" pInputTextarea
          [autoResize]="true"></textarea>
        <span class="danger" *ngIf="
                form.get('descripcion').hasError('required') &&
                form.get('descripcion').touched
              ">
          <small class="p-error">Este campo es <strong>requerido</strong></small>
        </span>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12 col-md-4">
        <label for="nombre" class="text-dark">Alimentadores: </label>
        <input type="text" pInputText formControlName="alimentadores" [style]="{ width: '100%' }" />
        <span class="danger" *ngIf="
                form.get('alimentadores').hasError('required') &&
                form.get('alimentadores').touched
              ">
          <small class="p-error">Este campo es <strong>requerido</strong></small>
        </span>
      </div>
      <div class="col-12 col-md-4">
        <label for="nombre" class="text-dark">Kilos por baño: </label>
        <input type="number" pInputText formControlName="kilosPorBanio" [style]="{ width: '100%' }" />
        <span class="danger" *ngIf="
                form.get('kilosPorBanio').hasError('required') &&
                form.get('kilosPorBanio').touched
              ">
          <small class="p-error">Este campo es <strong>requerido</strong></small>
        </span>
      </div>
      <div class="col-12 col-md-3">
        <label for="number" class="text-dark">Baños por día: </label>
        <input type="text" pInputText formControlName="baniosPorDia" [style]="{ width: '100%' }" />
        <span class="danger" *ngIf="
                form.get('baniosPorDia').hasError('required') &&
                form.get('baniosPorDia').touched
              ">
          <small class="p-error">Este campo es <strong>requerido</strong></small>
        </span>
      </div>
    </div>
    <div class="row mt-3">
      <div   [ngClass]="{
        'col-12 col-md-5' :  form.get('deposito').value == '0',
        'col-11' :  form.get('deposito').value != '0'
      }">
        <label for="deposito" class="text-dark">Proveedor: </label>
        <p-dropdown (onChange)="changeDeposit($event)" appendTo="body" [style]="{ width: '100%' }" formControlName="deposito" id="deposits"
          placeholder="Seleccione una opción..." [options]="deposits" defaultLabel="Seleccione una opción..."
          optionLabel="nombre" optionValue="id">
          <ng-template let-option pTemplate="item">
            <span [class]="'deposito-' + option.id">
              {{ option.nombre }}
            </span>
          </ng-template>
        </p-dropdown>
        <span class="danger" *ngIf="
                form.get('deposito').hasError('required') &&
                form.get('deposito').touched
              ">
          <small class="p-error">Este campo es <strong>requerido</strong></small>
        </span>
      </div>
      <div class="col-12 col-md-6" *ngIf="form.get('deposito').value === '0'">
        <label for="nuevoProceso" class="text-dark">Nuevo proveedor: </label>
        <input type="text" pInputText formControlName="depositoNuevo" [style]="{ width: '100%' }" />
        <!-- <span class="danger" *ngIf=" 
                  process.get('nuevoProceso').hasError('required') &&
                  process.get('nuevoProceso').touched
                ">
          <small class="p-error">Este campo es <strong>requerido</strong></small>
        </span> -->
      </div>
    </div>
    <div formArrayName="procesos">
      <div class="" *ngFor="
        let process of getProcessArray.controls;
        let i = index
      " [formGroupName]="i">
        <div class="row mt-3">
          <div [ngClass]="{
            'col-12 col-md-5' :  process.get('proceso').value == 'Otro',
            'col-11' :  process.get('proceso').value != 'Otro'
          }">
            <label for="nombre" class="text-dark">Proceso: </label>
            <p-dropdown (onChange)="disabledEnabledNewProcess($event,i)" appendTo="body" [style]="{ width: '100%' }"
              formControlName="proceso" id="processes" placeholder="Seleccione una opción..." [options]="processes"
              defaultLabel="Seleccione una opción..." optionLabel="proceso" optionValue="value">
              <ng-template let-option pTemplate="item">
                <span [class]="'process-' + option.value">
                  {{ option.proceso }}
                </span>
              </ng-template>
            </p-dropdown> <span class="danger" *ngIf="
                      process.get('proceso').hasError('required') &&
                      process.get('proceso').touched
                    ">
              <small class="p-error">Este campo es <strong>requerido</strong></small>
            </span>
          </div>
          <div class="col-12 col-md-6" *ngIf="process.get('proceso').value == 'Otro'">
            <label for="nuevoProceso" class="text-dark">Nuevo proceso: </label>
            <input type="text" pInputText formControlName="nuevoProceso" [style]="{ width: '100%' }" />
            <span class="danger" *ngIf=" 
                      process.get('nuevoProceso').hasError('required') &&
                      process.get('nuevoProceso').touched
                    ">
              <small class="p-error">Este campo es <strong>requerido</strong></small>
            </span>
          </div>
          <div class="col-1">
            <br>
            <p-button  *ngIf="i == 0" (click)="addProcessItem()" pTooltip="Añadir proceso"
              styleClass="button-add-divide-cleaning" icon="pi pi-plus" tooltipPosition="top"></p-button>
            <p-button pTooltip="Eliminar" styleClass="button-add-divide-cleaning" icon="pi pi-trash"
              tooltipPosition="top" (click)="deleteProcessItem(i)" *ngIf="i > 0"></p-button>
          </div>
        </div>
      </div>
    </div>
    <div formArrayName="generos">
      <div class="" *ngFor="
        let genre of getFabricGenreArray.controls;
        let i = index
      " [formGroupName]="i">
        <div class="row mt-3">
          <div class="col-11">
            <label for="number" class="text-dark">Género: </label>
            <p-dropdown (onChange)="disabledEnabledGenreSelect($event,genre,i)" [filter]="true" appendTo="body"
              [style]="{ width: '100%' }" formControlName="tela" id="fabrics" placeholder="Seleccione una opción..."
              [options]="genre.get('telas').value" defaultLabel="Seleccione una opción..." optionLabel="nombre"
              optionValue="id">
              <ng-template let-option pTemplate="item">
                <span [class]="'fabric-' + option.id">
                  {{ option.nombre }}
                </span>
              </ng-template>
            </p-dropdown>
            <span class="danger" *ngIf="
            genre.get('tela').hasError('required') &&
            genre.get('tela').touched
          ">
              <small class="p-error">Este campo es <strong>requerido</strong></small>
            </span>
          </div>
          <div class="col-1">
            <br>
            <p-button [disabled]="genre.get('tela').value == 'TODAS'" *ngIf="i == 0" (click)="genre.get('tela').value == 'TODAS' ? '' : addFabricGenreItem()" pTooltip="Añadir"
              styleClass="button-add-divide-cleaning" icon="pi pi-plus" tooltipPosition="top"></p-button>
            <p-button pTooltip="Eliminar" styleClass="button-add-divide-cleaning" icon="pi pi-trash"
              tooltipPosition="top" (click)="deleteFabricGenreItem(i)" *ngIf="i > 0"></p-button>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12 col-md-4">
            <label for="nombre" class="text-dark">Cantidad de rollos por día: </label>
            <input type="text" pInputText formControlName="cantRollosPorDia" [style]="{ width: '100%' }" />
            <span class="danger" *ngIf="
                    genre.get('cantRollosPorDia').hasError('required') &&
                    genre.get('cantRollosPorDia').touched
                  ">
              <small class="p-error">Este campo es <strong>requerido</strong></small>
            </span>
          </div>
          <div class="col-12 col-md-4">
            <label for="kilosPorDia" class="text-dark">Kilos por día: </label>
            <input type="text" pInputText formControlName="kilosPorDia" [style]="{ width: '100%' }" />
            <span class="danger" *ngIf="
                    genre.get('kilosPorDia').hasError('required') &&
                    genre.get('kilosPorDia').touched
                  ">
              <small class="p-error">Este campo es <strong>requerido</strong></small>
            </span>
          </div>
          <div class="col-12 col-md-3">
            <label for="kilosPorRollo" class="text-dark">Kilos por rollo: </label>
            <input type="text" pInputText formControlName="kilosPorRollo" [style]="{ width: '100%' }" />
            <span class="danger" *ngIf="
                    genre.get('kilosPorRollo').hasError('required') &&
                    genre.get('kilosPorRollo').touched
                  ">
              <small class="p-error">Este campo es <strong>requerido</strong></small>
            </span>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12 col-md-4">
            <label for="tiempoRolloMinuto" class="text-dark">Tiempo rollo por minuto: </label>
            <input type="text" pInputText formControlName="tiempoRolloMinuto" [style]="{ width: '100%' }" />
            <span class="danger" *ngIf="
                    genre.get('tiempoRolloMinuto').hasError('required') &&
                    genre.get('tiempoRolloMinuto').touched
                  ">
              <small class="p-error">Este campo es <strong>requerido</strong></small>
            </span>
          </div>
          <div class="col-12 col-md-4">
            <label for="velocidadVariable" class="text-dark">Velocidad variable: </label>
            <input type="text" pInputText formControlName="velocidadVariable" [style]="{ width: '100%' }" />
            <span class="danger" *ngIf="
                    genre.get('velocidadVariable').hasError('required') &&
                    genre.get('velocidadVariable').touched
                  ">
              <small class="p-error">Este campo es <strong>requerido</strong></small>
            </span>
          </div>
          <div class="col-12 col-md-3">
            <label for="vueltasNecesarias" class="text-dark">Vueltas necesarias: </label>
            <input type="text" pInputText formControlName="vueltasNecesarias" [style]="{ width: '100%' }" />
            <span class="danger" *ngIf="
                    genre.get('vueltasNecesarias').hasError('required') &&
                    genre.get('vueltasNecesarias').touched
                  ">
              <small class="p-error">Este campo es <strong>requerido</strong></small>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-11 text-end">
        <p-button [label]="title" styleClass="p-button p-button-secondary p-button-rounded date-promise-button"
          type="submit" [disabled]="form.invalid">
        </p-button>
      </div>
    </div>
  </form>
</div>