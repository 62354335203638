import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { OrderService } from 'src/app/api/services/order/order.service';
import { DataService } from 'src/app/api/services/data/data.service';
import { concat, concatMap, of, tap } from 'rxjs';
import { FabricService } from 'src/app/api/services/fabric/fabric.service';
import { ProcessService } from 'src/app/api/services/process/process.service';

@Component({
  selector: 'app-form-machine',
  templateUrl: './form-machine.component.html',
  styleUrls: ['./form-machine.component.css']
})
export class FormMachineComponent {

  spinner = true;

  @Input()
  machine: any = {};

  form: FormGroup;

  @Output()
  eventUser: EventEmitter<any> = new EventEmitter();

  title: any = "Crear máquina"

  fabrics: any = []
  deposits: any = []
  processes: any = [
    { proceso:"Teñido", value: "Teñido" },
    { proceso: 'Urdido', value: "Urdido" },
    { proceso: 'Tejido', value: "Tejido" },
    { proceso: 'Lavado ', value: "Lavado" },
    { proceso: 'Terminacion', value: "Terminacion" },
    { proceso: 'Otro', value: "Otro" },
  ]

  constructor(
    public messageService: MessageService,
    public dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
    private orderService: OrderService,
    private datepipe: DatePipe,
    private dataService: DataService,
    private fabricService: FabricService,
    private processesService: ProcessService
  ) { }

  ngOnInit(): void {
    if (this.machine) {
      this.title = "Actualizar máquina"
    }

    this.getData();
    //  concatMap(() => concat(of(this.getDeposits())))

  }

  ngOnDestroy(): void {
    this.spinner = true;
  }

  startForm() {
    this.form = new FormGroup({
      id: new FormControl(this.machine?.id || '', []),
      baniosPorDia: new FormControl(this.machine?.baniosPorDia || "", []),
      alimentadores: new FormControl(this.machine?.alimentadores, []),
      colores: new FormControl(this.machine?.colores, []),
      deposito: new FormControl(this.machine?.deposito?.id || "", []),
      depositoNuevo: new FormControl("", []),
      descripcion: new FormControl(this.machine?.descripcion || "", []),
      generos: new FormArray(this.machine?.generos && this.machine?.generos.length > 0 ? [] : [this.createFabricGenreItem()], []),
      kilosPorBanio: new FormControl(this.machine?.kilosPorBanio || "", []),
      nombre: new FormControl(this.machine?.nombre || "", [Validators.required]),
      procesos: new FormArray(this.machine?.procesos && this.machine?.procesos.length > 0 ? [] : [this.createProcessItem()], [])
    });

    this.setDataGenre()
    this.setDataProcess();
  }

  setDataGenre(){
    this.machine?.generos.forEach(element => {
      this.addFabricGenreItem(element)
    });
    return []
  }

  setDataProcess(){
    this.machine?.procesos.forEach(element => {
      this.addProcessItem(element)
    });
    return []
  }

  createFabricGenreItem(fabric?:any,disabled:boolean = false) {
    return new FormGroup({
      cantRollosPorDia: new FormControl({value : fabric?.cantRollosPorDia, disabled } || '', []),
      kilosPorDia: new FormControl({value : fabric?.kilosPorDia , disabled} || '', []),
      kilosPorRollo: new FormControl({value : fabric?.kilosPorRollo, disabled} || '', []),
      tela: new FormControl({value : fabric?.id , disabled : false} || '', []),
      tiempoRolloMinuto: new FormControl({value : fabric?.tiempoRolloMinuto, disabled} || '', []),
      velocidadVariable: new FormControl({value : fabric?.velocidadVariable, disabled} || '', []),
      vueltasNecesarias: new FormControl({value : fabric?.vueltasNecesarias, disabled} || '', []),
      proceso: new FormControl('', []),
      nuevoProceso: new FormControl({value : "", disabled: true}, []),
      telas: new FormControl(this.fabrics, []),
    });
  }

  addFabricGenreItem(fabric?:any,disabled:boolean = false) {
    this.getFabricGenreArray.push(this.createFabricGenreItem(fabric,disabled));
  }

  deleteFabricGenreItem(index: any) {
    this.getFabricGenreArray.removeAt(index);
  }

  get getFabricGenreArray(): FormArray {
    return this.form.get('generos') as FormArray;
  }

  createProcessItem(process?:any) {
    let find = this.processes.find((element)=>{
      return element.value == process?.nombre;
    })

    return new FormGroup({
      proceso: new FormControl(find ? process?.nombre : process?.nombre && !find ? "Otro" : "" , []),
      nuevoProceso: new FormControl({value : !find ? process?.nombre : "", disabled: process?.nombre && !find ? false : true}, []),
      id: new FormControl(process?.id || "", []),
    });
  }

  get getProcessArray(): FormArray {
    return this.form.get('procesos') as FormArray;
  }

  addProcessItem(process?:any) {
    this.getProcessArray.push(this.createProcessItem(process));
  }

  deleteProcessItem(index: any) {
    this.getProcessArray.removeAt(index);
  }

  changeDeposit(event:any){
    if(event.value === "0"){
      this.form.get('depositoNuevo').enable()
    }else{
      this.form.get('depositoNuevo').setValue("")
      this.form.get('depositoNuevo').disable()
    }
  }

  disabledEnabledNewProcess(event:any,index:any){
    if(event.value === "Otro"){
      this.getProcessArray.controls[index].get('nuevoProceso').enable()
    }else{
      this.getProcessArray.controls[index].get('nuevoProceso').setValue("")
      this.getProcessArray.controls[index].get('nuevoProceso').disable()
    }

    // this.filterGenreByProcess(event.value,index)
  }

  filterGenreByProcess(process:any,index:any){
    const fabricsFiltrados = this.fabrics.filter((fabric) => {
      return fabric.procesos.some((proceso) => {
        return proceso.proceso.nombre === process;
      });
    });

    this.getFabricGenreArray.controls[index].get('telas').setValue(fabricsFiltrados.length > 0 ? fabricsFiltrados : this.fabrics)
  }

  getData() {
    this.fabricService.getListFabricsWithOutPagination().pipe(
      tap({
        next: (data) => {
          this.fabrics = data.list;
        },
        error: (err) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: err.error ? err.error.message : 'Ups! Ocurrió un error',
          });
        },
        complete: () => {
          this.fabrics.unshift({nombre: "TODAS", id: "TODAS"})
        }
      }),
      concatMap(() => this.getDeposits()),
      // concatMap(() => this.getProcesses()),
      concatMap(() => concat(of(this.startForm()))),
    ).subscribe();
  }

  getDeposits() {
    return this.dataService.getDepositos([]).pipe(tap({
      next: (data) => {
        this.deposits = data.list;
        this.deposits.push({id: '0', nombre: "Otro"})
      }, error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: err.error ? err.error.message : 'Ups! Ocurrio un error',
        });
      },
      complete : ()=>{
        this.spinner = false;
      }
    }))
  }

  getProcesses() {
    return this.processesService.getProcessesList().pipe(tap({
      next: (data) => {
        let procesos = [];
        this.processes = data.list;
        for (let i = 0; i < data.list.length; i++) {
          const nombreProceso = data.list[i].nombre
          if (!procesos.some(proceso => proceso.proceso === nombreProceso)) {
            procesos.push({ proceso: nombreProceso, value: nombreProceso });
          }
        }
        procesos.push({ proceso: "Otro", value: "Otro" });
        this.processes = procesos
      }, error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: err.error ? err.error.message : 'Ups! Ocurrio un error',
        });
      },
      complete: () => {
      }
    }))
  }

  disabledEnabledGenreSelect(event:any,form:any,index:any){
    const formArray = this.getFabricGenreArray
    
    if(event.value == "TODAS"){
      formArray.controls[index].get('cantRollosPorDia').setValue("")
      formArray.controls[index].get('kilosPorDia').setValue("")
      formArray.controls[index].get('kilosPorRollo').setValue("")
      formArray.controls[index].get('tiempoRolloMinuto').setValue("")
      formArray.controls[index].get('velocidadVariable').setValue("")
      formArray.controls[index].get('vueltasNecesarias').setValue("")

      formArray.controls[index].get('cantRollosPorDia').disable()
      formArray.controls[index].get('kilosPorDia').disable()
      formArray.controls[index].get('kilosPorRollo').disable()
      formArray.controls[index].get('tiempoRolloMinuto').disable()
      formArray.controls[index].get('velocidadVariable').disable()
      formArray.controls[index].get('vueltasNecesarias').disable()
      
      formArray.controls.forEach((element,i) => {
        if(index != i){
          formArray.removeAt(i)
        }
      });

    }else{
      formArray.controls.forEach(element => {
        element.get('cantRollosPorDia').enable()
        element.get('kilosPorDia').enable()
        element.get('kilosPorRollo').enable()
        element.get('tiempoRolloMinuto').enable()
        element.get('velocidadVariable').enable()
        element.get('vueltasNecesarias').enable()
      });
    }
  }

  sendForm() {
    let maquina =
    {
      id: this.form.get('id').value || "",
      nombre: this.form.get('nombre').value,
      baniosPorDia: this.form.get('baniosPorDia').value,
      alimentadores: this.form.get('alimentadores').value,
      deposito: this.form.get('depositoNuevo').value,
      depositoId: this.form.get('deposito').value,
      descripcion: this.form.get('descripcion').value,
      kilosPorBanio: this.form.get('kilosPorBanio').value,
      procesos : this.getProcessArray.controls.map((element,index)=>{
        
        return {
          // idDeposito :  this.form.get('deposito').value,
          id: element.get('id').value,
          nombre :  element.get('proceso').value === 'Otro' ? element.get('nuevoProceso').value : element.get('proceso').value,
          orderIndex : index + 1
        }
      }),
      // proceso: this.form.get('proceso').value === 'Otro' ? this.form.get('nuevoProceso').value : this.form.get('proceso').value,
      generos: this.getFabricGenreArray.controls.map((element) => {
        return {
          cantRollosPorDia: element.get('cantRollosPorDia').value,
          kilosPorDia: element.get('kilosPorDia').value,
          kilosPorRollo: element.get('kilosPorRollo').value,
          tela: element.get('tela').value == "TODAS" ? null : element.get('tela').value,
          tiempoRolloMinuto: element.get('tiempoRolloMinuto').value,
          velocidadVariable: element.get('velocidadVariable').value,
          vueltasNecesarias: element.get('vueltasNecesarias').value,
        }
      })
    }

    this.eventUser.emit(maquina)

  }


}
