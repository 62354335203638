import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, of } from 'rxjs';
import { environment } from 'src/environments/environment.development';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}

  login(data: any) {
    return this.http.post(`${environment.apiUrl}/authenticate`, data).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  loginAzure(data: any) {
    return this.http.post(`${environment.apiUrl}/authenticate/ad`, data).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  validateToken(token: string) {
    return this.http
      .post(`${environment.apiUrl}/validate`, token, {responseType: "text"
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  registro(data: any) {
    return this.http
      .post(`${environment.apiUrl}/usuarios/nuevo`, data, {
        observe: 'response',
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  recuperarContraseña(mail: string) {
    let data = {
      username: mail.trim(),
    };
    return this.http
      .post(`${environment.apiUrl}/usuarios/password?username=${mail}`, {})
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  cambiarContrasenia(data: any) {
    return this.http
      .post(`${environment.apiUrl}/usuarios/activar`, data, {
        observe: 'response',
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  mockActual(){
    return of({roles : ['ADMIN']})
  }

  actual() {
    let headers = new HttpHeaders();
    headers = headers.append(
      'Authorization',
      'Bearer ' + localStorage.getItem('token')
    );
    return this.http
      .get(`${environment.apiUrl}/usuarios/actual`, { headers: headers })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  // actual2(): Promise<User> {
  //   let headers = new HttpHeaders();
  //   headers = headers.append(
  //     "Authorization",
  //     "Bearer " + localStorage.getItem("token")
  //   );
  //   return new Promise((resolve, reject) => {
  //     this.http
  //       .get(`${environment.apiUrl}/usuarios/actual`, { headers: headers })
  //       .subscribe({
  //         next: (data: User) => resolve(data),
  //         error: (err) => reject(err),
  //       });
  //   });
  // }
}
