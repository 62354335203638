import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-boms-items',
  templateUrl: './boms-items.component.html',
  styleUrls: ['./boms-items.component.css']
})
export class BomsItemsComponent implements OnInit{

  spinner = true;

  constructor(private messageService:MessageService){

  }

  ngOnInit(): void {
    this.spinner = false;
  }

}
