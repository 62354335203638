import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Deposit } from 'src/app/shared/models/deposit/deposit.class';
import { ServerResponse } from 'src/app/shared/models/model-forms/server.response.interface';

@Injectable({
  providedIn: 'root'
})
export class DepositService {

  deposits:Deposit[] = [
    {
      id : "1",
      nombre : "Mitre 1",
      available : "800"
    },
    {
      id : "2",
      nombre : "Mitre 2",
      available : "1000"
    },
    {
      id : "3",
      nombre : "Mitre 3",
      available : "200"
    },
    {
      id : "4",
      nombre : "Pendiente revisado",
      available : "700"
    }
  ]

  serverResponse: ServerResponse<Deposit> = {
    currentPage: 1,
    list: this.deposits,
    totalElements: this.deposits.length,
    totalPages: 10,
  };


  constructor(private http:HttpClient) { }

  getDeposits(): Observable<ServerResponse<Deposit>> {
    let headers = new HttpHeaders();

    // headers = headers.append(
    //   'Authorization',
    //   "Bearer" + localStorage.getItem('token')
    // );

    // return this.httpClient.get(`${environment.apiUrl}/order`, {headers : headers})
    // .pipe (
    //   map((res:any)=>{
    //     return res
    //   })
    // )
    return of<ServerResponse<Deposit>>(this.serverResponse);
  }

}
