import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FabricsRoutingModule } from './fabrics-routing.module';
import { ListFabricComponent } from './pages/list-fabric/list-fabric.component';
import { CreateFabricComponent } from './pages/create-fabric/create-fabric.component';
import { UpdateFabricComponent } from './pages/update-fabric/update-fabric.component';
import { FormFabricComponent } from './components/form-fabric/form-fabric.component';
import { TableFabricListComponent } from './components/table-fabric-list/table-fabric-list.component';
import { PublicModule } from 'src/app/public/public.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { DetailsFabricComponent } from './pages/details-fabric/details-fabric.component';


@NgModule({
  declarations: [
    ListFabricComponent,
    CreateFabricComponent,
    UpdateFabricComponent,
    FormFabricComponent,
    TableFabricListComponent,
    DetailsFabricComponent
  ],
  imports: [
    CommonModule,
    FabricsRoutingModule,
    PublicModule,
    SharedModule
  ],
  exports : [
    ListFabricComponent,
    CreateFabricComponent,
    UpdateFabricComponent,
    FormFabricComponent,
    TableFabricListComponent,
    DetailsFabricComponent
  ]
})
export class FabricsModule { }
