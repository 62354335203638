import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './public/home/home.component';
import { canActivateAuth } from './modules/auth/guards/auth.guard';

const routes: Routes = [
  {
    path: 'home',
    pathMatch: 'full',
    component: HomeComponent,
    canActivate: [canActivateAuth],
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'order',
    loadChildren: () =>
      import('./modules/order/order.module').then((m) => m.OrderModule),
    canActivate: [canActivateAuth],
  },
  {
    path: 'production',
    loadChildren: () =>
      import('./modules/production/production.module').then(
        (m) => m.ProductionModule
      ),
    canActivate: [canActivateAuth],
  },
  {
    path: 'workplace',
    loadChildren: () =>
      import('./modules/workplace/workplace.module').then(
        (m) => m.WorkplaceModule
      ),
    canActivate: [canActivateAuth],
  },
  {
    path: 'reports',
    loadChildren: () =>
      import('./modules/reports/reports.module').then(
        (m) => m.ReportsModule
      ),
    canActivate: [canActivateAuth],
  },
  {
    path: 'users',
    loadChildren: () =>
      import('./modules/users/users.module').then(
        (m) => m.UsersModule
      ),
    canActivate: [canActivateAuth],
  },
  {
    path: 'boms',
    loadChildren: () =>
      import('./modules/boms/boms.module').then(
        (m) => m.BomsModule
      ),
    canActivate: [canActivateAuth],
  },
  {
    path: 'archives',
    loadChildren: () =>
      import('./modules/archives/archives.module').then(
        (m) => m.ArchivesModule
      ),
    canActivate: [canActivateAuth],
  },
  // {
  //   path: 'rolls',
  //   loadChildren: () =>
  //     import('./modules/rolls/rolls.module').then(
  //       (m) => m.RollsModule
  //     ),
  //   canActivate: [canActivateAuth],
  // },
  {
    path: '**',
    redirectTo: 'home',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
