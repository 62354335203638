import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ServerResponse } from 'src/app/shared/models/model-forms/server.response.interface';
import { environment } from 'src/environments/environment.development';

@Injectable({
  providedIn: 'root'
})
export class FabricService {

  constructor(private http:HttpClient) { }

  getListFabrics(page:any,size:any):Observable<ServerResponse<any>>{
    let headers = new HttpHeaders()

    headers = headers.append(
      'Authorization',
      "Bearer " + localStorage.getItem('token')
    );  

    return this.http.get(`${environment.apiUrl}/tela/lista/${page}/${size}`, {headers : headers})
    .pipe (
      map((res:any)=>{
        return res
      })
    )
  }


  getListFabricsWithOutPagination():Observable<ServerResponse<any>>{
    let headers = new HttpHeaders()

    headers = headers.append(
      'Authorization',
      "Bearer " + localStorage.getItem('token')
    );  

    return this.http.get(`${environment.apiUrl}/tela/lista`, {headers : headers})
    .pipe (
      map((res:any)=>{
        return res
      })
    )
  }

  createFabric(fabric:any):Observable<ServerResponse<any>>{
    let headers = new HttpHeaders()

    headers = headers.append(
      'Authorization',
      "Bearer " + localStorage.getItem('token')
    );  

    return this.http.post(`${environment.apiUrl}/tela/nuevo`,fabric, {headers : headers})
    .pipe (
      map((res:any)=>{
        return res
      })
    )
  }

  updateFabric(tela:any):Observable<ServerResponse<any>>{
    let headers = new HttpHeaders()

    headers = headers.append(
      'Authorization',
      "Bearer " + localStorage.getItem('token')
    );  

    return this.http.post(`${environment.apiUrl}/tela/guardar`,tela, {headers : headers})
    .pipe (
      map((res:any)=>{
        return res
      })
    )
  }
}
