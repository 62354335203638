import { Component, Input } from '@angular/core';
import { MessageService } from 'primeng/api';
import { FabricService } from 'src/app/api/services/fabric/fabric.service';

@Component({
  selector: 'app-list-fabric',
  templateUrl: './list-fabric.component.html',
  styleUrls: ['./list-fabric.component.css']
})
export class ListFabricComponent {

  fabrics: any = [];

  size = 10;
  page = 1;
  totalElements = 0;

  spinner = true;

  @Input()
  isAbm:boolean = true;

  @Input()
  urlBack = "/boms";

  @Input()
  title:string = "Telas"

  constructor(private messageService: MessageService, private fabricService: FabricService) {

  }

  ngOnInit(): void {
    this.getFabrics(true);
  }

  getFabrics(refresh = false) {
    this.spinner = refresh;
    if (refresh) {
      this.fabricService.getListFabricsWithOutPagination().subscribe({
        next: (data) => {
          this.totalElements = data.totalElements;
          this.fabrics = data.list;
        }, error: (err) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: err.error ? err.error.message : 'Ups! Ocurrio un error',
          });
        }, complete: () => {
          this.spinner = false;
        }
      })
    }
  }

}
