import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import {
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { DepositService } from 'src/app/api/services/deposit/deposit.service';
import { OrderService } from 'src/app/api/services/order/order.service';
import { Order } from 'src/app/shared/models/order/order.class';

@Component({
  selector: 'app-assign-order-modal',
  templateUrl: './assign-order-modal.component.html',
  styleUrls: ['./assign-order-modal.component.css'],
})
export class AssignOrderModalComponent implements OnInit, OnDestroy {
  @Input()
  order: Order;

  spinner = true;

  depositsOriginal: any = [];
  depositsCopyOrder: any = [];

  ref: DynamicDialogRef | undefined;

  form: FormGroup;

  @Output()
  eventHiddenModal: EventEmitter<boolean> = new EventEmitter();

  stockPorTipoSelect: any = [];

  constructor(
    private messageService: MessageService,
    public dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
    private depositService: DepositService,
    private orderService: OrderService
  ) {
    this.spinner = true;
    this.depositsCopyOrder = [];
  }

  ngOnInit(): void {
    this.order = this.dialogConfig.data.order;
    // this.stockPorTipo(this.order);
    this.getDeposits();
  }

  ngOnDestroy(): void {
    this.spinner = true;
    this.depositsOriginal = [];
    this.depositsCopyOrder = [];
  }

  startForm() {
    this.form = new FormGroup({
      deposito: new FormControl('', [Validators.required]),
    });
  }

  getDeposits() {
    this.spinner = false;
    this.depositsOriginal = this.order.stock;
    
    this.getDepositsAvailable();
    this.startForm();
  }

  async getDepositsAvailable() {
    if (this.order) {
      this.depositsOriginal.forEach((element) => {
        this.depositsCopyOrder.push(element);
      });
    }
  }

  // stockPorTipo(order:Order){
  //   order.stockPorTipo.forEach(element => {
  //     if(this.stockPorTipoSelect.indexOf((element.tipo) == -1)){
  //         this.stockPorTipoSelect.push({label : element.tipo, value : element.tipo})
  //     }
  //   });
  // }

  sendForm() {
    let depositId = this.form.get('deposito').value.id;
    let articileItemId = this.order.id;

    this.orderService.assignOrder(articileItemId, depositId).subscribe({
      next: (data) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Guardado',
        });
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: err.error ? err.error.message : 'Ups! Ocurrio un error',
        });
      },
      complete: () => {
        this.dialogRef.close({ success: true, order: this.order });
      },
    });
  }

  returnNumber(string) {
    return parseInt(string).toFixed(2);
  }
}
