<app-banner [sizeTitleClass]="'h3'" [title]="'Detalles ' + process?.nombre" [backgroundName]="'/production/weaving/tejeduria-banner.png'"
    [fontSize]="2" [routerBack]="'/boms/processes/'"></app-banner>

<div class="card text-center" *ngIf="spinner">
    <span>
      <p-progressSpinner></p-progressSpinner>
    </span>
    <br />
    <span class="text-center">Cargando...</span>
  </div>
  
  <div>
    <div class="row text-center">
      <div class="col">
        <h3 class="text-secondary">Detalles</h3>
      </div>
    </div>
    <div class="row text-center mt-3">
      <div class="col">
        <p class="text-dark fw-bold">Nombre: <span>{{ process.nombre }}</span></p>
      </div>
    </div>
  </div>