import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmEventType, ConfirmationService, MessageService } from 'primeng/api';
import { FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProductionWarpedAssignMpModalComponent } from '../../modals/production-warped-assign-mp-modal/production-warped-assign-mp-modal.component';
import { WarpedService } from 'src/app/api/services/production/warped/warped.service';

@Component({
  selector: 'app-table-production-warped-allocate-raw-material',
  templateUrl: './table-production-warped-allocate-raw-material.component.html',
  styleUrls: ['./table-production-warped-allocate-raw-material.component.css']
})
export class TableProductionWarpedAllocateRawMaterialComponent implements OnInit {

  @Input()
  articulos: any[] = [];

  @Input()
  size = 10;

  ref: DynamicDialogRef | undefined;

  form: FormGroup


  constructor(private messageService: MessageService, private router: Router, private dialogService: DialogService, private confirmationService: ConfirmationService
    , private warpedService: WarpedService) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      urdidoresArray: new FormArray([]),
    });

    this.articulos.forEach((element: any) => {
      this.addItemUrdidor(element);
    });

  }

  createItemUrdidorRequired(articulo: any) {
    console.log(articulo);
    
    return new FormGroup({
      id: new FormControl(articulo.id, []),
      objectComplete: new FormControl(articulo, []),
      articulo: new FormControl(articulo.articulo.nombre, []),
      urdidor: new FormControl(articulo.urdidor.nombre, []),
      cantidadCadena: new FormControl(articulo.cantidadCadena, []),
      cantidadHilado: new FormControl(articulo.cantidadHilado, [
        Validators.required
      ]),
      claveAsignada: new FormControl('', [
      ]),
      cantidadDeCargas: new FormControl(this.loadWeightCalculator2(articulo.articulo.pesoPorBob * (articulo.urdidor.alimentadores || 1),articulo.cantidadHilado), [
      ]),
      pesoPorCarga: new FormControl(articulo.articulo.pesoPorBob * (articulo.urdidor.alimentadores || 1), [
      ]),
      cargas: new FormControl(articulo.articulo.pesoPorBob * (articulo.urdidor.alimentadores || 1), [
      ]),
    });
  }

  

  changeCantidadCargas(articulo){

    console.log(articulo);
    let cantidadCargas = this.loadWeightCalculator2(articulo.get('pesoPorCarga').value, articulo.get('cantidadHilado').value);
    console.log(cantidadCargas);
    
    articulo.get('cantidadDeCargas').setValue(cantidadCargas);
    
  }

  get getUrdidoresArray(): FormArray {
    return this.form.get('urdidoresArray') as FormArray;
  }

  addItemUrdidor(item: any) {
    this.getUrdidoresArray.push(this.createItemUrdidorRequired(item));
  }

  deleteItemUrdidor(index: any) {
    this.getUrdidoresArray.removeAt(index);
  }

  changeUrdidor(event: any, index: any) {
    this.articulos[index].urdidorSelect = event.value;
  }

  assignMp(articulo: any, index: any) {


    this.ref = this.dialogService.open(ProductionWarpedAssignMpModalComponent, {
      header: '',
      width: '75%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      styleClass: 'modal-assign-order',
      data: {
        articulo,
        formItem: this.getUrdidoresArray.controls[index],
        index
      },
      maskStyleClass: ""
    });
    this.ref.onClose.subscribe((data: any) => {
      if (data && data.success) {
        this.getUrdidoresArray.controls[data.index].get('claveAsignada').setValue(data.clave)
      }
    });
  }

  loadWeightCalculator(form,index){
    let cargas = 0;
    const hilado = form.value.cantidadHilado
    const pesoPorCarga = form.value.pesoPorCarga;

    while ((pesoPorCarga * cargas) < hilado) {
      cargas++
    }

    return cargas;
  }

  loadWeightCalculator2(pesoPorCarga,cantidaHilado){
    let cargas = 0;

    while ((pesoPorCarga * cargas) < cantidaHilado) {
      cargas++
    }

    return cargas;
  }

  navigateWorkPlaceUrdido() {
    let faltantes = this.getUrdidoresArray.controls.filter((element) => { return element.get('claveAsignada').value == "" });

    if (faltantes.length > 0) {
      this.confirmationService.confirm({
        message: "Queda materia prima dispónible por asignar antes de programar máquinas ¿Desea continuar?",
        header: "Confirmar acción",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Aceptar",
        rejectLabel: "Cancelar",
        acceptButtonStyleClass: "p-button button-confirm-programmer-machine-weaving p-button-rounded",
        rejectButtonStyleClass: "p-button button-reject-programmer-machine-weaving p-button-rounded",
        accept: () => {
          this.assignUrdido();
        },
        reject: (type) => {
          switch (type) {
            case ConfirmEventType.REJECT:
              this.messageService.add({
                severity: "warn",
                summary: "Cancelado",
                detail: "Usted ha cancelado",
              });
              break;
            case ConfirmEventType.CANCEL:
              this.messageService.add({
                severity: "warn",
                summary: "Cancelado",
                detail: "Usted ha cancelado",
              });
              break;
          }
        },
      });
    } else {
      this.assignUrdido();
    }
  }

  assignUrdido() {
    let selects = this.getUrdidoresArray.controls.filter((element) => { return element.get('claveAsignada').value != "" });

    let asignacionMaquinas = [];

    selects.forEach((element) => {
      asignacionMaquinas.push({
        cantidadCadena: element.get('cantidadCadena').value,
        cantidadHilado: element.get('cantidadHilado').value,
        hiladoList: element.get('claveAsignada').value.map((element) => {
          return element.clave.map((clave)=>{
            return clave.id
          }).flat(Infinity);
        }).flat(Infinity),
        hiladoUrdidoId: element.get('objectComplete').value.articulo.id,
        maquinaId: element.get('objectComplete').value.urdidor.id,
      })
    })

    this.warpedService.asignarMaquinasAsignarUrdido(asignacionMaquinas).subscribe({
      next: (data) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Programado',
        });
      }, error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: err.error ? err.error.message : 'Ups! Ocurrio un error',
        });
      },
      complete: () => {
        this.router.navigate(['/workplace/warped'], {
          state: {
            articulos: this.articulos,
          },
        });
      }
    })

  }

}
