import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-table-fabric-list',
  templateUrl: './table-fabric-list.component.html',
  styleUrls: ['./table-fabric-list.component.css']
})
export class TableFabricListComponent {

  @Input()
  fabrics: any[] = [];

  @Input()
  paginate = 1;

  @Input()
  totalRecords = 0;

  @Input()
  size = 10;

  @Output()
  eventEmitterTable: EventEmitter<any> = new EventEmitter();

  ref: DynamicDialogRef | undefined;

  visibleAssignOrder: boolean = false;

  @Input()
  isAbm:boolean = true;

  constructor(private dialogService:DialogService,private router:Router){}

  detailsFabric(fabric:any){
    this.router.navigate(['/boms/fabrics/detail-fabric'],{
      state : {fabric}
    })
  }

  createFabric(){
    this.router.navigate(['/boms/fabrics/create-fabric'])
  }
  updateFabric(fabric:any){
    this.router.navigate(['/boms/fabrics/update-fabric'],{
      state : {fabric}
    })
  }

  seeRolls(fabric:any){
    this.router.navigate(['/rolls/list-rolls'],{
      state : {fabric}
    })
  }

}
