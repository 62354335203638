import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { SortEvent } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-table-machine-list',
  templateUrl: './table-machine-list.component.html',
  styleUrls: ['./table-machine-list.component.css']
})
export class TableMachineListComponent implements OnInit{

  @Input()
  machines: any[] = [];

  @Input()
  paginate = 1;

  @Input()
  totalRecords = 0;

  @Input()
  size = 10;

  @Output()
  eventEmitterTable: EventEmitter<any> = new EventEmitter();

  ref: DynamicDialogRef | undefined;

  visibleAssignOrder: boolean = false;

  constructor(private dialogService:DialogService,private router:Router){}


  ngOnInit(): void {
    
  }

  detailsMachine(machine:any){
    this.router.navigate(['/boms/machines/detail-machine'],{
      state : {machine}
    })
  }


  createMachine(){
    this.router.navigate(['/boms/machines/create-machine'])
  }
  updateMachine(machine:any){
    this.router.navigate(['/boms/machines/update-machine'],{
      state : {machine}
    })
  }

  listLocksMachines(machine:any){
    this.router.navigate(['/boms/machines/lock/list-machine-lock'],{
      state : {machine}
    })
  }

  customSort(event: SortEvent) {

    event.data.sort((data1, data2) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) result = -1;
      else if (value1 != null && value2 == null) result = 1;
      else if (value1 == null && value2 == null) result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

      return event.order * result;
    });
  }

}
