<div class="card text-center" *ngIf="spinner">
  <span>
    <p-progressSpinner></p-progressSpinner>
  </span>
  <br />
  <span class="text-center">Cargando...</span>
</div>

<div class="container" *ngIf="!spinner">
    <div class="row">
      <div class="col-12">
        <h1 class="title text-center h3">Agregar orden</h1>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <form [formGroup]="form" (ngSubmit)="addOrderSubmit()">
          <div class="row mt-3">
            <div class="col-12 col-md-6">
              <label for="genero" class="">Genero: </label><br />
              <p-dropdown [filter]="true" class="" [options]="telas" [style]="{ width: '100%', border: '2px solid #F6CACA' }"
                placeholder="- Genero -" formControlName="genero"  optionLabel="tela.nombre" appendTo="body">
                <ng-template let-option pTemplate="item">
                  <span [class]="'genero-' + option.id">{{ option.tela.nombre }}</span>
                </ng-template>
              </p-dropdown>
              <span class="danger" *ngIf="
                  form.get('genero').hasError('required') &&
                  form.get('genero').touched
                ">
                <small class="p-error">Este campo es <strong>requerido</strong></small>
              </span>
            </div>
            <div class="col-12 col-md-6">
              <label for="article" class="">Articulo: </label><br />
              <p-dropdown [filter]="true" class="" [options]="articles" [style]="{ width: '100%', border: '2px solid #F6CACA' }"
                placeholder="- Articulo -" formControlName="article" optionLabel="nombre" appendTo="body">
                <ng-template let-option pTemplate="item">
                  <span [class]="'article-' + option.id">{{
                    option.nombre
                    }}</span>
                </ng-template>
              </p-dropdown>
              <span class="danger" *ngIf="
                  form.get('article').hasError('required') &&
                  form.get('article').touched
                ">
                <small class="p-error">Este campo es <strong>requerido</strong></small>
              </span>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12 col-md-6">
              <label for="quantity" class="">Cantidad (Mts): </label><br />
              <input min="1" type="number" formControlName="quantity" pInputText
                [style]="{ width: '100%',border: '2px solid #F6CACA' }" />
              <br />
              <span class="danger" *ngIf="
                  form.get('quantity').hasError('required') &&
                  form.get('quantity').touched
                ">
                <small class="p-error">Este campo es <strong>requerido</strong></small>
              </span>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12 text-end">
              <p-button type="submit" styleClass="p-button-rounded p-button-sm p-button-secondary save-order-warped"
                label="Guardar" [disabled]="form.invalid"></p-button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>