import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FabricService } from 'src/app/api/services/fabric/fabric.service';

@Component({
  selector: 'app-create-fabric',
  templateUrl: './create-fabric.component.html',
  styleUrls: ['./create-fabric.component.css']
})
export class CreateFabricComponent {

  spinner = true;

  fabric: any = {}

  constructor(
    public messageService: MessageService,
    public dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
    private fabricService: FabricService,
    private router:Router
  ) { }

  ngOnInit(): void {
    this.fabric = this.dialogConfig?.data?.user;
    this.spinner = false;
  }

  createFabric(fabric: any) {
    this.fabricService.createFabric(fabric).subscribe({
      next: (data) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Tela creada!',
        });
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: err.error ? err.error.message : 'Ups! Ocurrio un error',
        });
      },
      complete: () => {
        this.router.navigate(['/boms/fabrics'])
      }
    })
  }

}
