import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { OrderService } from 'src/app/api/services/order/order.service';

@Component({
  selector: 'app-form-process',
  templateUrl: './form-process.component.html',
  styleUrls: ['./form-process.component.css']
})
export class FormProcessComponent {

  spinner = true;

  @Input()
  process: any = {};

  form: FormGroup;

  @Output()
  eventUser: EventEmitter<any> = new EventEmitter();

  title:any = "Crear proceso"

  constructor(
    public messageService: MessageService,
    public dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
    private orderService: OrderService,
    private datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    if(this.process){
      this.title = "Actualizar proceso"
    }

    this.startForm();
    this.spinner = false;
  }

  ngOnDestroy(): void {
    this.spinner = true;
  }

  startForm() {
    this.form = new FormGroup({
      id: new FormControl(this.process?.id || '', []),
      nombre: new FormControl(this.process?.nombre || "", [Validators.required]),
      // alimentadores: new FormControl("", []),
      // capacidad: new FormControl("", []),
      // kilosPorBanio: new FormControl("", []),
      // baniosPorDia: new FormControl("", []),
      // composicion: new FormControl("", []),
      // email: new FormControl(this.user?.email || "", [Validators.required]),
    });
  }

  sendForm() {
    let maquina =
    {
      id: this.form.get('id').value,
      nombre: this.form.get('nombre').value,
      // email: this.form.get('email').value,
    }

    this.eventUser.emit(maquina)
    
  }

}
