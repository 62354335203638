import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-see-details-production-plano',
  templateUrl: './see-details-production-plano.component.html',
  styleUrls: ['./see-details-production-plano.component.css']
})
export class SeeDetailsProductionPlanoComponent {

  tela:any;

  spinner = true;

  form:FormGroup;

  depositos:any = [
    {id : 1, nombre : "Estrada"},
    {id : 2, nombre : "Roca"}
  ]

  formItemReceived:FormGroup
  
  constructor(
    public dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
    private messageService:MessageService
    ){

  }

  ngOnInit(): void {
    this.tela = this.dialogConfig.data.tela;
    console.log(this.tela);
    
    this.formItemReceived = this.dialogConfig.data.formItem;
    this.spinner = false;
    this.startForm();
    
  }

  startForm() {
    this.form = new FormGroup({
      deposito: new FormControl('', [Validators.required]),
    });
  }

  sendForm(){

  }

}
