import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-chart-doughnut',
  templateUrl: './chart-doughnut.component.html',
  styleUrls: ['./chart-doughnut.component.css'],
})
export class ChartDoughnutComponent implements OnInit {


  @Input()
  data: any;

  @Input()
  height = '45vh'

  @Input()
  type = "pie"

  @Input()
  legend:boolean = true;

  @Input()
  direction = "x"

  options: any;

  ngOnInit() {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');

    this.options = {
      cutout: this.type == 'doughnut' ? '60%' : null,
      plugins: {
        legend: {
          labels: {
            color: textColor,
          },
        },
      },
    };
  }
}
