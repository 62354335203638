<!-- <div class="card text-center" *ngIf="spinner">
  <span>
    <p-progressSpinner></p-progressSpinner>
  </span>
  <br />
  <span class="text-center">Cargando...</span>
</div>

<div *ngIf="articulo && !spinner">
  <div class="row text-center">
    <div class="col">
      <h3 class="text-secondary">Asignar materia prima</h3>
    </div>
  </div>
  <div class="row text-center mt-3">
    <div class="col">
      <p class="text-dark">CAD POLIESTER 150/48</p>
      <p class="text-dark">{{articulo.urdidorSelect.nombre}}</p>
      <p class="text-dark">Carga: {{this.formItemReceived.get('cantidadHilado').value}} Kgs</p>
      <p class="text-dark">Cantidad: {{ articulo.cantidad }}</p>
    </div>
  </div> -->
<!-- <div class="row text-center mt-2">
    <div class="col">
      <h5 class="text-dark">Stock existente</h5>
    </div>
  </div> -->
<div class="card text-center" *ngIf="spinner">
  <span>
    <p-progressSpinner></p-progressSpinner>
  </span>
  <br />
  <span class="text-center">Cargando...</span>
</div>

<div class="row text-center">
  <div class="col">
    <h3 class="text-secondary">Stock de hilado</h3>
  </div>
</div>
<div class="row text-center mt-3">
  <div class="col">
    <p class="text-dark "><span> {{articulo.controls.objectComplete.value.articulo.nombre}}</span> </p>
  </div>
  <div class="col">
    <p class="text-dark ">Cantidad: <span> {{articulo.controls.objectComplete.value.cantidadCadena.toFixed(2)}} Kgs</span> </p>
  </div>
  <div class="col">
    <p class="text-dark "><span> {{articulo.controls.objectComplete.value.urdidor.nombre}}</span> </p>
  </div>
  <div class="col">
    <p class="text-dark "><span> Cantidad de cargas = {{articulo.controls.cantidadDeCargas.value}} ({{articulo.controls.pesoPorCarga.value}} Kgs C/U)</span> </p>
  </div>
</div>
<div class="row">
  <div class="col text-center">
    <p class="text-dark fw-bold">Stock existente</p>
  </div>
</div>

<form [formGroup]="form" (ngSubmit)="sendForm()">
  <div class="row justify-content-center text-center" *ngIf="articulo.controls.objectComplete.value.hiladoList.length > 0;else sinClaves">
    <div class="col-12 col-md-4 mx-md-3" *ngFor="let hilado of hilados">
      <div *ngIf="hilado.claves.length > 0;else sinClaveInterna">
        <div class="row">
          <div class="col">
            <p-multiSelect tooltipPosition="top" [options]="hilado.claves" (onChange)="calculateIsValidQantity($event,hilado)" appendTo="body"
              [style]="{ width: '100%' }" [formControlName]="hilado.composicion" id="claves"
              placeholder="Seleccione una opción..." selectedItemsLabel="{0} Seleccionados" [maxSelectedLabels]="0">
              <ng-template let-option pTemplate="item">
                <span [class]="'composition-' + option.clave">
                  {{option.cantidad}} Kgs {{ option.clave || 'Sin clave'}}
                </span>
              </ng-template>
            </p-multiSelect>
            <div class="mt-3">
              <span class="text-danger " *ngIf="
              form.get(hilado.composicion).hasError('min') &&
              form.get(hilado.composicion).touched">
                La cantidad elegida es menor a la demandada
              </span>
              <span class="danger" *ngIf="
              form.get(hilado.composicion).hasError('required') &&
              form.get(hilado.composicion).touched
            ">
                <small class="p-error">Este campo es <strong>requerido</strong></small>
              </span>
            </div>
          </div>
          <!-- <p-scrollPanel [style]="{ width: '100%', height: '150px' }">
            <div *ngFor="let clave of hilado.claves" class="field-checkbox mt-2 mx-2">
              <p-radioButton (onClick)="calculateIsValidQantity($event,hilado)" [value]="clave"
                [inputId]="hilado.composicion + clave.id" [formControlName]="hilado.composicion"></p-radioButton>
              <label [for]="clave.id" class="ml-2"><span class="fw-bold">{{ clave.cantidad }} Kgs
                  {{clave.clave || 'Sin clave'}}</span></label>
            </div>
            <div class="mt-3">
              <span class="text-warning " *ngIf="
              form.get(hilado.composicion).hasError('min') &&
              form.get(hilado.composicion).touched">
                La cantidad de {{hilado.composicion}} elegida es menor a la demandada
              </span>
              <span class="danger" *ngIf="
              form.get(hilado.composicion).hasError('required') &&
              form.get(hilado.composicion).touched
            ">
                <small class="p-error">Este campo es <strong>requerido</strong></small>
              </span>
            </div>

          </p-scrollPanel> -->
        </div>
      </div>
      <ng-template #sinClaveInterna>
        <p-messages class="w-100" [value]="[{ severity: 'info', detail: 'No hay claves' }]" [enableService]="false"
          [closable]="false"></p-messages>
      </ng-template>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col text-end">
      <p-button label="Guardar" styleClass="p-button p-button-secondary p-button-rounded assing-deposit-button"
        type="submit" [disabled]="form.invalid">
      </p-button>
    </div>
  </div>
</form>
<ng-template #sinClaves>
  <p-messages class="w-100" [value]="[{ severity: 'info', detail: 'No hay hilados' }]" [enableService]="false"
    [closable]="false"></p-messages>
</ng-template>