import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-production-items',
  templateUrl: './production-items.component.html',
  styleUrls: ['./production-items.component.css']
})
export class ProductionItemsComponent implements OnInit{


  spinner = true;

  constructor(private messageService:MessageService){

  }

  ngOnInit(): void {
    this.spinner = false;
  }
}
