import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { WeavingService } from 'src/app/api/services/production/weaving/weaving.service';

@Component({
  selector: 'app-production-weaving-planning-order',
  templateUrl: './production-weaving-planning-order.component.html',
  styleUrls: ['./production-weaving-planning-order.component.css']
})
export class ProductionWeavingPlanningOrderComponent implements OnInit {

  circular: any = []
  plano: any = []
  ketten: any = []

  telas: any = []

  spinner = true;

  constructor(private messageService: MessageService, private weavingService: WeavingService) {

  }

  ngOnInit(): void {
    this.getTelas(history.state.object)
  }

  getTelas(telas: any) {
    this.weavingService.asignacionWeaving(telas).subscribe({
      next: (data) => {
        telas = data
        telas.list.forEach(element => {
          switch (element.tela.linea) {
            case "CIRCULAR":
              this.circular.push(element)
              break;
            case "PLANO":
              this.plano.push(element)
              break;
            case "KETTEN":
              this.ketten.push(element)
              break;
            default:
              break;
          }
        });
      }, error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: err.error ? err.error.message : 'Ups! Ocurrio un error',
        });
      },
      complete: () => {
        this.spinner = false;
      }
    })
  }

}
