<app-banner
  [sizeTitleClass]="'h6'"
  [title]="'Asignar tejeduria/máquina/hilado'"
  [backgroundName]="''"
  [fontSize]="1"
  [routerBack]="'/production/weaving'"
></app-banner>

<div class="container-fluid mt-1" *ngIf="!spinner">
  <!-- <div class="row mt-3">
      <div class="col-11">
        <app-filters [filters]="filters"></app-filters>
      </div>
      <div class="col-auto">
        <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success mr-2" pTooltip="Descargar Excel" tooltipPosition="bottom"></button>
      </div>
    </div> -->
  <div class="row m-0 p-0 mt-1">
    <div class="col m-0 p-0">
    <p-tabView class="tab-weaving" [activeIndex]="0" [style]="{margin : '0',padding : '0'}">
      <p-tabPanel header="Circular">
        <app-table-production-weaving-circular [telas]="circular">
        </app-table-production-weaving-circular>
      </p-tabPanel>
      <p-tabPanel header="Plano">
        <app-table-production-weaving-plano [telas]="plano">
        </app-table-production-weaving-plano>
      </p-tabPanel>
      <p-tabPanel header="Ketten">
          <app-table-production-weaving-ketten  [telas]="ketten">
          </app-table-production-weaving-ketten>
      </p-tabPanel>
    </p-tabView>
  </div>
  </div>
</div>
<p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>

<div class="card text-center" *ngIf="spinner">
  <span>
    <p-progressSpinner></p-progressSpinner>
  </span>
  <br />
  <span class="text-center">Cargando...</span>
</div>
