<div class="card text-center" *ngIf="spinner">
  <span>
    <p-progressSpinner></p-progressSpinner>
  </span>
  <br />
  <span class="text-center">Cargando...</span>
</div>

<div *ngIf="!spinner">
  <div class="row text-center">
    <div class="col">
      <h3 class="text-secondary">Stock de cadena/trama</h3>
    </div>
  </div>
  <div class="row text-center mt-3">
    <div class="col">
      <p class="text-dark fw-bold"><span>{{tela.controls.nombre.value}}</span> </p>
      <p class="text-dark fw-bold"><span>{{tela.controls.totalKgs.value.toFixed(2) || 0}}Mts
       </span> </p>
      <!-- <p class="text-dark fw-bold">Tela: <span>{{formItemReceived.get('tejedor').value.nombre}} - {{formItemReceived.get('maquina').value.nombre}}</span> </p> -->
      <!-- <p class="text-dark fw-bold">Tela: <span>{{tela.nombre}}</span> </p> -->
      <!-- <p class="text-dark fw-bold">Alimentadores : <span>{{formItemReceived.get('maquina').value.bobinas}}</span> </p>
      <p class="text-dark fw-bold">Cargas necesarias: <span>{{cantidadCargas}}
          ({{formItemReceived.get('cargaPorMaquina').value}} Kgs)</span> </p>
      <p class="text-dark fw-bold">Tela: <span>{{tela.nombre}} {{tela.id}}</span> </p>
      <p class="text-dark fw-bold">Cantidad: <span>{{formItemReceived.get('totalKgs').value || 0}}</span> </p> -->
      <!-- <p class="text-dark fw-bold"><span>{{formItemReceived.get('totalKgs').value || 0}} Kgs</span> </p>  -->
    </div>
  </div>
  <div class="row text-center mt-3">
    <div class="col-6" *ngFor="let composicion of tela?.controls?.composiciones?.value">
      <p class="text-dark fw-bold">{{composicion.composicion}} - {{composicion.hilado.nombre}}:</p>
      <p>

        <span *ngIf="!composicion.hilado.nombre.includes('CAD')" [ngClass]="{
          'text-success' : composicion.hilado.available ,
          'text-danger' : !composicion.hilado.available,
        }">
        {{(getNumber("float",tela.controls.totalKgs.value,composicion) * composicion?.hilado?.consumoXMetro).toFixed(2)}} Kgs
          <!-- {{obtenerCantidadPorMedida(composicion,tela.controls['objectComplete'].value.tela.unidadDeMedida)}}Kgs
          ({{composicion.hilado.porcentaje}} %) - {{composicion?.hilado?.cantidadMetros?.toFixed(2)}}Mts -->
        </span>
        <span *ngIf="composicion.hilado.nombre.includes('CAD')" [ngClass]="{
          'text-success' : composicion.hilado.available ,
          'text-danger' : !composicion.hilado.available,
        }">
        {{(getNumber("float",tela.controls.totalKgs.value,composicion) * composicion?.hilado?.consumoXMetro).toFixed(2)}} Mts
          <!-- {{composicion?.hilado?.cantidadMetros?.toFixed(2)}}Mts ({{composicion.hilado.porcentaje}} %) -
          {{obtenerCantidadPorMedida(composicion,tela.controls['objectComplete'].value.tela.unidadDeMedida)}}Kgs -->
        </span>
      </p> 
    </div>
  </div>

  <form [formGroup]="form" (ngSubmit)="sendForm()">
    <div class="row text-center" *ngIf="(this.tela.controls['objectComplete'].value.hilado1List.length > 0 
      || this.tela.controls['objectComplete'].value.hilado2List.length > 0 
      || this.tela.controls['objectComplete'].value.hilado3List.length > 0);else sinHilado">
      <div class="col offset-md-1" *ngFor="let hilado of hilados;let i = index">
        <!-- <label [for]="hilado.composicion" *ngIf="i == 0" class="text-dark fw-bold">{{'Cadena'}} </label> -->
        <!-- <label [for]="hilado.composicion" *ngIf="i == 1" class="text-dark fw-bold">{{'Trama'}} </label> -->
        <div *ngIf="hilado.claves.length > 0;else sinClaves">
          <div class="row text-start">
            <!-- <label [for]="hilado.composicion" class="text-dark">ESTRADAS HILADOS</label> -->
            <p-scrollPanel [style]="{ width: '100%', height: '150px' }">
              <div *ngFor="let clave of sortClaves(hilado.claves)" class="field-checkbox mt-2 mx-2">
                <p-radioButton (onClick)="calculateIsValidQantity($event,hilado)" [value]="clave"
                  [inputId]="hilado.composicion.hilado.id + clave.id"
                  [formControlName]="hilado.composicion.hilado.id"></p-radioButton>
                <label [for]="clave.id" class="ml-2"><span class="fw-bold">{{ clave.cantidad.toFixed(2) }} {{(clave?.um
                    | titlecase) || 'Kgs'}}
                    {{clave.clave || 'Sin clave'}} <span class="fw-bold" *ngIf="clave.deposito">-</span>
                    {{clave?.deposito}}</span></label>
              </div>
            </p-scrollPanel>
            <div class="mt-3">
              <span class="text-warning " *ngIf="
              form.get([hilado.composicion.hilado.id.toString()]).hasError('min') &&
              form.get([hilado.composicion.hilado.id.toString()]).touched">
                La cantidad elegida es menor a la demandada
              </span>
              <span class="danger" *ngIf="
              form.get([hilado.composicion.hilado.id.toString()]).hasError('required') &&
              form.get([hilado.composicion.hilado.id.toString()]).touched
            ">
                <small class="p-error">Este campo es <strong>requerido</strong></small>
              </span>
            </div>
          </div>
        </div>
        <ng-template #sinClaves>
          <p-messages class="w-100" [value]="[{ severity: 'info', detail: 'No hay claves' }]" [enableService]="false"
            [closable]="false"></p-messages>
        </ng-template>
      </div>

    </div>
    <div class="row mt-3">
      <div class="col text-end">
        <!-- <p-button (onClick)="guardarSinCadena()" label="Guardar sin cadena"
          styleClass="p-button p-button-secondary p-button-rounded button-urdido mx-3" type="button">
        </p-button> -->
        <p-button [disabled]="form.invalid" label="Guardar"
          styleClass="p-button p-button-secondary p-button-rounded assing-deposit-button mx-3" type="submit">
        </p-button>
      </div>
    </div>
  </form>
</div>

<ng-template #sinHilado>
  <p-messages class="w-100" [value]="[{ severity: 'info', detail: 'No hay hilados' }]" [enableService]="false"
    [closable]="false"></p-messages>
</ng-template>