import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmEventType, ConfirmationService, MessageService } from 'primeng/api';
import { FormControl, FormGroup, FormArray } from '@angular/forms';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AddOrderWarpedComponent } from '../../modals/add-order-warped/add-order-warped.component';
import { WarpedService } from 'src/app/api/services/production/warped/warped.service';

@Component({
  selector: 'app-table-production-warped-planning',
  templateUrl: './table-production-warped-planning.component.html',
  styleUrls: ['./table-production-warped-planning.component.css']
})
export class TableProductionWarpedPlanningComponent implements OnInit {

  @Input()
  articulos: any[] = [];

  @Input()
  size = 10;

  form: FormGroup;
  ref: DynamicDialogRef | undefined;

  constructor(private messageService: MessageService, private router: Router, private dialogService: DialogService,
    private warpedService: WarpedService, private changeDetectorRef: ChangeDetectorRef, private confirmationService: ConfirmationService
  ) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      urdidoresArray: new FormArray([]),
    });

    this.articulos.forEach((element: any) => {
      console.log(element);
      
      this.addItemUrdidor(element);
    });
  }


  loadOrderLazy(event: any) {
    //simulate remote connection with a timeout
    setTimeout(() => {
      //load data of required page
      let loadedOrders = this.articulos.slice(
        event.first,
        event.first + event.rows
      );

      //populate page of virtual cars
      Array.prototype.splice.apply(this.articulos, [
        ...[event.first, event.rows],
        ...loadedOrders,
      ]);

      //trigger change detection
      event.forceUpdate();
    }, Math.random() * this.articulos.length + this.size);
  }


  changeUrdidor(event: any, check: boolean) {

    // let form: FormGroup = this.getUrdidoresArray.controls[event.index] as FormGroup

    // if (check) {
    //   form.removeControl('urdidor')
    //   form.addControl('urdidor', new FormControl('', [Validators.required]))
    // } else {
    //   form.removeControl('urdidor')
    //   form.addControl('urdidor', new FormControl('', []))

    // }
    // this.changeDetectorRef.detectChanges();
  }

  createItemUrdidorRequired(articulo: any) {
    return new FormGroup({
      id: new FormControl(articulo?.id || "", []),
      articulo: new FormControl(articulo?.articulo, []),
      articuloId: new FormControl(articulo?.articulo?.id, []),
      cantidad: new FormControl(articulo?.cantidad, []),
      tejeduria: new FormControl(articulo?.tejeduria, []),
      tela: new FormControl(articulo?.tela || "", []),
      telaId: new FormControl(articulo?.tela?.id || "", []),
      urdidores: new FormControl(articulo?.maquinas || [], []),
      urdidor: new FormControl({ value: '', disabled: articulo.maquinas.length <= 0 }, []),
      newItem: new FormControl(articulo?.newItem ? true : false, []),
    });
  }

  get getUrdidoresArray(): FormArray {
    return this.form.get('urdidoresArray') as FormArray;
  }

  addItemUrdidor(item: any) {
    this.getUrdidoresArray.insert(0, this.createItemUrdidorRequired(item));
  }

  deleteItemUrdidor(index: any) {
    this.getUrdidoresArray.removeAt(index);
  }

  addOrderWarped() {
    this.ref = this.dialogService.open(AddOrderWarpedComponent, {
      header: '',
      width: '50%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      styleClass: 'modal-assign-order',
      data: {
      },
      maskStyleClass: ""
    });
    this.ref.onClose.subscribe((data: any) => {
      if (data && data.success) {
        this.addItemUrdidor(data.articulo);
        this.articulos.unshift(data.articulo);
      }
    });
  }

  navigateProductionOrder() {


    let faltantes = this.getUrdidoresArray.controls.filter((element) => { return !element.get('urdidor').value && !element.get('newItem').value })

    if (faltantes.length > 0) {
      this.confirmationService.confirm({
        message: "Quedan urdidores por asignar ¿Desea continuar de todas formas?",
        header: "Confirmar acción",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Aceptar",
        rejectLabel: "Cancelar",
        acceptButtonStyleClass: "p-button button-confirm-programmer-machine-weaving p-button-rounded",
        rejectButtonStyleClass: "p-button button-reject-programmer-machine-weaving p-button-rounded",
        accept: () => {
          this.asignacionUrdido();
        },
        reject: (type) => {
          switch (type) {
            case ConfirmEventType.REJECT:
              this.messageService.add({
                key: "msg",
                severity: "warn",
                summary: "Cancelado",
                detail: "Usted ha cancelado",
              });
              break;
            case ConfirmEventType.CANCEL:
              this.messageService.add({
                key: "msg",
                severity: "warn",
                summary: "Cancelado",
                detail: "Usted ha cancelado",
              });
              break;
          }
        },
      });
    } else {
      this.asignacionUrdido();
    }


  }

  asignacionUrdido() {
    let ordenesElegidas = 
    this.getUrdidoresArray.controls.filter((element) => { return (element.get('urdidor').value != "" && !element.get('newItem').value)  || (element.get('urdidor').value != "" && element.get('newItem').value)})

    let ordenes = ordenesElegidas.map((element) => {
      return {
        articuloId: element.get('articuloId').value,
        cantidad: element.get('cantidad').value,
        maquinaId: element.get('urdidor').value.id,
        telaId: element.get('telaId').value
      }
    })

    let articulos: any = []
    this.warpedService.asignacionUrdido(ordenes).subscribe({
      next: (data) => {
        articulos = data
        this.messageService.add({
          severity: 'success',
          summary: 'Asignado!',
        });
      }, error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: err.error ? err.error.message : 'Ups! Ocurrio un error',
        });
      },
      complete: () => {
        this.router.navigate(['/production/warped/allocate-raw-material'], {
          state: {
            articulos: articulos,
          },
        });
      }
    })
  }

}
