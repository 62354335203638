import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MachineRoutingModule } from './machine-routing.module';
import { ListMachineComponent } from './pages/list-machine/list-machine.component';
import { CreateMachineComponent } from './pages/create-machine/create-machine.component';
import { UpdateMachineComponent } from './pages/update-machine/update-machine.component';
import { DetailMachineComponent } from './pages/detail-machine/detail-machine.component';
import { FormMachineComponent } from './components/form-machine/form-machine.component';
import { TableMachineListComponent } from './components/table-machine-list/table-machine-list.component';
import { PublicModule } from 'src/app/public/public.module';
import { SharedModule } from 'src/app/shared/shared.module';


@NgModule({
  declarations: [
    ListMachineComponent,
    CreateMachineComponent,
    UpdateMachineComponent,
    DetailMachineComponent,
    FormMachineComponent,
    TableMachineListComponent
  ],
  imports: [
    CommonModule,
    MachineRoutingModule,
    PublicModule,
    SharedModule
  ],
  exports : [
    ListMachineComponent,
    CreateMachineComponent,
    UpdateMachineComponent,
    DetailMachineComponent,
    FormMachineComponent,
    TableMachineListComponent
  ]
})
export class MachineModule { }
