import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListProcessComponent } from './pages/list-process/list-process.component';
import { CreateProcessComponent } from './pages/create-process/create-process.component';
import { UpdateProcessComponent } from './pages/update-process/update-process.component';
import { DetailProcessComponent } from './pages/detail-process/detail-process.component';

const routes: Routes = [
  {
    path : '',
    children : [
      {path : '', component : ListProcessComponent},
      {path : 'list-process', component : ListProcessComponent},
      {path : 'create-process', component : CreateProcessComponent},
      {path : 'update-process', component : UpdateProcessComponent},
      {path : 'detail-process', component : DetailProcessComponent},
      {path : '**', redirectTo : ''}
    ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProcessRoutingModule { }
