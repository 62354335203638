import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MachineService } from 'src/app/api/services/automation/machine/machine.service';
import { FabricService } from 'src/app/api/services/fabric/fabric.service';

@Component({
  selector: 'app-update-machine',
  templateUrl: './update-machine.component.html',
  styleUrls: ['./update-machine.component.css']
})
export class UpdateMachineComponent {

  spinner = true;

  machine: any = {}

  constructor(
    public messageService: MessageService,
    public dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
    private fabricService: FabricService,
    private machineService: MachineService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.machine = history.state.machine
    this.spinner = false;
  }

  updateMachine(machine: any) {
    this.machineService.updateMachine(machine).subscribe({
      next: (data) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Máquina actualizada!',
        });
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: err.error ? err.error.message : 'Ups! Ocurrio un error',
        });
      },
      complete: () => {
        this.router.navigate(['/boms/machines'])
      }
    })
  }

}
