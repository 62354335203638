<div class="card text-center" *ngIf="spinner">
    <span>
      <p-progressSpinner></p-progressSpinner>
    </span>
    <br />
    <span class="text-center">Cargando...</span>
  </div>

  <div *ngIf="form && !spinner">
      <form [formGroup]="form" (ngSubmit)="sendForm()">
        <div class="row justify-content-center">
          <div class="col-12 col-md-4">
            <label for="nombre" class="text-dark">Nombre: </label>
            <input type="text" pInputText formControlName="nombre" [style]="{ width: '100%' }"/>
            <span
              class="danger"
              *ngIf="
                form.get('nombre').hasError('required') &&
                form.get('nombre').touched
              "
            >
              <small class="p-error"
                >Este campo es <strong>requerido</strong></small
              >
            </span>
          </div>
          <!-- <div class="col-12 col-md-4">
            <label for="nombre" class="text-dark">Composición: </label>
            <input type="text" pInputText formControlName="composicion" [style]="{ width: '100%' }"/>
            <span
              class="danger"
              *ngIf="
                form.get('composicion').hasError('required') &&
                form.get('composicion').touched
              "
            >
              <small class="p-error"
                >Este campo es <strong>requerido</strong></small
              >
            </span>
          </div> -->
        </div>
        <div class="row justify-content-center">
          <div class="col-12 col-md-4">

          </div>
        </div>
        <div class="row mt-5">
          <div class="col text-end">
            <p-button
              [label]="title"
              styleClass="p-button p-button-secondary p-button-rounded date-promise-button"
              type="submit"
              [disabled]="form.invalid"
            >
            </p-button>
          </div>
        </div>
      </form>
  </div>