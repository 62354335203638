import { AbstractControl, FormArray, ValidatorFn } from "@angular/forms";

export const totalQuantityValidator: ValidatorFn = (
    control: AbstractControl
  ): { [key: string]: boolean } | null => {
    const divideBathroomsArray = control.get(
      'divideBathroomsArray'
    ) as FormArray;
    const originalQuantity = control.get('originalQuantity').value;
    const totalQuantity = divideBathroomsArray.controls.reduce(
      (sum, formGroup) => {
        const quantity = formGroup.get('quantity').value || 0;
        return sum + quantity;
      },
      0
    );
    const totalNumber = parseInt(totalQuantity)

    if (totalNumber > originalQuantity) {
      return { totalExceeded: true };
    }

    if (totalNumber < originalQuantity) {
      return { totalMissing: true };
    }

    return null;
  };