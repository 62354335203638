import { Component, DoCheck, OnInit } from '@angular/core';
import { Menu } from './interfaces/menu.interface';
import { AuthService } from 'src/app/api/services/auth/auth.service';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
})
export class MenuComponent implements OnInit, DoCheck {


  showText: boolean = false;

  token: string = "";

  menu: Menu[] = []

  roles: any = [];

  url = "";

  ngOnInit(): void {
    this.getRoles()
    this.menu = [
      {
        name: "Home",
        icon: "fs-4 bi-house",
        url: "/home",
        urlActiveClass: "text-danger",
        tooltip: "Home",
        tooltipOptions: {
          showDelay: 200,
          autoHide: false,
          tooltipEvent: "hover",
          tooltipPosition: "right"
        },
        class: "nav-item my-2 item-text-size",
        classTitle: 'ms-1 item-text-size',
        roles: ['ADMIN'],
        function: ()=>{}

      },
      {
        name: "Pedidos",
        icon: "fs-4 bi bi-box-seam",
        url: "/order",
        urlActiveClass: "text-danger",
        tooltip: "Pedidos",
        tooltipOptions: {
          showDelay: 200,
          autoHide: false,
          tooltipEvent: "hover",
          tooltipPosition: "right"
        },
        class: "nav-item my-2 item-text-size",
        classTitle: 'ms-1 item-text-size',
        roles: ['ADMIN'],
        function: ()=>{}

      },
      {
        name: "Plan de produción",
        icon: "fs-4 bi bi-clipboard-data",
        url: "/production",
        urlActiveClass: "text-danger",
        tooltip: "Plan de produción",
        tooltipOptions: {
          showDelay: 200,
          autoHide: false,
          tooltipEvent: "hover",
          tooltipPosition: "right"
        },
        class: "nav-item my-2 item-text-size",
        classTitle: 'ms-1 item-text-size',
        roles: ['ADMIN'],
        function: ()=>{}

      },

      {
        name: "Maquinas y centros",
        icon: "fs-4 bi bi-tools",
        url: "/workplace",
        urlActiveClass: "text-danger",
        tooltip: "Maquinas y centros",
        tooltipOptions: {
          showDelay: 200,
          autoHide: false,
          tooltipEvent: "hover",
          tooltipPosition: "right"
        },
        class: "nav-item my-2 item-text-size",
        classTitle: 'ms-1 item-text-size',
        roles: ['ADMIN'],
        function: ()=>{}

      },
      {
        name: "Reportes",
        icon: "fs-4 bi bi-reception-4",
        url: "/reports",
        urlActiveClass: "text-danger",
        tooltip: "Reportes",
        tooltipOptions: {
          showDelay: 200,
          autoHide: false,
          tooltipEvent: "hover",
          tooltipPosition: "right"
        },
        class: "nav-item my-2 item-text-size",
        classTitle: 'ms-1 item-text-size',
        roles: ['ADMIN'],
        function: ()=>{}

      },
      {
        name: "BOMS",
        icon: "fs-4 bi bi-building-fill",
        url: "/boms",
        urlActiveClass: "text-danger",
        tooltip: "BOMs",
        tooltipOptions: {
          showDelay: 200,
          autoHide: false,
          tooltipEvent: "hover",
          tooltipPosition: "right"
        },
        class: "nav-item my-2 item-text-size",
        classTitle: 'ms-1 item-text-size',
        roles: ['ADMIN'],
        function:()=>{}

      },
      {
        name: "Usuarios",
        icon: "fs-4 bi bi-people",
        url: "/users",
        urlActiveClass: "text-danger",
        tooltip: "Usuarios",
        tooltipOptions: {
          showDelay: 200,
          autoHide: false,
          tooltipEvent: "hover",
          tooltipPosition: "right"
        },
        class: "nav-item my-2 item-text-size",
        classTitle: 'ms-1 item-text-size',
        roles: ['ADMIN'],
        function: ()=>{}

      },
      {
        name: "Añadir archivos",
        icon: "fs-4 bi bi-file-earmark-plus",
        url: "/archives/upload",
        urlActiveClass: "text-danger",
        tooltip: "Añadir archivos",
        tooltipOptions: {
          showDelay: 200,
          autoHide: false,
          tooltipEvent: "hover",
          tooltipPosition: "right"
        },
        class: "nav-item my-2 item-text-size",
        classTitle: 'ms-1 item-text-size',
        roles: ['ADMIN'],
        function: ()=>{}
      },
      {
        name: "Cerrar sesión",
        icon: "fs-4 bi bi-box-arrow-left",
        url: '',
        urlActiveClass: "text-danger",
        tooltip: "Cerrar sesión",
        tooltipOptions: {
          showDelay: 200,
          autoHide: false,
          tooltipEvent: "hover",
          tooltipPosition: "right"
        },
        class: "nav-item my-2 item-text-size",
        classTitle: 'ms-1 item-text-size',
        roles: ['ADMIN'],
        function: ()=>{
          this.signOut()
        }
      },
    ]
  }

  constructor(private authService: AuthService, private messageService: MessageService, private router: Router) {

  }

  getRoles() {
    this.authService.mockActual().subscribe({
      next: (data) => {
        this.roles = data.roles;
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: err.error ? err.error.message : 'Ups! Ocurrio un error'
        })
      }
    })
  }

  ngDoCheck(): void {
    this.showText = this.router.url == "/home" ? true : false;

    this.token = localStorage.getItem('token')
  }

  change() {
    this.showText = this.showText ? false : true
  }

  hasRole(rolesItem: string[]) {
    return this.roles.some((item) => rolesItem.includes(item));
  }

  signOut() {
    localStorage.removeItem('token');
    this.messageService.add({
      severity: 'success',
      summary: 'Hecho!',
      detail: 'Cierre de sesión exitoso! ',
    });
    this.router.navigate(['/auth/login'])
  }

}
