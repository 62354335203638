import { Line } from '../enums/line.enum';
import { Status } from '../enums/status.enum';
import { MachineMock } from '../machine/machineMock.class';
import { Stock } from '../stock/stock.class';

export class Order {
  id: string;
  idInterno: string;
  idPedido: string;
  cliente: string;
  fechaDePedido: Date;
  fechaDeEntrega?:Date
  articulo: string;
  color: null | string;
  colorId?:string;
  cantidad: string;
  estado: any;
  observaciones: string[] = [];
  tela?: string;
  telaId?: string;
  linea?: Line;
  hayStock?: boolean;
  stock?: any;
  prioridad?: string;
  maquina?: any;
  tipo?: string;
  proceso?: string;
  maquinaMock?:MachineMock[] = []
  stockPorTipo?:any;
  isStock?:boolean;
  fechaPrometida?:any;
  fechaPrometidaHistorial?:any;
  hojaRuta?:any;
  indicacion?:any;
  genero?:any;
  lt?:any;
  stockAsignado:any;
}
