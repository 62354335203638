<div class="container-fluid">
  <div class="row">
    <nav
      *ngIf="token"
      class="navbar navbar-expand-lg navbar-light background-menu d-block d-md-none"
    >
      <div class="container-fluid">
        <a class="navbar-brand text-white" href="#">Menu</a>
        <button
          class="navbar-toggler bg-white"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li *ngFor="let item of menu">
              <div
                *ngIf="hasRole(item.roles)"
                pTooltip=""
                [showDelay]="item.tooltipOptions.showDelay"
                [autoHide]="item.tooltipOptions.autoHide"
                [tooltipEvent]="item.tooltipOptions.tooltipEvent"
                [tooltipPosition]="item.tooltipOptions.tooltipPosition"
                [class]="item.class"
              >
                <a
                  [routerLink]="item.url"
                  class="text-decoration-none px-0 align-middle text-white"
                >
                  <i [class]="item.icon"></i>
                  <span class="ms-1">{{ item.name }}</span>
                </a>
              </div>
            </li>
            <li>
              <div class="dropdown pb-4 mt-3">
                <a
                  href="#"
                  class="d-flex align-items-center text-white text-decoration-none dropdown-toggle"
                  id="dropdownUser1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src="https://github.com/mdo.png"
                    alt="hugenerd"
                    width="25"
                    height="25"
                    class="rounded-circle"
                  />
                  <span class="d-none d-sm-inline"></span>
                </a>
                <ul class="dropdown-menu dropdown-menu-dark text-small shadow">
                  <li><a class="dropdown-item" href="#">item 1</a></li>
                  <li><a class="dropdown-item" href="#">item 2</a></li>
                  <li><a class="dropdown-item" href="#">item 3</a></li>
                  <li>
                    <hr class="dropdown-divider" />
                  </li>
                  <li>
                    <a class="dropdown-item" (click)="signOut()">Cerrar sesión</a>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <div
      *ngIf="token"
      [ngClass]="showText ? 'col-1' : 'col-1-menu'"
      class="p-2 background-menu sticky-top d-none d-md-block vh-100"
    >
      <div
        class="d-flex flex-sm-column flex-row flex-nowrap align-items-center sticky-top"
      >
        <ul
          class="nav nav-pills nav-flush flex-sm-column flex-row flex-nowrap mb-auto mx-auto text-start align-items-start"
        >
          <li *ngFor="let item of menu">
            <div
              *ngIf="hasRole(item.roles)"
              [pTooltip]="showText ? '' : item.tooltip"
              [showDelay]="item.tooltipOptions.showDelay"
              [autoHide]="item.tooltipOptions.autoHide"
              [tooltipEvent]="item.tooltipOptions.tooltipEvent"
              [tooltipPosition]="item.tooltipOptions.tooltipPosition"
              [class]="item.class + ''"
            >
              <a
                (click)="item.function()"
                [routerLink]="item.url"
                class="text-decoration-none px-0 align-middle text-white"
              >
                <i [class]="item.icon" class="fs-icon"></i>
                <span  [class]="item.classTitle" *ngIf="showText">{{ item.name }}</span>
              </a>
            </div>
          </li>
        </ul>
        <hr />
      </div>
    </div>
    <div
      [ngClass]="
        showText && token
          ? 'col-sm-12 col-md-11 m-0 p-0 bg-green-bg'
          : token
          ? 'col-12 col-12-extend m-0 p-0'
          : 'col-12 m-0 p-0'
      "
    >
      <app-main></app-main>
    </div>
  </div>
</div>

