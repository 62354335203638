<app-banner
  [sizeTitleClass]="'h6'"
  [title]="'Producción'"
  [backgroundName]=""
  [fontSize]="1"
  [routerBack]="'/home'"
></app-banner>

<div class="card text-center" *ngIf="spinner">
  <span>
    <p-progressSpinner></p-progressSpinner>
  </span>
  <br />
  <span class="text-center">Cargando...</span>
</div>

<div class="container-fluid mt-5 p-2">
  <div class="row m-auto">
    <div class="col-12 col-sm-11">
      <div class="panel">
        <a routerLink="/production/warped" class="text-decoration-none">
          <h1 class="text-dark text-decoration-none h6 fw-bold">Urdido</h1>
        </a>
      </div>
    </div>
    <div class="col-12 col-sm-11">
      <div class="panel">
        <a routerLink="/production/weaving" class="text-decoration-none">
          <h1 class="text-dark text-decoration-none h6 fw-bold">Tejeduria</h1>
        </a>
      </div>
    </div>
    <div class="col-12 col-sm-11">
      <div class="panel">
        <a routerLink="/production/cleaning" class="text-decoration-none">
          <h1 class="text-dark text-decoration-none h6 fw-bold">Tintoreria</h1>
        </a>
      </div>
    </div>
  </div>
</div>
