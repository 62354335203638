import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ServerResponse } from 'src/app/shared/models/model-forms/server.response.interface';
import { environment } from 'src/environments/environment.development';

@Injectable({
  providedIn: 'root'
})
export class WeavingService {

  constructor(private http:HttpClient) { }

  getPedidosTejeduria():Observable<ServerResponse<any>>{
    let headers = new HttpHeaders()

    headers = headers.append(
      'Authorization',
      "Bearer " + localStorage.getItem('token')
    );  

    return this.http.get(`${environment.apiUrl}/pedidos/tejeduria`, {headers : headers})
    .pipe (
      map((res:any)=>{
        return res
      })
    )
  }

  asignacionWeaving(ordenes:any):Observable<ServerResponse<any>>{
    let headers = new HttpHeaders()

    headers = headers.append(
      'Authorization',
      "Bearer " + localStorage.getItem('token')
    );  

    return this.http.post(`${environment.apiUrl}/asignacion-maquinas/tejeduria`,ordenes, {headers : headers})
    .pipe (
      map((res:any)=>{
        return res
      })
    )
  }

  asignarMaquinasWeaving(ordenes:any):Observable<ServerResponse<any>>{
    let headers = new HttpHeaders()

    headers = headers.append(
      'Authorization',
      "Bearer " + localStorage.getItem('token')
    );  

    return this.http.post(`${environment.apiUrl}/asignacion-maquinas/tejeduria/asignar`,ordenes, {headers : headers})
    .pipe (
      map((res:any)=>{
        return res
      })
    )
  }

  getOrdenesTejeduria(){
    let headers = new HttpHeaders();

    headers = headers.append(
      'Authorization',
      "Bearer " + localStorage.getItem('token')
    );

    return this.http.get(`${environment.apiUrl}/ordenes-tejeduria`, {headers : headers})
    .pipe (
      map((res:any)=>{
        return res
      })
    )
  }

  assignStartDateOrder(orden:any,fecha:any):Observable<ServerResponse<any>>{
    let headers = new HttpHeaders()

    headers = headers.append(
      'Authorization',
      "Bearer " + localStorage.getItem('token')
    );  

    return this.http.post(`${environment.apiUrl}/ordenes-tejeduria/fecha-inicio/${orden.id}`,{fecha}, {headers : headers})
    .pipe (
      map((res:any)=>{
        return res
      })
    )
  }

    

}
