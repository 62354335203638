import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ServerResponse } from 'src/app/shared/models/model-forms/server.response.interface';
import { environment } from 'src/environments/environment.development';

@Injectable({
  providedIn: 'root'
})
export class WarpedService {

  constructor(private http:HttpClient) { }

  getPedidosUrdido():Observable<ServerResponse<any>>{
    let headers = new HttpHeaders()

    headers = headers.append(
      'Authorization',
      "Bearer " + localStorage.getItem('token')
    );  

    return this.http.get(`${environment.apiUrl}/pedidos/urdido`, {headers : headers})
    .pipe (
      map((res:any)=>{
        return res
      })
    )
  }
  
  asignacionUrdido(ordenes:any = []):Observable<ServerResponse<any>>{
    let headers = new HttpHeaders()

    headers = headers.append(
      'Authorization',
      "Bearer " + localStorage.getItem('token')
    );  

    return this.http.post(`${environment.apiUrl}/asignacion-maquinas/urdido`,{ordenes} ,{headers : headers})
    .pipe (
      map((res:any)=>{
        return res
      })
    )
  }

  asignarMaquinasAsignarUrdido(asignacionMaquinas:any = []):Observable<ServerResponse<any>>{
    let headers = new HttpHeaders()

    headers = headers.append(
      'Authorization',
      "Bearer " + localStorage.getItem('token')
    );  

    return this.http.post(`${environment.apiUrl}/asignacion-maquinas/urdido/asignar`,{asignacionMaquinas} ,{headers : headers})
    .pipe (
      map((res:any)=>{
        return res
      })
    )
  }
}
