<p-dialog [(visible)]="showPopUpAddOrder" [draggable]="false" [style]="{
    'min-width': '50vw',
    width: '50vw'
  }" [modal]="true" class="modal-add-order" styleClass="modal-add-order">
  <app-add-order (eventEmitterNewOrder)="addOrderEmit($event)"></app-add-order>
</p-dialog>


  <p-table [value]="orders" dataKey="id" [tableStyle]="{ 'min-width': '45rem' }" styleClass="p-datatable-sm" [style]="{
    'max-height': '70vh',
    'overflow-y': 'auto',
  }" [scrollable]="true" [rows]="size" [(selection)]="selectOrders" (sortFunction)="customSort($event)"
    [customSort]="true"  scrollHeight="70vh" selectionMode="single">
    <ng-template pTemplate="header"> 
      <tr>
        <th style="width: 2%" class="td m-0 p-0">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th pSortableColumn="idInterno" class="td m-0 p-0" style="width: 4.5%">
          Nro <p-sortIcon field="idInterno"></p-sortIcon>
        </th>
        <th pSortableColumn="fechaDeEntrega" class="td m-0 p-0" style="width: 12.5%">
          Fecha entrega <p-sortIcon field="fechaDeEntrega"></p-sortIcon>
        </th>
        <th pSortableColumn="cliente" class="td m-0 p-0" style="width: 20.5%">
          Cliente <p-sortIcon field="cliente"></p-sortIcon>
        </th>
        <th pSortableColumn="tela" class="td m-0 p-0" style="width: 12.5%">
          Articulo <p-sortIcon field="tela"></p-sortIcon>
        </th>
        <th pSortableColumn="color" class="td m-0 p-0" style="width: 12.5%">
          Color <p-sortIcon field="color"></p-sortIcon>
        </th>
        <th pSortableColumn="indicacion" class="td m-0 p-0" style="width: 12.5%">
          Indicación <p-sortIcon field="indicacion"></p-sortIcon>
        </th>
        <th pSortableColumn="cantidad" class="td m-0 p-0" style="width: 12.5%">
          Cantidad <p-sortIcon field="cantidad"></p-sortIcon>
        </th>
        <th class="td m-0 p-0" style="width: 12.5%">Eliminar</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-order let-rowIndex="rowIndex">
      <tr>
        <td class="td p-0 p-0">
          <p-tableCheckbox [value]="order" *ngIf="order.id"></p-tableCheckbox>
        </td>
        <td class="td p-0 m-0 truncate">
          {{order.idInterno}}
        </td>
        <td class="td p-0 m-0 truncate">
          {{ order.fechaDePedido | date : "dd/MM/yyyy" || '' }}
        </td>
        <td class="td p-0 m-0 fw-bold truncate">{{ order.cliente || 'Stock' }}</td>
        <td class="td p-0 m-0 truncate">{{ order.tela }}</td>
        <td class="td p-0 m-0 fw-bold truncate">{{ order.color }}</td>
        <td class="td p-0 m-0 truncate">
          <span>{{order.indicacion }}</span>
          <!-- <span *ngIf="order.indicacion == 'L'">LISO</span>
          <span *ngIf="order.indicacion == 'IR'">IMPER + RESINA</span>
          <span *ngIf="order.indicacion == 'I'">IMPER</span> -->
        </td>
        <td class="td p-0 m-0 fw-bold">{{ order.cantidadAPlanificar.toFixed(1) }}</td>
        <td class="td p-0 m-0">
          <p-button *ngIf="order.tipo == 'STOCK'" icon="pi pi-times-circle" class=""
            styleClass="p-button-text p-button-sm p-button-secondary padding-0" pTooltip="Eliminar" tooltipPosition="bottom"
            (onClick)="deleteAddOrder(order,rowIndex)"></p-button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="loadingbody" let-rowData>
      <tr class="h-100 w-100">
        <td colspan="13">
          <div class="text-center">
            <span>
              <p-progressSpinner></p-progressSpinner>
            </span>
            <br />
            <span class="text-center">Cargando...</span>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" class="w-100">
      <tr>
        <td colspan="12">
          <p-messages class="w-100" [value]="[{ severity: 'info', detail: 'No hay pedidos' }]" [enableService]="false"
            [closable]="false">
          </p-messages>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <div class="row d-flex justify-content-center mb-5 mt-1">
    <div class="col-12 text-end">
      <p-button type="button" styleClass="p-button-rounded p-button-sm  p-button-secondary add-order-cleaning p-1"
        class="my-3 mx-3" icon="pi pi-plus" iconPos="left" label="Agregar orden"
        (click)="showModalAddOrder()"></p-button>
      <p-button type="button" (onClick)="navigateProductionOrder()"
        styleClass="p-button-rounded p-button-sm p-button-secondary select-order-cleaning p-1" class="mx-3 "
        icon="pi pi-arrow-right" iconPos="right" label="Seleccionar pedidos" [loading]="loadingSelectOrders"
        [disabled]="selectOrders.length <= 0"></p-button>
    </div>
  </div>