<app-banner [sizeTitleClass]="'h3'" [title]="'Crear máquina'" [backgroundName]="'/production/weaving/tejeduria-banner.png'"
    [fontSize]="2" [routerBack]="'/boms/processes/'"></app-banner>

<div class="card text-center" *ngIf="spinner">
  <span>
      <p-progressSpinner></p-progressSpinner>
  </span>
  <br />
  <span class="text-center">Cargando...</span>
</div>


<div class="container-fluid mt-3" *ngIf="!spinner">
  <div class="row">
    <div class="col-12">
      <app-form-process [process]="process" (eventUser)="createProcess($event)"></app-form-process>
    </div>
  </div>
</div>