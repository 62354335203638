<app-banner
  [sizeTitleClass]="'h6'"
  [title]="'Todos los pedidos'"
  [backgroundName]=""
  [fontSize]="1"
  [routerBack]="'/home'"
></app-banner>

<div class="container-fluid mt-3" *ngIf="!spinner">
  <div class="row">
    <div class="col-10">
      <app-filters
        [filters]="filters"
        (eventFilterEmitter)="searchFilter($event)"
      ></app-filters>
    </div>
    <div class="col-12 col-lg-2 text-lg-end">
      <p-button
        label="Descargar .xls"
        type="button"
        [loading]="loadingButtonExcel"
        icon="bi bi-file-earmark-arrow-down"
        (click)="exportExcel()"
        pTooltip="Descargar Excel"
        tooltipPosition="bottom"
        styleClass="p-button-sm p-button-rounded p-button-success text-dark padding-1"
        class="p-button-success">
      </p-button>
    </div>
  </div>
  <div class="row p-0 mt-3">
    <div class="col-12">
      <app-table-order
        [totalRecords]="totalRecords"
        [size]="size"
        [paginate]="paginate"
        [orders]="orders"
        (eventEmitterTable)="getOrders($event)"
      >
      </app-table-order>
    </div>
  </div>
</div>

<div class="card text-center" *ngIf="spinner">
  <span>
    <p-progressSpinner></p-progressSpinner>
  </span>
  <br />
  <span class="text-center">Cargando...</span>
</div>
