<form [formGroup]="form" (ngSubmit)="navigateWorkPlaceUrdido()">
    <p-table [value]="getUrdidoresArray.controls" dataKey="id" [tableStyle]="{ 'min-width': '40rem' }"
        styleClass="p-datatable-sm" [style]="{
      'max-height': '70vh',
      'overflow-y': 'auto',
    }" [scrollable]="true" [rows]="size" [customSort]="true" scrollHeight="70vh" selectionMode="single">
        <ng-template pTemplate="header">
            <tr>
                <th class="td m-0 p-0" style="width: 12.5%">
                    Articulo
                </th>
                <th class="td m-0 p-0" style="width: 12.5%">
                    Cantidad cadena
                </th>
                <th class="td m-0 p-0" style="width: 12.5%">
                    Urdidor
                </th>
                <th class="td m-0 p-0" style="width: 12.5%">
                    Cantidad hilado
                </th>
                <th class="td m-0 p-0" style="width: 12.5%">
                    Cantidad de cargas
                </th>
                <th class="td m-0 p-0" style="width: 12.5%">
                    Peso por carga
                </th>
                <th class="td m-0 p-0" style="width: 12.5%">
                    Claves
                </th>
                <th class="td m-0 p-0" style="width: 12.5%">
                    Asignar
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-articulo let-rowIndex="rowIndex" formArrayName="urdidoresArray">
            <tr [formGroupName]="rowIndex">
                <td class="td m-0 p-0 truncate fw-bold">{{ articulo.controls.articulo.value }}</td>
                <td class="td m-0 p-0 truncate">{{ articulo.controls.cantidadCadena.value.toFixed(2) }}</td>
                <td class="td m-0 p-0 truncatev fw-bold">{{ articulo.controls.urdidor.value }}</td>
                <td class="td m-0 p-0 truncate">
                    <input (input)="changeCantidadCargas(articulo)" pInputText type="number" [style]="{ width: '100%',border: '2px solid #F0F47E' }"
                        formControlName="cantidadHilado" class="w-75 height" />
                    <span class="danger" *ngIf="
                    getUrdidoresArray?.controls[rowIndex]
                      ?.get('cantidadHilado') 
                      ?.hasError('required') &&
                      getUrdidoresArray?.controls[rowIndex]?.get('cantidadHilado')?.touched
                  ">
                        <small class="p-error">Este campo es <strong>requerido</strong></small>
                    </span>
                    <span class="danger" *ngIf="
                    getUrdidoresArray?.controls[rowIndex]
                      ?.get('cantidadHilado')
                      ?.hasError('max') &&
                      getUrdidoresArray?.controls[rowIndex]?.get('cantidadHilado')?.touched
                  ">
                        <small class="p-error">No puede superar la cantidad de cadena <strong>requerida</strong></small>
                    </span>
                </td>
                <td class="td m-0 p-0 truncate fw-bold">
                    {{ articulo.controls.cantidadDeCargas.value }}
                    <!-- {{loadWeightCalculator(articulo,rowIndex)}} -->
                </td>
                <td class="td m-0 p-0 truncate">
                    {{articulo.controls.pesoPorCarga.value}}
                </td>
                <td class="td m-0 p-0 truncate fw-bold">
                    <div *ngFor="let claves of articulo.controls.claveAsignada.value">
                        <div *ngFor="let clave of claves.clave">
                            {{ clave?.clave|| "SIN CLAVE"}}
                        </div>
                    </div>
                </td>
                <td class="td m-0 p-0 truncate">
                    <p-button *ngIf="!getUrdidoresArray?.controls[rowIndex]?.get('claveAsignada').value" [disabled]="!getUrdidoresArray?.controls[rowIndex]
                    ?.get('cantidadHilado').valid" icon="" class=""
                        styleClass="p-button-sm p-button-danger p-button-outlined p-button-text padding-0 icon-width-2 bi bi-box-seam" pTooltip="Asignar"
                        tooltipPosition="bottom" (onClick)="assignMp(articulo,rowIndex)">
                    </p-button>

                    <p-button *ngIf="getUrdidoresArray?.controls[rowIndex]?.get('claveAsignada').value" [disabled]="!getUrdidoresArray?.controls[rowIndex]
                    ?.get('cantidadHilado').valid" icon="" class=""
                        styleClass="p-button-sm p-button-success p-button-outlined p-button-text padding-0 icon-width-2 bi bi-box-seam" pTooltip="Asignar"
                        tooltipPosition="bottom" (onClick)="assignMp(articulo,rowIndex)">
                    </p-button>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="loadingbody" let-rowData>
            <tr class="h-100 w-100">
                <td colspan="13">
                    <div class="text-center">
                        <span>
                            <p-progressSpinner></p-progressSpinner>
                        </span>
                        <br />
                        <span class="text-center">Cargando...</span>
                    </div>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" class="w-100">
            <tr>
                <td colspan="12">
                    <p-messages class="w-100" [value]="[{ severity: 'info', detail: 'No hay pedidos' }]"
                        [enableService]="false" [closable]="false">
                    </p-messages>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <div class="row d-flex justify-content-center my-5">
        <div class="col-12 text-end">
            <p-button type="button" (onClick)="navigateWorkPlaceUrdido()"
                styleClass="p-button-sm p-button-secondary button-urdido padding-1" class="my-3 mx-3" icon="pi pi-arrow-right"
                iconPos="right" label="Ir a urdidores"></p-button>
        </div>
    </div>
    <p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>
</form>