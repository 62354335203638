<div class="card text-center" *ngIf="spinner">
  <span>
    <p-progressSpinner></p-progressSpinner>
  </span>
  <br />
  <span class="text-center">Cargando...</span>
</div>

<div *ngIf="tela && !spinner">
  <div class="row text-center">
    <div class="col">
      <h3 class="text-secondary">Orden de producción N°1566</h3>
    </div>
  </div>
  <div class="row text-center mt-3">
    <div class="col">
      <p class="text-dark">Articulo: {{ tela.nombre }}</p>
      <p class="text-dark">Cantidad: 30.000 -> 30.520</p>
      <p class="text-dark">Tejedor: Valentino</p>
      <p class="text-dark">Maquina: SOFT</p>
    </div>
  </div>
  <div class="row text-center">
    <!-- <div class="col">
      <p *ngFor="let deposit of depositsOriginal" class="text-dark">
        {{ deposit.deposito.nombre }}:
        <b
          [class]="{
            'text-danger':
              returnNumber(deposit.cantidad) < returnNumber(order.cantidad),
            'text-success':
              returnNumber(deposit.cantidad) >= returnNumber(order.cantidad)
          }"
          >{{ deposit.cantidad }}</b
        >
      </p>
    </div> -->
    <div class="col">
        <p class="text-dark fs-5">
            Cantidad de bobinas : 108
          </p>
          <p class="text-dark fs-5">Stock existente</p>
          <p class="text-dark fs-5">Estrada 50</p>
          <p class="text-dark fs-5">Roca 20</p>
    </div>
  </div>
  <form [formGroup]="form" (ngSubmit)="sendForm()">
    <div class="row justify-content-center">
      <div class="col-12 col-md-4 mx-md-3">
        <label for="deposito" class="text-dark">Asignar</label>
        <p-dropdown
          [style]="{ width: '100%' }"
          class=""
          appendTo="body"
          [options]="depositos"
          [showClear]="true"
          optionValue="id"
          optionLabel="nombre"
          formControlName="deposito"
          placeholder="Seleccione"
        >
          <ng-template let-option pTemplate="item">
            <span [class]="'customer-badge status-' + option.id">{{
              option.nombre
            }}</span>
          </ng-template>
        </p-dropdown>
        <span
          class="danger"
          *ngIf="
            form.get('deposito').hasError('required') &&
            form.get('deposito').touched
          "
        >
          <small class="p-error"
            >Este campo es <strong>requerido</strong></small
          >
        </span>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col text-end">
        <p-button
          label="Guardar"
          styleClass="p-button p-button-secondary p-button-rounded assing-deposit-button"
          type="submit"
          [disabled]="form.invalid"
        >
        </p-button>
      </div>
    </div>
  </form>
</div>
