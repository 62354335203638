import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-details-fabric',
  templateUrl: './details-fabric.component.html',
  styleUrls: ['./details-fabric.component.css']
})
export class DetailsFabricComponent {

  spinner = true;

  fabric:any = {}

  constructor(
    private messageService: MessageService,
    public dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig
  ) {}


  ngOnInit(): void {
    this.fabric = history.state.fabric
    this.spinner = false;
  }

  ngOnDestroy(): void {
    this.spinner = true;
  }

}
