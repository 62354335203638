import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment.development';
import { Observable, map } from 'rxjs';
import { ServerResponse } from 'src/app/shared/models/model-forms/server.response.interface';

@Injectable({
  providedIn: 'root'
})
export class ProcessService {
  constructor(private http: HttpClient) {
  }

  getProcessesList(){
    let headers = new HttpHeaders();

    headers = headers.append(
      'Authorization',
      "Bearer " + localStorage.getItem('token')
    );

    return this.http.get(`${environment.apiUrl}/procesos/lista`, {headers : headers})
    .pipe (
      map((res:any)=>{
        return res
      })
    )
  }

  createProcess(fabric:any):Observable<ServerResponse<any>>{
    let headers = new HttpHeaders()

    headers = headers.append(
      'Authorization',
      "Bearer " + localStorage.getItem('token')
    );  

    return this.http.post(`${environment.apiUrl}/procesos/nuevo`,fabric, {headers : headers})
    .pipe (
      map((res:any)=>{
        return res
      })
    )
  }

  updateProcess(tela:any):Observable<ServerResponse<any>>{
    let headers = new HttpHeaders()

    headers = headers.append(
      'Authorization',
      "Bearer " + localStorage.getItem('token')
    );  

    return this.http.post(`${environment.apiUrl}/procesos/guardar`,tela, {headers : headers})
    .pipe (
      map((res:any)=>{
        return res
      })
    )
  }
}
