export enum FilterEnum {
  INPUTTEXT,
  INPUTNUMBER,
  INPUTPASSWORD,
  DATE,
  DROPDOWN,
  MULTISELECT,
  SWITCH,
  CHECKBOX,
  RADIO,
}
