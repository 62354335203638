import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment.development';

@Injectable({
  providedIn: 'root'
})
export class OrderFileService {

  constructor(private http:HttpClient) { }

  downloadOrderExcel(query: any) {
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    return this.http
      .get(`${environment.apiUrl}/pedidos/filtro/excel`, {
        headers: headers,
        responseType: "blob",
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  descargarOrderProductionWeaving(data: any) {
    let headers = new HttpHeaders({
      "Content-Type": "text/html",
    });
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    return this.http
      .post(
        `${environment.apiUrl}/generar-pdf`,
        data,
        { headers: headers, responseType: "blob" }
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }


  descargarOrderProductionWarped(data: any) {
    let headers = new HttpHeaders({
      "Content-Type": "text/html",
    });
    headers = headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    return this.http
      .post(
        `${environment.apiUrl}/generar-pdf`,
        data,
        { headers: headers, responseType: "blob" }
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

}
