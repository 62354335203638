<p-table [value]="fabrics" dataKey="id" [tableStyle]="{ 'min-width': '40rem' }" styleClass="p-datatable-sm" [style]="{
    'max-height': '65vh',
    'overflow-y': 'auto',
  }" [scrollable]="true" [rows]="size" [customSort]="true"  selectionMode="single">
    <ng-template pTemplate="header">
        <tr>
            <th class="td m-0 p-0" style="width: 20%">
                Nombre
            </th>
            <th class="td m-0 p-0" style="width: 20%">
                Linea
            </th>
            <th class="td m-0 p-0" style="width: 20%">
                Composición
            </th>
            <th class="td m-0 p-0" style="width: 20%">
                Procesos
            </th>
            <th class="td m-0 p-0" style="width: 20%">
                Acciones
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="caption">
        <div class="row m-0 p-0" *ngIf="isAbm">
            <div class="col m-0 p-0 text-end">
                <p-button icon="pi pi-plus" class=""
                    styleClass="p-button-lg p-button-secondary p-button-outlined p-button-text" pTooltip="Crear tela"
                    tooltipPosition="bottom" routerLink="/boms/fabrics/create-fabric">
                </p-button>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="body" let-tela let-rowIndex="rowIndex">
        <tr>
            <td class="td m-0 p-0 fw-bold truncate"> {{tela.nombre}}</td>
            <td class="td m-0 p-0 truncate">{{tela.linea}}</td>
            <td class="td m-0 p-0 fw-bold truncate">
                <div class="row m-0 p-0">
                    <div class="col-12 m-0 p-0">
                        <span class="fw-bold" *ngFor="let item of tela.composicion;let l = last; let i = index">
                            {{item}}
                            <b *ngIf="!l">-</b>
                        </span>
                    </div>
                </div>
            </td>
            <td class="td m-0 p-0 truncate">
                <div class="row">
                    <div class="col-12">
                        <span *ngFor="let item of tela.procesos;let l = last; let i = index">
                            {{item.proceso.nombre}}
                            <b *ngIf="!l">-</b>
                        </span>
                    </div>
                </div>
            </td>
            <td class="td m-0 p-0 fw-bold truncate">
                <p-button icon="pi pi-eye" *ngIf="isAbm" class=""
                    styleClass="p-button-sm p-button-secondary p-button-outlined p-button-text padding-0" pTooltip="Ver detalle"
                    tooltipPosition="bottom" (onClick)="detailsFabric(tela)">
                </p-button>
                <p-button icon="pi pi-pencil" *ngIf="isAbm" class=""
                    styleClass="p-button-sm p-button-secondary p-button-outlined p-button-text padding-0"
                    pTooltip="Actualizar tela" tooltipPosition="bottom" (onClick)="updateFabric(tela)">
                </p-button>
                <p-button icon="pi pi-file-edit" *ngIf="!isAbm" class=""
                    styleClass="p-button-sm p-button-secondary p-button-outlined p-button-text padding-0"
                    pTooltip="Ver rollos" tooltipPosition="bottom" (onClick)="seeRolls(tela)">
                </p-button>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="loadingbody" let-rowData>
        <tr class="h-100 w-100">
            <td colspan="13">
                <div class="text-center">
                    <span>
                        <p-progressSpinner></p-progressSpinner>
                    </span>
                    <br />
                    <span class="text-center">Cargando...</span>
                </div>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" class="w-100">
        <tr>
            <td colspan="12">
                <p-messages class="w-100" [value]="[{ severity: 'info', detail: 'No hay usuarios' }]"
                    [enableService]="false" [closable]="false">
                </p-messages>
            </td>
        </tr>
    </ng-template>
</p-table>
<div class="row d-flex justify-content-center my-5">
    <div class="col-12 text-end">
        <!-- <p-button type="button" styleClass="p-button-rounded p-button-sm  p-button-secondary add-order-warped"
        class="my-3 mx-3" icon="pi pi-plus" iconPos="left" label="Agregar orden"
        (onClick)="addOrderWarped()"></p-button>
      <p-button [disabled]="form.invalid" type="submit" styleClass="p-button-sm p-button-secondary button-urdido"
        class="my-3 mx-3" icon="pi pi-arrow-right" iconPos="right" label="Asignar materia prima"></p-button> -->
    </div>
</div>