<app-banner [sizeTitleClass]="'h3'" [title]="'Procesos'" [backgroundName]="'/production/weaving/tejeduria-banner.png'"
    [fontSize]="2" [routerBack]="'/boms'"></app-banner>

<div class="card text-center" *ngIf="spinner">
    <span>
        <p-progressSpinner></p-progressSpinner>
    </span>
    <br />
    <span class="text-center">Cargando...</span>
</div>

<div class="container-fluid mt-3" *ngIf="!spinner">
    <!-- <div class="row">
      <div class="col-11">
        <app-filters [filters]="filters"></app-filters>
      </div>
      <div class="col-auto">
        <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success mr-2" pTooltip="Descargar Excel" tooltipPosition="bottom"></button>
      </div>
    </div> -->
    <div class="row mt-3">
        <div class="col-12">
            <app-table-process-list (eventEmitterTable)="getProcesses($event)" [size]="size" [processes]="processes">
            </app-table-process-list>
        </div>
    </div>
</div>