<div class="container-fluid bg-general">

  <div class="row justify-content-center align-items-center vh-100 text-center">
    <div class="row">
      <div class="col">
        <img src="assets/img/logo.png" alt="" srcset="">
      </div>
    </div>
    <div class="col-6 card pt-5">
      <h1 class="text-white mb-3">Bienvenido</h1>
      <form [formGroup]="formDatos" class="" (ngSubmit)="submit()">
        <div class="row text-center mt-3">
          <div class="col-8 m-auto">
            <div class="input-group">
              <button
                type="button"
                pButton
                pRipple
                icon="pi pi-user"
                styleClass="p-button-warn"
                class="button-user"
                disabled
              ></button>
              <input
                class="text-line"
                type="text"
                formControlName="user"
                placeholder="Usuario"
              />
              <div
                *ngIf="
                  formDatos.get('user').hasError('required') &&
                  formDatos.get('user').touched
                "
                class="text-center"
              >
                <small class="p-error"
                  >Este campo es <strong>requerido</strong></small
                >
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-8 m-auto">
            <div class="input-group">
              <button
                type="button"
                pButton
                pRipple
                icon="pi pi-lock"
                styleClass="p-button-warn"
                class="button-user"
                disabled
              ></button>
              <input
                type="password"
                formControlName="pass"
                placeholder="Contraseña"
                class="text-line"
              />
            </div>
            <div
              *ngIf="
                formDatos.get('pass').hasError('required') &&
                formDatos.get('pass').touched
              "
              class="text-center"
            >
              <small class="p-error"
                >Este campo es <strong>requerido</strong></small
              >
            </div>
          </div>
        </div>
        <div class="row my-5">
          <div class="col-12">
            <p-button type="submit" styleClass="p-button button-login" class="" label="Ingresar" >

            </p-button>

          </div>
        </div>
        <!-- <div class="row mt-3">
          <div class="col-12">
            <p>
              ¿No tienes una cuenta? Haz click
              <a routerLink="/registro"><span>Registrarse</span></a>
            </p>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <p>
              <a routerLink="/olvido-contraseña"
                ><span>He olvidado mi contraseña</span></a
              >
            </p>
          </div>
        </div> -->
      </form>
    </div>
  </div>
</div>
