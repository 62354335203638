<div class="container">
  <div class="row">
    <div class="col-12">
      <h1 class="title text-center h3">Agregar orden</h1>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12">
      <form [formGroup]="form" (ngSubmit)="addOrderSubmit()">
        <div class="row mt-3">
          <div class="col-12 col-md-6">
            <label for="article" class="">Articulo: </label><br />
            <p-dropdown class="" [options]="articles" [style]="{ width: '100%', border: '2px solid #C6C5FF' }"
              placeholder="- Articulo -" formControlName="article" optionLabel="nombre" appendTo="body">
              <ng-template let-option pTemplate="item">
                <span [class]="'article-' + option.id">{{
                  option.nombre
                  }}</span>
              </ng-template>
            </p-dropdown>
            <span class="danger" *ngIf="
                form.get('article').hasError('required') &&
                form.get('article').touched
              ">
              <small class="p-error">Este campo es <strong>requerido</strong></small>
            </span>
          </div>
          <div class="col-12 col-md-6">
            <label for="color" class="">Color: </label><br />
            <p-dropdown class="" [options]="colors" [style]="{ width: '100%', border: '2px solid #C6C5FF' }"
              placeholder="- Color -" formControlName="color" optionLabel="nombre" appendTo="body">
              <ng-template let-option pTemplate="item">
                <span [class]="'color-' + option.id">{{ option.nombre }}</span>
              </ng-template>
            </p-dropdown>
            <span class="danger" *ngIf="
                form.get('color').hasError('required') &&
                form.get('color').touched
              ">
              <small class="p-error">Este campo es <strong>requerido</strong></small>
            </span>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12 col-md-6">
            <label for="color" class="">Indicación: </label><br />
            <p-dropdown class="" [options]="indications" [style]="{ width: '100%', border: '2px solid #C6C5FF' }"
              placeholder="- Indicación -" formControlName="indication" optionLabel="nombre" optionValue="id"
              appendTo="body">
              <ng-template let-option pTemplate="item">
                <span [class]="'indication-' + option.id">{{
                  option.nombre
                  }}</span>
              </ng-template>
            </p-dropdown>
            <span class="danger" *ngIf="
                form.get('indication').hasError('required') &&
                form.get('indication').touched
              ">
              <small class="p-error">Este campo es <strong>requerido</strong></small>
            </span>
          </div>
          <div class="col-12 col-md-6">
            <label for="quantity" class="">Cantidad (Kg): </label><br />
            <input min="1" type="number" formControlName="quantity" pInputText
              [style]="{ width: '100%',border: '2px solid #C6C5FF' }" />
            <br />
            <span class="danger" *ngIf="
                form.get('quantity').hasError('required') &&
                form.get('quantity').touched
              ">
              <small class="p-error">Este campo es <strong>requerido</strong></small>
            </span>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12 text-end">
            <p-button type="submit" styleClass="p-button-rounded p-button-sm p-button-secondary save-order-cleaning"
              label="Guardar" [disabled]="form.invalid"></p-button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>