import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Order } from '../../../../../shared/models/order/order.class';
import { DataService } from 'src/app/api/services/data/data.service';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';


@Component({
  selector: 'app-add-quantity-weaving',
  templateUrl: './add-quantity-weaving.component.html',
  styleUrls: ['./add-quantity-weaving.component.css']
})
export class AddQuantityWeavingComponent implements OnInit {

  spinner = true;

  form: FormGroup;

  @Output()
  eventEmitterNewOrder: EventEmitter<Order> = new EventEmitter()

  articles: any = [];

  objetoConMayorId:any

  constructor(private datasService: DataService, private messageService: MessageService,
    private dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,) {

  }

  ngOnInit(): void {
    this.objetoConMayorId = this.dialogConfig.data.objetoConMayorId;
    this.form = new FormGroup({
      article: new FormControl("", [Validators.required]),
      quantity: new FormControl("", [Validators.required, Validators.pattern('^[0-9]+$')]),
    })
    this.getTelas();
  }

  getTelas() {
    this.datasService.getTelasSinIndicacion().subscribe(({
      next: (data) => {
        this.articles = data.list;
      }, error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: err.error ? err.error.message : 'Ups! Ocurrio un error',
        });
      },
      complete: () => {
        this.spinner = false;
      }
    }))
  }

  addOrderSubmit() {
    let articulo = this.form.get('article').value;
    let cantidad = this.form.get('quantity').value;

    this.dialogRef.close({ success: true, addTela: { id: 1 + (this.objetoConMayorId.id)  , articulo: articulo.nombre ,cantidad ,tela: { articulo: articulo.nombre, id: articulo.id } } });

    this.form.reset();

  }

}
