import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-table-process-list',
  templateUrl: './table-process-list.component.html',
  styleUrls: ['./table-process-list.component.css']
})
export class TableProcessListComponent implements OnInit{

  
  @Input()
  processes: any[] = [];

  @Input()
  paginate = 1;

  @Input()
  totalRecords = 0;

  @Input()
  size = 10;

  @Output()
  eventEmitterTable: EventEmitter<any> = new EventEmitter();

  ref: DynamicDialogRef | undefined;

  visibleAssignOrder: boolean = false;

  constructor(private dialogService:DialogService,private router:Router){}


  ngOnInit(): void {
    
  }

  detailsMachine(machine:any){
    this.router.navigate(['/boms/processes/detail-process'],{
      state : {machine}
    })
  }


  createMachine(){
    this.router.navigate(['/boms/processes/create-process'])
  }
  updateMachine(machine:any){
    this.router.navigate(['/boms/processes/update-process'],{
      state : {machine}
    })
  }

}
