import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-detail-machine',
  templateUrl: './detail-machine.component.html',
  styleUrls: ['./detail-machine.component.css']
})
export class DetailMachineComponent {

  spinner = true;

  machine:any = {}

  constructor(
    private messageService: MessageService,
    public dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig
  ) {}


  ngOnInit(): void {
    this.machine = history.state.machine
    this.spinner = false;
  }

  ngOnDestroy(): void {
    this.spinner = true;
  }


}
