import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DataService } from 'src/app/api/services/data/data.service';
import { MessageService } from 'primeng/api';
import { OrderService } from 'src/app/api/services/order/order.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-add-order-warped',
  templateUrl: './add-order-warped.component.html',
  styleUrls: ['./add-order-warped.component.css']
})
export class AddOrderWarpedComponent implements OnInit {

  spinner = true;

  form: FormGroup;

  @Output()
  eventEmitterNewOrder: EventEmitter<any> = new EventEmitter()

  telas: any = [];
  articles: any = [];
  colors: any = [];

  generos: any = [
    {
      id: 1,
      nombre: "GENEVE"
    }
  ]


  ref: DynamicDialogRef | undefined;

  constructor(private datasService: DataService, private messageService: MessageService, private orderService: OrderService, public dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig) {

  }

  ngOnInit(): void {
    this.form = new FormGroup({
      article: new FormControl("", [Validators.required]),
      genero: new FormControl("", [Validators.required]),
      quantity: new FormControl("", [Validators.required]),
    })
    this.getArticulos();
  }
  
  getArticulos() {
    this.datasService.getArticulosUrdido().subscribe(({
      next: (data: any) => {
        this.articles = data.map((element, index) => { return element.hiladoList.map((urdido) => { return { nombre: urdido.nombre, id: urdido.id } }) }).flat(Infinity);
        this.telas = data.map((element, index) => { return {tela : element.tela , urdidores: element.urdidores} }).flat(Infinity);
      }, error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: err.error ? err.error.message : 'Ups! Ocurrio un error',
        });
      }, complete: () => {
        this.spinner = false;

        // this.getTelas();
      }
    }))
  }

  getTelas() {
    this.datasService.getTelasSinIndicacion().subscribe(({
      next: (data) => {
        this.telas = data.list;
      }, error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: err.error ? err.error.message : 'Ups! Ocurrio un error',
        });
      }, complete: () => {
      }
    }))
  }



  addOrderSubmit() {
    let articulo = {
      articulo: { id: this.form.get('article').value.id, nombre: this.form.get('article').value.nombre },
      id: this.form.get('article').value.id,
      cantidad: this.form.get('quantity').value,
      tela: this.form.get('genero').value.tela,
      maquinas: this.form.get('genero').value.urdidores || [],
      newItem: true
    }

    this.messageService.add({
      severity: 'success',
      summary: 'Guardado',
    });

    this.dialogRef.close({ success: true, articulo: articulo });

    this.form.reset();
  }

}
