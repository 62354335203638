import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProductionWeavingRoutingModule } from './production-weaving-routing.module';
import { OrderModule } from '../../order/order.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { PublicModule } from 'src/app/public/public.module';
import { ProductionWeavingPlanningComponent } from './pages/production-weaving-planning/production-weaving-planning.component';
import { ProductionWeavingPlanningOrderComponent } from './pages/production-weaving-planning-order/production-weaving-planning-order.component';
import { TableProductionWeavingPlanningComponent } from './components/table-production-weaving-planning/table-production-weaving-planning.component';
import { AddQuantityWeavingComponent } from './modals/add-quantity-weaving/add-quantity-weaving.component';
import { OrderProductionModalComponent } from './modals/order-production-modal/order-production-modal.component';
import { TableProductionWeavingCircularComponent } from './components/table-production-weaving-circular/table-production-weaving-circular.component';
import { TableProductionWeavingPlanoComponent } from './components/table-production-weaving-plano/table-production-weaving-plano.component';
import { TableProductionWeavingKettenComponent } from './components/table-production-weaving-ketten/table-production-weaving-ketten.component';
import { SeeDetailsProductionCircularComponent } from './modals/see-details-production-circular/see-details-production-circular.component';
import { AssignYarnWeavingPlanoComponent } from './modals/assign-yarn-weaving-plano/assign-yarn-weaving-plano.component';
import { AssignYarnWeavingKettenComponent } from './modals/assign-yarn-weaving-ketten/assign-yarn-weaving-ketten.component';
import { AssignYarnWeavingCircularComponent } from './modals/assign-yarn-weaving-circular/assign-yarn-weaving-circular.component';
import { SeeDetailsProductionPlanoComponent } from './modals/see-details-production-plano/see-details-production-plano.component';
import { SeeDetailsProductionKettenComponent } from './modals/see-details-production-ketten/see-details-production-ketten.component';

@NgModule({
  declarations: [
    ProductionWeavingPlanningComponent,
    ProductionWeavingPlanningOrderComponent,
    TableProductionWeavingPlanningComponent,
    AddQuantityWeavingComponent,
    OrderProductionModalComponent,
    TableProductionWeavingCircularComponent,
    TableProductionWeavingPlanoComponent,
    TableProductionWeavingKettenComponent,
    SeeDetailsProductionCircularComponent,
    AssignYarnWeavingPlanoComponent,
    AssignYarnWeavingKettenComponent,
    AssignYarnWeavingCircularComponent,
    SeeDetailsProductionPlanoComponent,
    SeeDetailsProductionKettenComponent,
  ],
  imports: [
    CommonModule,
    ProductionWeavingRoutingModule,
    OrderModule,
    SharedModule,
    PublicModule,
  ],
})
export class ProductionWeavingModule {}
