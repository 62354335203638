<div class="card text-center" *ngIf="spinner && depositsCopyOrder.length <= 0">
    <span>
      <p-progressSpinner></p-progressSpinner>
    </span>
    <br />
    <span class="text-center">Cargando...</span>
  </div>
  
  <div *ngIf="order && !spinner && depositsCopyOrder.length > 0">
    <div class="row">
      <div class="col">
        <p>Articulo: {{ order.tela }} {{ order.color }}</p>
        <p>Cantidad: {{ order.cantidad }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <b>Stock existente</b>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <ul>
          <li *ngFor="let deposit of depositsOriginal">
            {{ deposit.nombre }}:
            <b
              [class]="{
                'text-danger':
                  returnNumber(deposit.available) < returnNumber(order.cantidad),
                'text-success':
                  returnNumber(deposit.available) >= returnNumber(order.cantidad)
              }"
              >{{ deposit.available }}</b
            >
          </li>
        </ul>
      </div>
    </div>
    <form [formGroup]="form" (ngSubmit)="sendForm()">
      <div class="row">
        <div class="col">
          <label for="">Seleccione un deposito</label>
          <p-dropdown
            [style]="{ width: '100%' }"
            class=""
            appendTo="body"
            [options]="depositsCopyOrder"
            [showClear]="true"
            optionValue="id"
            optionLabel="nombre"
            formControlName="deposito"
            placeholder="Seleccione"
          >
            <ng-template let-option pTemplate="item">
              <span [class]="'customer-badge status-' + option.nombre">{{
                option.nombre
              }}</span>
            </ng-template>
          </p-dropdown>
          <span
            class="danger"
            *ngIf="
              form.get('deposito').hasError('required') &&
              form.get('deposito').touched
            "
          >
            <small class="p-error"
              >Este campo es <strong>requerido</strong></small
            >
          </span>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <label for="">Fecha entrega: </label>
          <p-calendar
            formControlName="fechaEntrega"
            dateFormat="dd/mm/yy"
            class=""
            appendTo="body"
            inputId="basic"
            [yearNavigator]="true"
            [style]="{ width: '100%' }"
          ></p-calendar>
          <span
            class="danger"
            *ngIf="
              form.get('fechaEntrega').hasError('required') &&
              form.get('fechaEntrega').touched
            "
          >
            <small class="p-error"
              >Este campo es <strong>requerido</strong></small
            >
          </span>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col text-end">
          <p-button
            label="Guardar"
            styleClass="p-button p-button-secondary"
            type="submit"
            [disabled]="form.invalid"
          >
          </p-button>
        </div>
      </div>
    </form>
  </div>
  