import { DatePipe } from '@angular/common';
import {
  Component,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import {
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';

@Component({
  selector: 'app-production-warped-assign-mp-modal',
  templateUrl: './production-warped-assign-mp-modal.component.html',
  styleUrls: ['./production-warped-assign-mp-modal.component.css']
})
export class ProductionWarpedAssignMpModalComponent implements OnInit {

  spinner: boolean = true;

  articulo: any;

  index: any;

  form: FormGroup;

  hilados: any = []

  constructor(
    public messageService: MessageService,
    public dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
    private datepipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.articulo = this.dialogConfig.data.articulo;
    this.index = this.dialogConfig.data.index;

    this.startForm();
    this.armedForm(this.articulo.controls.objectComplete.value)

    this.spinner = false;
  }

  ngOnDestroy(): void {
    this.spinner = true;
  }

  startForm() {
    this.form = new FormGroup({
    });
  }

  // getComposiciones(articulo: any) {
  //   const composiciones = articulo.tela.composicion

  //   const usesComposiciones = composiciones.reduce((result, composicion) => {
  //     for (const clave of articulo.hiladoList) {
  //       let find = result.find((element) => { return element.composicion === composicion })
  //       if (clave.descripcion.includes(composicion) && !find) {
  //         result.push({ composicion, claves: [clave] });
  //       } else {
  //         if (find) {
  //           find.claves.push(clave)
  //         } else {
  //           if (!this.form.get(composicion)) {
  //             result.push({ composicion, claves: [clave] });
  //           }
  //         }
  //       }
  //     }

  //     return result;
  //   }, []);
  //   return usesComposiciones
  // }

  armedForm(articulo: any) {
    this.form.addControl(
      'clave',
      new FormControl('', [
        Validators.required,
        Validators.min(this.articulo.controls.cantidadCadena.value),
      ])
    );

    this.hilados.push({ composicion: 'clave', claves: articulo.hiladoList });

  }


  calculateIsValidQantity(event: any, composicion: any) {
    let quantity = this.articulo.controls.cantidadCadena.value;
    let totalSelect = this.form.value.clave.reduce((total, item) => total + item.cantidad, 0);

    if (totalSelect >= quantity) {
      this.form.get(composicion.composicion).setErrors(null)
      return;
    }

    this.form.get('clave').setErrors({ min: { actual: totalSelect, min: quantity } })

    return;
  }

  sendForm() {

    let selects = []
    selects.push({ composicion: 'clave', clave: this.form.get('clave').value })

    this.messageService.add({
      severity: 'success',
      summary: 'Clave asignada',
    });

    this.dialogRef.close({ success: true, claves: selects, clave: selects, index: this.index });
  }


}

