import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProcessService } from '../../../../../api/services/process/process.service';

@Component({
  selector: 'app-create-process',
  templateUrl: './create-process.component.html',
  styleUrls: ['./create-process.component.css']
})
export class CreateProcessComponent {

  spinner = true;

  process: any = {}

  constructor(
    public messageService: MessageService,
    public dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
    private processService:ProcessService,
    private router:Router
  ) { }

  ngOnInit(): void {
    this.process = this.dialogConfig?.data?.process;
    this.spinner = false;
  }

  createProcess(process: any) {
    this.processService.createProcess(process).subscribe({
      next: (data) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Proceso creado!',
        });
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: err.error ? err.error.message : 'Ups! Ocurrio un error',
        });
      },
      complete: () => {
        this.router.navigate(['/boms/processes'])
      }
    })
  }

}
