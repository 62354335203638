import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-order-production-modal',
  templateUrl: './order-production-modal.component.html',
  styleUrls: ['./order-production-modal.component.css']
})
export class OrderProductionModalComponent implements OnInit{

  tela:any;

  spinner = true;

  form:FormGroup;

  depositos:any = [
    {id : 1, nombre : "Estrada"},
    {id : 2, nombre : "Roca"}
  ]
  
  constructor(
    public dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
    private messageService:MessageService
    ){

  }

  ngOnInit(): void {
    this.tela = this.dialogConfig.data.tela;
    this.spinner = false;
    this.startForm();
    
  }

  startForm() {
    this.form = new FormGroup({
      deposito: new FormControl('', [Validators.required]),
    });
  }

  sendForm(){

  }
  

}
