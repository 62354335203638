import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProductionWeavingPlanningComponent } from './pages/production-weaving-planning/production-weaving-planning.component';
import { ProductionWeavingPlanningOrderComponent } from './pages/production-weaving-planning-order/production-weaving-planning-order.component';

const routes: Routes = [
  {
    path: '',
    children: [
      { path: '', component: ProductionWeavingPlanningComponent },
      { path: 'weaving', component: ProductionWeavingPlanningComponent },
      {
        path: 'order-planning',
        component: ProductionWeavingPlanningOrderComponent,
      },
      { path: '**', redirectTo: '' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductionWeavingRoutingModule { }
