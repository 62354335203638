import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-assign-yarn-weaving-plano',
  templateUrl: './assign-yarn-weaving-plano.component.html',
  styleUrls: ['./assign-yarn-weaving-plano.component.css']
})
export class AssignYarnWeavingPlanoComponent implements OnInit {

  spinner: boolean = true;

  tela: any;

  index: any;

  // formItemReceived:FormGroup

  form: FormGroup;

  cantidadCargas: any = 0;


  cadenas: any = []
  tramas: any = []

  hilados: any = []

  constructor(
    public messageService: MessageService,
    public dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
    private datepipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.tela = this.dialogConfig.data.tela;
    this.startForm();
    this.getComposiciones(this.tela.controls['objectComplete'].value, this.tela.controls['composiciones'].value)

  }

  ngOnDestroy(): void {
    this.spinner = true;
  }

  startForm() {
    this.form = new FormGroup({
    });
  }

  getNumber(parse,number,comp?){
    let numberParse = null;
    switch (parse) {
      case "float":
        numberParse =  parseFloat(number)
        break;
      case "int":
        numberParse =  parseInt(number)
        break;
      default:
        break;
      }
      return numberParse
  }

  obtenerCantidadPorMedida(cantidad,unidad) {
    let cantidadObtenida = 0.00;
    switch (unidad) {
      case "METRO":
        const totalKgs = this.tela.controls.totalKgs.value / this.tela.controls.coeficienteTerminada.value
        cantidadObtenida = Math.floor(totalKgs *  cantidad.hilado.porcentaje) /100        
        break;
      case "KILOS":
        break;
    
      default:
        break;
    }
    return parseFloat(cantidadObtenida.toFixed(2));
  }

  getComposiciones(hilados: any, composiciones: any) {
    const buscarEntradaExistentePorClave = (composicion, claves) => {
      return this.hilados.find(entrada => entrada.claves === claves || entrada.composicion.hilado.id.toString() === composicion.hilado.id.toString());
    };

    const agregarComposicion = (hiladoList, composicion,isCad:boolean) => {
      if (hiladoList.length > 0) {
        composicion.hilado.available = this.calculateIfYarnIsEnough(hiladoList, composicion.hilado.total);
        let nameComposicion = "";
        switch (composicion.composicion) {
          case "POLIAMIDA":
            nameComposicion = "NYLON"
            break;
          default:
            nameComposicion = composicion.composicion
            break;
        }
        console.log(parseFloat(this.tela.get('totalKgs').value) * composicion.hilado.consumoXMetro);


        const contieneNombreEspecifico = hiladoList.some(hilado => hilado.descripcion.includes(nameComposicion));
        if (contieneNombreEspecifico) {
          // Buscar si ya existe una entrada con la misma composición y claves
          const entradaExistenteClave = buscarEntradaExistentePorClave(composicion, hiladoList);

          if (!entradaExistenteClave) {
            this.hilados.push({ composicion: composicion, claves: hiladoList || [], total: parseFloat(this.tela.get('totalKgs').value) * composicion.hilado.consumoXMetro});

            
            // Asegurar que el control en el formulario existe antes de agregarlo
            if (!this.form.get(composicion.hilado.id.toString())) {
              this.form.addControl(
                composicion.hilado.id.toString(),
                new FormControl('', [
                  Validators.min(parseFloat(this.tela.get('totalKgs').value) * composicion.hilado.consumoXMetro),
                ])
              );
            }
          }
        }
      } else if (composicion.composicion) {
        if (!this.form.get(composicion.hilado.id.toString())) {
          composicion.hilado.available = this.calculateIfYarnIsEnough(hiladoList, composicion.hilado.total);
          this.hilados.push({ composicion: composicion, claves: hiladoList || [], total: parseFloat(this.tela.get('totalKgs').value) * composicion.hilado.consumoXMetro});
          this.form.addControl(
            composicion.hilado.id.toString(),
            new FormControl('', [
              Validators.min(parseFloat(this.tela.get('totalKgs').value) * composicion.hilado.consumoXMetro),
            ])
          );
        }
      }
    };

    for (let composicion of composiciones) {
      switch (composicion.hilado.numeroDeComponente) {
        case 1:
          agregarComposicion(hilados.hilado1List, composicion, composicion.hilado.nombre.includes('CAD'));
          break;
        case 2:
          agregarComposicion(hilados.hilado2List, composicion, composicion.hilado.nombre.includes('CAD'));
          break;
        case 3:
          agregarComposicion(hilados.hilado3List, composicion, composicion.hilado.nombre.includes('CAD'));
          break;
        default:
          break;
      }
    }

    this.spinner = false;
  }

  sortClaves(hilado: any = []) {
    return hilado.sort((clave1, clave2) => (clave1?.clave > clave2?.clave ? -1 : 1));
  }

  calculateIfYarnIsEnough(hilado: any, quantity: any) {
    let find = hilado.find((element) => { return element.cantidad >= quantity })
    if (find) {
      return true;
    }
    return false;
  }


  calculateIsValidQantity(event: any, composicion: any) {
    let quantity = composicion.total;
    let composicionSelect = event.value.cantidad
    if (composicionSelect >= quantity) {
      this.form.get(composicion.composicion.hilado.id.toString()).setErrors(null)
      return;
    }

    this.form.get(composicion.composicion.hilado.id.toString()).setErrors({ min: { actual: quantity, min: composicionSelect } })

    return;
  }

  guardarSinCadena() {
    let selects = [];
    this.tela.controls['composiciones'].value.forEach(element => {
      selects.push({ hilado: element.hilado, composicion: element.composicion, clave: this.form.get(element.hilado.id.toString()).value })
    });

    this.messageService.add({
      severity: 'success',
      summary: 'Guardado',
    });

    this.dialogRef.close({ claves: selects, tela: this.tela, index: this.index, sinClave: true });
  }

  sendForm() {
    let selects = [];
    this.tela.controls['composiciones'].value.forEach(element => {
      if (this.form.get(element.hilado.id.toString()) && this.form.get(element.hilado.id.toString()).value) {
        selects.push({ composicion: element.composicion, clave: this.form.get(element.hilado.id.toString()).value })
      }
    });

    this.messageService.add({
      severity: 'success',
      summary: 'Guardado',
    });

    let status = "";

    if (selects.length > 0 && selects.length != this.tela.controls['composiciones'].value.length) {
      status = "CADENA-PLANO-PARCIALMENTE-ASIGNADO"
    } else if (selects.length <= 0) {
      status = "CADENA-PLANO-NO-ASIGNADO"
    } else if (selects.length > 0 && selects.length == this.tela.controls['composiciones'].value.length) {
      status = "CADENA-PLANO-ASIGNADO"
    }

    this.dialogRef.close({
      success: true,
      claves: selects,
      tela: this.tela,
      index: this.index,
      sinClave: selects.length != this.tela.controls['composiciones'].value.length,
      status
    });
  }


}
