import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Method } from 'src/app/shared/filters/enum/method.enum';
import { FilterService } from 'src/app/shared/filters/services/filter.service';
import { ServerResponse } from 'src/app/shared/models/model-forms/server.response.interface';
import { environment } from 'src/environments/environment.development';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private http:HttpClient,private filterService:FilterService) { }

  getColors(): Observable<ServerResponse<any>> {
    let headers = new HttpHeaders();

    headers = headers.append(
      'Authorization',
      "Bearer " + localStorage.getItem('token')
    );

    return this.http.get(`${environment.apiUrl}/colores/lista`, {headers : headers})
    .pipe (
      map((res:any)=>{
        return res
      })
    )
  }


  getRemitos(): Observable<ServerResponse<any>> {
    let headers = new HttpHeaders();

    headers = headers.append(
      'Authorization',
      "Bearer " + localStorage.getItem('token')
    );

    return this.http.get(`${environment.apiUrl}/colores/lista`, {headers : headers})
    .pipe (
      map((res:any)=>{
        return res
      })
    )
  }

  getClientes(): Observable<any> {
    let headers = new HttpHeaders();

    headers = headers.append(
      'Authorization',
      "Bearer " + localStorage.getItem('token')
    );

    return this.http.get(`${environment.apiUrl}/pedidos/cliente/lista`, {headers : headers})
    .pipe (
      map((res:any)=>{
        return res
      })
    )
  }

  getTelas(): Observable<ServerResponse<any>> {
    let headers = new HttpHeaders();

    headers = headers.append(
      'Authorization',
      "Bearer " + localStorage.getItem('token')
    );

    return this.http.get(`${environment.apiUrl}/tela/lista`, {headers : headers})
    .pipe (
      map((res:any)=>{
        return res
      })
    )
  }

  getTelasSinIndicacion(): Observable<ServerResponse<any>> {
    let headers = new HttpHeaders();

    headers = headers.append(
      'Authorization',
      "Bearer " + localStorage.getItem('token')
    );

    return this.http.get(`${environment.apiUrl}/tela/lista-limpia`, {headers : headers})
    .pipe (
      map((res:any)=>{
        return res
      })
    )
  }

  getArticulos(): Observable<ServerResponse<any>> {
    let headers = new HttpHeaders();

    headers = headers.append(
      'Authorization',
      "Bearer " + localStorage.getItem('token')
    );

    return this.http.get(`${environment.apiUrl}/articulos/lista`, {headers : headers})
    .pipe (
      map((res:any)=>{
        return res
      })
    )
  }


  getArticulosUrdido(): Observable<ServerResponse<any>> {
    let headers = new HttpHeaders();

    headers = headers.append(
      'Authorization',
      "Bearer " + localStorage.getItem('token')
    );

    return this.http.get(`${environment.apiUrl}/articulos/urdido`, {headers : headers})
    .pipe (
      map((res:any)=>{
        return res
      })
    )
  }

  getDepositos(query:any = [], methodSearch:Method = Method.GET): Observable<ServerResponse<any>> {
    let headers = new HttpHeaders();

    headers = headers.append(
      'Authorization',
      "Bearer " + localStorage.getItem('token')
    );

    let filtersAccept = ["id","nombre",]
    
    let queryBuild = methodSearch === Method.POST ? this.filterService.getFiltersForPost(query,filtersAccept)
                    : this.filterService.getFiltersForGet(query,filtersAccept)

    return this.http.get(`${environment.apiUrl}/deposito/lista?${queryBuild}`, {headers : headers})
    .pipe (
      map((res:any)=>{
        return res
      })
    )
  }

  getHiladosTejidos(): Observable<ServerResponse<any>> {
    let headers = new HttpHeaders();

    headers = headers.append(
      'Authorization',
      "Bearer " + localStorage.getItem('token')
    );

    return this.http.get(`${environment.apiUrl}/hilado-tejido/lista`, {headers : headers})
    .pipe (
      map((res:any)=>{
        return res
      })
    )
    }

  getHiladosUrdidos(): Observable<ServerResponse<any>> {
    let headers = new HttpHeaders();

    headers = headers.append(
      'Authorization',
      "Bearer " + localStorage.getItem('token')
    );

    return this.http.get(`${environment.apiUrl}/hilado-urdido/lista`, {headers : headers})
    .pipe (
      map((res:any)=>{
        return res
      })
    )
  }

}
