import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Method } from 'src/app/shared/filters/enum/method.enum';
import { FilterService } from 'src/app/shared/filters/services/filter.service';
import { ServerResponse } from 'src/app/shared/models/model-forms/server.response.interface';
import { Order } from 'src/app/shared/models/order/order.class';
import { environment } from 'src/environments/environment.development';

@Injectable({
  providedIn: 'root'
})
export class ProductionService {

  constructor(private http:HttpClient,private filterService:FilterService) { }

  getProductionPlan(query:any = [],methodSearch:Method = Method.GET):Observable<ServerResponse<Order>>{

    let filtersAccept = ["telaId","colorId","maquinaId","inicioFechaDeEntrega","finFechaDeEntrega","cliente"]

    let queryBuild = methodSearch === Method.POST ? this.filterService.getFiltersForPost(query,filtersAccept)
                    : this.filterService.getFiltersForGet(query,filtersAccept)

    let headers = new HttpHeaders()

    headers = headers.append(
      'Authorization',
      "Bearer " + localStorage.getItem('token')
    );  

    return this.http.get(`${environment.apiUrl}/pedidos/tintoreria?${queryBuild}`, {headers : headers})
    .pipe (
      map((res:any)=>{
        return res
      })
    )
  }
}
