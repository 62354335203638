<app-banner [sizeTitleClass]="'h6'" [title]="'Planificación de tintorería'" [backgroundName]="" [fontSize]="1"
  [routerBack]="'/production'"></app-banner>

<div class="container-fluid mt-3" *ngIf="!spinner">
  <div class="row">
    <div class="col-10">
      <app-filters [filters]="filters" (eventFilterEmitter)="searchFilter($event)"></app-filters>
    </div>
    <!-- <div class="col-auto">
        <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success mr-2" pTooltip="Descargar Excel" tooltipPosition="bottom"></button>
      </div> -->
  </div>
  <div class="row mt-3">
    <div class="col-12">
      <app-table-production-cleaning-planning (eventModalAddOrder)="getProductionPlan()" [size]="size"
        [orders]="orders">
      </app-table-production-cleaning-planning>
    </div>
  </div>
</div>

<div class="card text-center" *ngIf="spinner">
  <span>
    <p-progressSpinner></p-progressSpinner>
  </span>
  <br />
  <span class="text-center">Cargando...</span>
</div>