<div class="card text-center" *ngIf="spinner">
  <span>
    <p-progressSpinner></p-progressSpinner>
  </span>
  <br />
  <span class="text-center">Cargando...</span>
</div>

<div *ngIf="order && !spinner">
  <div class="row text-center">
    <div class="col">
      <h3 class="text-secondary">Asignar stock</h3>
    </div>
  </div>
  <div class="row text-center mt-3">
    <div class="col">
      <p-table [value]="[order]" dataKey="id" [tableStyle]="{ 'min-width': '100%' }" styleClass="p-datatable-sm"
        class="" selectionMode="single">
        <ng-template pTemplate="header">
          <tr>

            <th style="width : 25%" class="td m-0 p-0">
              Cliente
            </th>
            <th style="width : 25%" class="td m-0 p-0">
              Articulo
            </th>
            <th style="width : 25%" class="td m-0 p-0">
              Color
            </th>
            <th style="width : 25%" class="td m-0 p-0">
              Cantidad
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-order>
          <tr>
            <td class="td truncate m-0 p-0 fw-bold">
              {{order.cliente}}
            </td>
            <td class="td truncate m-0 p-0">
              {{order.tela}}
            </td>
            <td class="td truncate m-0 p-0">
              {{order.color}}
            </td>
            <td class="td truncate m-0 p-0 fw-bold">
              {{order.cantidad}}
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="loadingbody" let-rowData>
          <tr class="h-100 w-100">
            <td colspan="13">
              <div class="text-center">
                <span>
                  <p-progressSpinner></p-progressSpinner>
                </span>
                <br />
                <span class="text-center">Cargando...</span>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" class="w-100">
          <tr>
            <td colspan="4">
              <p-messages class="w-100" [value]="[{ severity: 'info', detail: 'No hay datos' }]" [enableService]="false"
                [closable]="false">
              </p-messages>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
  <div class="row text-center mt-3">
    <div class="col">
      <h3 class="text-secondary">Stock existente</h3>
    </div>
  </div>
  <div class="row text-center">
    <p-table [value]="depositsOriginal || []" dataKey="id" [tableStyle]="{ 'min-width': '100%' }"
      styleClass="p-datatable-sm" class="" selectionMode="single">
      <ng-template pTemplate="header">
        <tr>
          <th style="width : 50%" class="td m-0 p-0">
            Deposito
          </th>
          <th style="width : 50%" class="td m-0 p-0">
            Cantidad
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-deposit>
        <tr>
          <td class="td truncate m-0 p-0 fw-bold">
            <p class="fw-bold">
              {{deposit.deposito.nombre}}
            </p>
          </td>
          <td class="td truncate m-0 p-0">
            <p>
              <b [ngClass]="{
              'text-danger':
                deposit?.cantidad < order?.cantidad,
              'text-success':
               deposit?.cantidad >= order?.cantidad
            }">{{ deposit?.cantidad | number }}</b>
            </p>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="loadingbody" let-rowData>
        <tr class="h-100 w-100">
          <td colspan="13">
            <div class="text-center">
              <span>
                <p-progressSpinner></p-progressSpinner>
              </span>
              <br />
              <span class="text-center">Cargando...</span>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" class="w-100">
        <tr>
          <td colspan="2">
            <p-messages class="w-100" [value]="[{ severity: 'info', detail: 'No hay stock' }]" [enableService]="false"
              [closable]="false">
            </p-messages>
          </td>
        </tr>
      </ng-template>
    </p-table>

  </div>
  <!-- <form [formGroup]="form" (ngSubmit)="sendForm()">
    <div class="row justify-content-center">
      <div class="col-12 col-md-4 mx-md-3">
        <p-multiSelect
          appendTo="body"
          [style]="{ width: '100%' }"
          formControlName="deposito"
          id="deposits"
          placeholder="Seleccione una opción..."
          [options]="depositsOriginal"
          defaultLabel="Seleccione una opción..."
          optionLabel="deposito.nombre"
        >
          <ng-template let-option pTemplate="item">
            <span [class]="'deposito-' + option.id">
              {{ option.deposito.nombre }}
            </span>
          </ng-template>
        </p-multiSelect>
        <span
        class="danger"
        *ngIf="
          form.get('deposito').hasError('required') &&
          form.get('deposito').touched
        "
      >
        <small class="p-error"
          >Este campo es <strong>requerido</strong></small
        >
      </span>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col text-end">
        <p-button
          label="Guardar"
          styleClass="p-button p-button-secondary p-button-rounded assing-deposit-button"
          type="submit"
          [disabled]="form.invalid"
        >
        </p-button>
      </div>
    </div>
  </form> -->

  <form [formGroup]="form" (ngSubmit)="sendForm()">
    <div formArrayName="items">
      <div class="row justify-content-center mt-4" *ngFor="
    let item of getItemsArrayForm.controls;
    let i = index
  " [formGroupName]="i">
        <div class="col-1 text-end order-3 order-md-1">
          <br />
          <p-button pTooltip="Añadir" (click)="addItemForm()" styleClass="p-button-rounded button-add-remito-cleaning"
            *ngIf="i == 0" icon="pi pi-plus" tooltipPosition="top"></p-button>
          <p-button pTooltip="Eliminar" (click)="deleteItemForm(i)"
            styleClass="p-button-rounded button-add-remito-cleaning" *ngIf="i > 0" icon="pi pi-trash"
            tooltipPosition="top"></p-button>
        </div>
        <div class="col-12 col-md-4 order-2 order-md-2">
          <label for="deposito" class="text-dark">Deposito</label>
          <!-- <p-dropdown appDepositosDisponibles 
            (onChange)="quantityInputForm($event,i)" appendTo="body" [style]="{ width: '100%' }"
            formControlName="deposito" id="deposits" placeholder="Seleccione una opción..." 
            defaultLabel="Seleccione una opción..." optionLabel="deposito.nombre">
            <ng-template let-option pTemplate="item">
              <span [class]="'deposito-' + option.id">
                {{ option.deposito.nombre }}
              </span>
            </ng-template>
          </p-dropdown> -->
          <p-dropdown [options]="item.get('depositos').value" (onChange)="quantityInputForm($event,i)" appendTo="body"
            [style]="{ width: '100%' }" formControlName="deposito" id="deposits" placeholder="Seleccione una opción..."
            defaultLabel="Seleccione una opción..." optionLabel="deposito.nombre">
            <ng-template let-option pTemplate="item">
              <span [class]="'deposito-' + option.id">
                {{ option.deposito.nombre }}
              </span>
            </ng-template>
          </p-dropdown>

          <span class="danger" *ngIf="
            item.get('deposito').hasError('required') &&
            item.get('deposito').touched
          ">
            <small class="p-error">Este campo es <strong>requerido</strong></small>
          </span>
        </div>
        <div class="col-12 col-md-4 order-1 order-md-3">
          <label for="quantity" class="">Cantidad </label><br />
          <input (input)="totalAssign()" min="1" type="number" formControlName="cantidad" pInputText
            [style]="{ width: '100%'}" />
          <br />
          <span class="danger" *ngIf="
            item.get('cantidad').hasError('required') &&
            item.get('cantidad').touched
          ">
            <small class="p-error">Este campo es <strong>requerido</strong></small>
          </span>
        </div>
      </div>
      <div class="row mt-5 text-center">
        <div class="col">
          <p class="text-center text-dark fw-bold border d-inline p-1">
            Total : {{totalAssign()}}
          </p>
        </div>
      </div>
      <!-- <div class="row mt-3">
        <div class="offset-0 col-12 offset-md-2 col-md-10">
          <span class="danger" *ngIf="form.hasError('totalExceeded') && form.touched">
            <small class="p-error">No puede superar la cantidad <strong>original</strong></small>
          </span>
          <span class="danger" *ngIf="form.hasError('totalMissing') && form.touched">
            <small class="p-error">El conjunto de números no puede ser menor a la
              <strong>original</strong></small>
          </span>
        </div>
      </div> -->
    </div>
    <div class="row mt-3">
      <div class="col text-end">
        <p-button icon="pi pi-trash" *ngIf="order.stockAsignado.length > 0" label="Eliminar todos" styleClass="p-button p-button-secondary p-button-rounded assing-deposit-button"
          type="button" (onClick)="sendForm([])">
        </p-button>
        <p-button label="Guardar" styleClass="p-button p-button-secondary p-button-rounded assing-deposit-button mx-2"
          type="submit" [disabled]="form.invalid">
        </p-button>
      </div>
    </div>
  </form>
</div>