import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[appOrdenarPorNombre]'
})
export class OrdenarPorNombreDirective implements OnChanges {

    @Input() depositos: any[] = []; // Suponiendo que este es tu array de depósitos

    ngOnChanges(changes: SimpleChanges) {
        this.ordenarDepositosPorNombre();
    }

    private ordenarDepositosPorNombre() {
        this.depositos.sort((a, b) => {
            const nombreA = a.deposito.nombre.toUpperCase();
            const nombreB = b.deposito.nombre.toUpperCase();
            if (nombreA < nombreB) {
                return -1;
            }
            if (nombreA > nombreB) {
                return 1;
            }
            return 0;
        });
    }
}
