import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, CanMatchFn, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable, catchError, map, of } from 'rxjs';
import { AuthService } from 'src/app/api/services/auth/auth.service';

const isAuthenticated = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> => {
  const authService = inject(AuthService);
  const router = inject(Router);

  let user = localStorage.getItem('token');
  if (user) {
    return authService.validateToken(user).pipe(
      map((isValid: boolean) => {
        if (isValid) {
          return true; // El token es válido, permitir acceso
        } else {
          return router.createUrlTree(['auth/login']); // Redirigir a la página de login
        }
      }),
      catchError((error: any) => {
        console.error("Error en la solicitud:", error); // Manejar errores de la solicitud
        localStorage.removeItem('token')
        return of(router.createUrlTree(['auth/login'])); // Redirigir a la página de login en caso de error
      })
    );
  } else {
    return of(router.createUrlTree(['auth/login'])); // No hay token, redirigir a la página de login
  }
};

const isAuthenticatedMatch = (route: ActivatedRouteSnapshot, segments: UrlSegment[]): | boolean | UrlTree => {
  const authService = inject(AuthService);
  const router = inject(Router);

  let user = localStorage.getItem('token');
  if (user) {
    return true;
  }
  return false || router.createUrlTree(['auth/login'])
}


export const canActivateAuth: CanActivateFn = isAuthenticated; //si retorna true, deja pasar, si retorna false, niega la url y redirige
export const canMatch: CanMatchFn = isAuthenticatedMatch; // si retorna true carga el modulo y sus hijos, en caso de que no, redirig
//es como un lazy load

