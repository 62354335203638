<app-banner [sizeTitleClass]="'h6'" [title]="'Crear tela'" [backgroundName]=""
    [fontSize]="1" [routerBack]="'/boms/fabrics/'"></app-banner>

<div class="card text-center" *ngIf="spinner">
  <span>
      <p-progressSpinner></p-progressSpinner>
  </span>
  <br />
  <span class="text-center">Cargando...</span>
</div>


<div class="container-fluid mt-1" *ngIf="!spinner">
  <div class="row">
    <div class="col-12">
      <app-form-fabric [fabric]="fabric" (eventUser)="createFabric($event)"></app-form-fabric>
    </div>
  </div>
</div>