import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListMachineComponent } from './pages/list-machine/list-machine.component';
import { CreateMachineComponent } from './pages/create-machine/create-machine.component';
import { UpdateMachineComponent } from './pages/update-machine/update-machine.component';
import { DetailMachineComponent } from './pages/detail-machine/detail-machine.component';

const routes: Routes = [
  {
    path: '',
    children: [
      { path: '', component: ListMachineComponent },
      { path: 'list-machine', component: ListMachineComponent },
      { path: 'create-machine', component: CreateMachineComponent },
      { path: 'update-machine', component: UpdateMachineComponent },
      { path: 'detail-machine', component: DetailMachineComponent },
      {
        path: 'lock',
        loadChildren: () => import('./../machines-locks/machines-lock.module').then(m => m.MachinesLockModule)
      },
      { path: '**', redirectTo: '' }
    ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MachineRoutingModule { }
