import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, of } from 'rxjs';
import { Machine } from 'src/app/shared/models/machine/machine.class';
import { ServerResponse } from 'src/app/shared/models/model-forms/server.response.interface';
import { environment } from 'src/environments/environment.development';

@Injectable({
  providedIn: 'root',
})
export class MachineService {
  machine: Machine = {
    id: '1',
    nombre: 'ATM',
    nameDeposit: 'PIGUE',
    colors: ['Hueso', 'Negro'],
    fabric: ['GIRONA', 'MALAGA'],
    kgsBath: '1',
    line: 'Plano',
    monthlyCapacity: '2',
    nameMachine: 'ATM',
    process: 'Teñido',
    quantityBath: '4',
    specifications: 'Hilado',
    unitMeasurement: 'Kgs',
    velocity: '50',
  };

  machineUrdido: Machine = {
    id: '1',
    nombre: 'ATM',
    nameDeposit: 'PETCHOL',
    colors: ['Hueso', 'Negro'],
    fabric: ['GIRONA', 'MALAGA'],
    kgsBath: '1',
    line: 'Plano',
    monthlyCapacity: '2',
    nameMachine: 'ATM',
    process: 'Teñido',
    quantityBath: '4',
    specifications: 'Hilado',
    unitMeasurement: 'Kgs',
    velocity: '50',
  };

  machineTejeduria: Machine = {
    id: '1',
    nombre: 'ATM',
    nameDeposit: 'PETCHOL',
    colors: ['Hueso', 'Negro'],
    fabric: ['GIRONA', 'MALAGA'],
    kgsBath: '1',
    line: 'Plano',
    monthlyCapacity: '2',
    nameMachine: 'ATM',
    process: 'Teñido',
    quantityBath: '4',
    specifications: 'Hilado',
    unitMeasurement: 'Kgs',
    velocity: '50',
  };

  constructor(private http: HttpClient) { }

  getMachine(): Observable<Machine> {
    return of(this.machine);
  }

  getMachines(): Observable<Machine[]> {
    return of([this.machine]);
  }

  getMachinesList(query: any = []) {
    let headers = new HttpHeaders();

    let queryBuid = "";
    query.forEach((q: any) => {
      if (q.col == "generos%5B0%5D.tela") {
        if (q.value != undefined) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "procesos%5B0%5D.nombre") {
        if (q.value != undefined) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
      if (q.col == "estado") {
        if (q.value != undefined) {
          queryBuid = queryBuid + q.col + "=" + q.value + "&";
        }
      }
    });

    headers = headers.append(
      'Authorization',
      "Bearer " + localStorage.getItem('token')
    );

    return this.http.get(`${environment.apiUrl}/maquina/lista?${queryBuid}`, { headers: headers })
      .pipe(
        map((res: any) => {
          return res
        })
      )
  }


  getDetailsMachine(id: any) {
    let headers = new HttpHeaders();

    headers = headers.append(
      'Authorization',
      "Bearer " + localStorage.getItem('token')
    );

    return this.http.get(`${environment.apiUrl}/maquina/editar/${id}`, { headers: headers })
      .pipe(
        map((res: any) => {
          return res
        })
      )
  }

  createMachine(fabric: any): Observable<ServerResponse<any>> {
    let headers = new HttpHeaders()

    headers = headers.append(
      'Authorization',
      "Bearer " + localStorage.getItem('token')
    );

    return this.http.post(`${environment.apiUrl}/maquina/nuevo`, fabric, { headers: headers })
      .pipe(
        map((res: any) => {
          return res
        })
      )
  }

  updateMachine(tela: any): Observable<ServerResponse<any>> {
    let headers = new HttpHeaders()

    headers = headers.append(
      'Authorization',
      "Bearer " + localStorage.getItem('token')
    );

    return this.http.post(`${environment.apiUrl}/maquina/guardar`, tela, { headers: headers })
      .pipe(
        map((res: any) => {
          return res
        })
      )
  }

  assignMachines(asignacionMaquinas: any): Observable<any> {
    let headers = new HttpHeaders();

    headers = headers.append(
      'Authorization',
      'Bearer ' + localStorage.getItem('token')
    );

    return this.http
      .post(
        `${environment.apiUrl}/asignacion-maquinas/asignar`,
        asignacionMaquinas,
        {
          headers: headers,
        }
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  changeTypeOrder(order: any): Observable<any> {
    let headers = new HttpHeaders();

    headers = headers.append(
      'Authorization',
      'Bearer ' + localStorage.getItem('token')
    );

    return this.http
      .post(
        `${environment.apiUrl}/ordenes/cambiar-tipo/${order.id}/${order.tipo}`,
        {},
        {
          headers: headers,
        }
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
}
