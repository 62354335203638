import { Directive, Input, OnInit } from '@angular/core';
import { Dropdown } from 'primeng/dropdown';
import { FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appDepositosDisponibles]'
})
export class DepositosDisponiblesDirective implements OnInit {

  @Input() formArray: FormArray;
  @Input() todosDepositos: string[];
  private formArraySubscription: Subscription;

  constructor(private dropdown: Dropdown) {}

  ngOnInit() {
    if (this.formArray && this.todosDepositos) {
      this.actualizarDepositosDisponibles();
      this.formArraySubscription = this.formArray.valueChanges.subscribe(() => {
        this.actualizarDepositosDisponibles();
      });
    }
  }

  ngOnDestroy() {
    if (this.formArraySubscription) {
      this.formArraySubscription.unsubscribe();
    }
  }

  private actualizarDepositosDisponibles() {
    const depositosSeleccionados:any = this.formArray.value.map(item => item.deposito);
    const depositosDisponibles = this.todosDepositos.filter(deposito => !depositosSeleccionados.includes(deposito));
    
    this.dropdown.options = depositosDisponibles.map((deposito:any) => ({
        cantidad: deposito.cantidad,
        deposito: deposito.deposito
    }));
  }
  
  
}
