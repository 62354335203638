<div class="col">
  <p-table [value]="telas" dataKey="id" [tableStyle]="{ 'min-width': '40rem' }" styleClass="p-datatable-sm" [style]="{
    'max-height': '70vh',
    'overflow-y': 'auto',
  }" [scrollable]="true" [rows]="size" [(selection)]="selectTelas" (sortFunction)="customSort($event)"
    [customSort]="true" scrollHeight="70vh" selectionMode="single">
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 4rem" class="td m-0 p-0">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th pSortableColumn="articulo" class="td m-0 p-0" style="width: 50%">
          Articulo <p-sortIcon field="articulo"></p-sortIcon>
        </th>
        <th pSortableColumn="cantidad" class="td m-0 p-0" style="width: 50%">
          Cantidad <p-sortIcon field="cantidad"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-tela>
      <tr>
        <td class="td m-0 p-0">
          <p-tableCheckbox [value]="tela"></p-tableCheckbox>
        </td>
        <td class="td m-0 p-0 truncate">{{ tela.articulo || tela.nombre }}</td>
        <td class="td m-0 p-0 truncate">{{ tela.cantidad.toFixed(2) }}</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="loadingbody" let-rowData>
      <tr class="h-100 w-100">
        <td colspan="13">
          <div class="text-center">
            <span>
              <p-progressSpinner></p-progressSpinner>
            </span>
            <br />
            <span class="text-center">Cargando...</span>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" class="w-100">
      <tr>
        <td colspan="12">
          <p-messages class="w-100" [value]="[{ severity: 'info', detail: 'No hay pedidos' }]" [enableService]="false"
            [closable]="false">
          </p-messages>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <div class="row d-flex justify-content-center my-5">
    <div class="col-12 text-end">
      <p-button type="button" styleClass="p-button-rounded p-button-sm p-button-secondary add-order-weaving padding-0"
        class="my-3 mx-3" icon="pi pi-plus" iconPos="left" label="Agregar orden"
        (click)="showModalAddQuantity()"></p-button>
      <p-button type="button" (onClick)="navigateProductionOrder()" [disabled]="selectTelas.length <= 0"
        styleClass="p-button-rounded p-button-sm p-button-secondary select-order-weaving padding-0" class="my-3 mx-3"
        icon="pi pi-arrow-right" iconPos="right" label="Asignar tejeduria/máquina"
        [loading]="loadingSelectOrders"></p-button>
    </div>
  </div>
</div>