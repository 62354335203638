import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ProcessService } from 'src/app/api/services/process/process.service';

@Component({
  selector: 'app-list-process',
  templateUrl: './list-process.component.html',
  styleUrls: ['./list-process.component.css']
})
export class ListProcessComponent {

  processes: any = [];

  size = 10;
  page = 1;
  totalElements = 0;

  spinner = true;

  constructor(private messageService: MessageService,private processService:ProcessService) {

  }

  ngOnInit(): void {
    // this.spinner = false;

    this.getProcesses(true);
  }

  getProcesses(refresh = false) {
    this.spinner = refresh;
    if (refresh) {
      this.processService.getProcessesList().subscribe({
        next: (data) => {
          this.totalElements = data.totalElements;
          this.processes = data.list;
        }, error: (err) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: err.error ? err.error.message : 'Ups! Ocurrio un error',
          });
        }, complete: () => {
          this.spinner = false;
        }
      })
    }
  }

}
