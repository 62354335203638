<div class="card text-center" *ngIf="spinner">
  <span>
    <p-progressSpinner></p-progressSpinner>
  </span>
  <br />
  <span class="text-center">Cargando...</span>
</div>

<div>
  <div class="row text-center">
    <div class="col">
      <h3 class="text-secondary">Detalle</h3>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <p-table [value]="[order]" dataKey="id" [tableStyle]="{ 'min-width': '100%' }" styleClass="p-datatable-sm"
        class="" selectionMode="single">
        <ng-template pTemplate="header">
          <tr>

            <th style="width : 25%" class="td m-0 p-0">
              Cliente
            </th>
            <th style="width : 25%" class="td m-0 p-0">
              Articulo
            </th>
            <th style="width : 25%" class="td m-0 p-0">
              Color
            </th>
            <th style="width : 25%" class="td m-0 p-0">
              Cantidad
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-order>
          <tr>
            <td class="td truncate m-0 p-0 fw-bold">
              {{order.cliente}}
            </td>
            <td class="td truncate m-0 p-0">
              {{order.tela}}
            </td>
            <td class="td truncate m-0 p-0">
              {{order.color}}
            </td>
            <td class="td truncate m-0 p-0 fw-bold">
              {{order.cantidad}}
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="loadingbody" let-rowData>
          <tr class="h-100 w-100">
            <td colspan="13">
              <div class="text-center">
                <span>
                  <p-progressSpinner></p-progressSpinner>
                </span>
                <br />
                <span class="text-center">Cargando...</span>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" class="w-100">
          <tr>
            <td colspan="4">
              <p-messages class="w-100" [value]="[{ severity: 'info', detail: 'No hay datos' }]" [enableService]="false"
                [closable]="false">
              </p-messages>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

  <div class="row text-center mt-2">
    <div class="col">
      <h3 class="text-secondary">Asignado</h3>
    </div>
  </div>
  <div>
    <div class="row">
      <div class="col">
        <p-table [value]="order.stockAsignado || []" dataKey="id" [tableStyle]="{ 'min-width': '100%' }"
          styleClass="p-datatable-sm" class="" selectionMode="single">
          <ng-template pTemplate="header">
            <tr>

              <th style="width : 33,33%" class="td m-0 p-0">
                Depósito
              </th>
              <th style="width : 33,33%" class="td m-0 p-0">
                Cantidad
              </th>
              <th style="width : 33,33%" class="td m-0 p-0">
                Total
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-assing>
            <tr>
              <td class="td truncate m-0 p-0 fw-bold">
                {{assing.deposito}}
              </td>
              <td class="td truncate m-0 p-0">
                {{assing.cantidad.toFixed(2)}}
              </td>
              <td class="td truncate m-0 p-0">
                {{totalDepositoCantidad(order.stockAsignado).toFixed(2)}}
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="loadingbody" let-rowData>
            <tr class="h-100 w-100">
              <td colspan="13">
                <div class="text-center">
                  <span>
                    <p-progressSpinner></p-progressSpinner>
                  </span>
                  <br />
                  <span class="text-center">Cargando...</span>
                </div>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" class="w-100">
            <tr>
              <td colspan="3">
                <p-messages class="w-100" [value]="[{ severity: 'info', detail: 'No hay asignaciones' }]"
                  [enableService]="false" [closable]="false">
                </p-messages>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
  <div class="mt-2">
    <div class="row text-center">
      <div class="col">
        <h3 class="text-secondary">Historial</h3>
      </div>
    </div>
    <div class="row d-flex justify-content-center">
      <div class="col-12">
          <p-table [value]="order.fechaPrometidaHistorial" dataKey="id" [tableStyle]="{ 'width': '100%'}"
            styleClass="p-datatable-sm" selectionMode="single">
            <ng-template pTemplate="header">
              <tr>
                <th style="width : 50%" class="td m-0 p-0">
                  Fecha prometida
                </th>
                <th style="width : 50%" class="td m-0 p-0">
                  Observaciones
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-data>
              <tr>
                <td class="td truncate m-0 p-0 fw-bold">
                  {{(data.fechaPrometida | date : "dd/MM/yyyy" || '----')}}
                </td>
                <td class="td truncate m-0 p-0">
                  {{data.observaciones || '----'}}
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="loadingbody" let-rowData>
              <tr class="h-100 w-100">
                <td colspan="13">
                  <div class="text-center">
                    <span>
                      <p-progressSpinner></p-progressSpinner>
                    </span>
                    <br />
                    <span class="text-center">Cargando...</span>
                  </div>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" class="w-100">
              <tr>
                <td colspan="2">
                  <p-messages class="w-100" [value]="[{ severity: 'info', detail: 'No hay historial' }]"
                    [enableService]="false" [closable]="false">
                  </p-messages>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
  <div class="row text-center mt-3">
    <div class="col-1"></div>
    <div class="col text-end">
      <p-button (onClick)="sendDeposits()" label="Guardar"
        styleClass="p-button p-button-secondary p-button-rounded assing-deposit-button" type="button">
      </p-button>
    </div>
  </div>