<app-banner [sizeTitleClass]="'h6'" [title]="'Detalles ' + fabric?.nombre" [backgroundName]=""
    [fontSize]="1" [routerBack]="'/boms/fabrics/'"></app-banner>

<div class="card text-center" *ngIf="spinner">
    <span>
      <p-progressSpinner></p-progressSpinner>
    </span>
    <br />
    <span class="text-center">Cargando...</span>
  </div>
  
  <div>
    <div class="row text-center">
      <div class="col">
        <h3 class="text-secondary">Detalles</h3>
      </div>
    </div>
    <div class="row text-center mt-1">
      <div class="col">
        <p class="text-dark fw-bold">Nombre: <span>{{ fabric.nombre }}</span></p>
      </div>
    </div>
  </div>