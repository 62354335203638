import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProductionCleaningPlanningComponent } from './pages/production-cleaning-planning/production-cleaning-planning.component';
import { ProductionCleaningPlanningOrderComponent } from './pages/production-cleaning-planning-order/production-cleaning-planning-order.component';
const routes: Routes = [
  {
    path: '',
    children: [
      { path: '', component: ProductionCleaningPlanningComponent },
      { path: 'cleaning', component: ProductionCleaningPlanningComponent },
      {
        path: 'order-planning',
        component: ProductionCleaningPlanningOrderComponent,
      },
      { path: '**', redirectTo: '' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProductionCleaningRoutingModule {}
