import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent {

  @Input()
  title:string = "";

  @Input()
  sizeTitleClass = "";

  @Input()
  backgroundName = "";

  @Input()
  fontSize:number = 5

  @Input()
  routerBack = "";

}
