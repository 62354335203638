import { DatePipe } from '@angular/common';
import { Component, DoCheck, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { concat, concatMap, of, tap } from 'rxjs';
import { DataService } from 'src/app/api/services/data/data.service';
import { OrderService } from 'src/app/api/services/order/order.service';
import { uniqueComponentNumberValidator } from 'src/app/shared/validators/repeatNumberComponentWeaving';

@Component({
  selector: 'app-form-fabric',
  templateUrl: './form-fabric.component.html',
  styleUrls: ['./form-fabric.component.css']
})
export class FormFabricComponent implements OnInit, DoCheck {

  spinner = true;

  @Input()
  fabric: any = {};

  form: FormGroup;

  @Output()
  eventUser: EventEmitter<any> = new EventEmitter();

  title: any = "Crear tela"

  compositions: any = [
    { label: "POLIESTER", value: "POLIESTER" },
    { label: "ELASTANO", value: "ELASTANO" },
    { label: "ALGODÓN", value: "ALGODON" },
    { label: "POLIAMIDA", value: "POLIAMIDA" },
  ]

  unitOfMeasurement: any = [
    { label: "KILO", value: "KILO" },
    { label: "METRO", value: "METRO" },
  ]

  lines: any = [
    { label: "CIRCULAR", value: "CIRCULAR" },
    { label: "PLANO", value: "PLANO" },
    { label: "KETTEN", value: "KETTEN" },
  ]

  colors: any = [
    { label: "BLANCO", value: "BLANCO" },
    { label: "BLANCO/HUESO", value: "BLANCO/HUESO" },
    { label: "COLORES", value: "COLORES" },
  ]

  numeroComponente: any = [
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3,},
  ]

  processes: any = [
    { label: "Tejido", value: "Tejido" },
    { label: "Terminacion", value: "Terminacion" },
    { label: "Lavado", value: "Lavado" },
    { label: "Urdido", value: "Urdido" },
    { label: "Teñido", value: "Teñido" },
    { label: "Imper", value: "Imper" },
    { label: "Resina", value: "Resina" },
    { label: "Frisado", value: "Frisado" },
  ]

  hiladoTejido: any = []
  hiladoUrdido: any = []

  constructor(
    public messageService: MessageService,
    public dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
    private orderService: OrderService,
    private dataService: DataService,
    private datepipe: DatePipe
  ) { }
  ngDoCheck(): void {
  }

  ngOnInit(): void {
    if (this.fabric) {
      this.title = "Actualizar tela"
    }

    this.getData();
    this.spinner = false;
    console.log(this.fabric);
    
  }

  ngOnDestroy(): void {
    this.spinner = true;
  }

  getData() {
    this.dataService.getHiladosTejidos().pipe(
      tap({
        next: (data) => {
          this.hiladoTejido = data.list;
        },
        error: (err) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: err.error ? err.error.message : 'Ups! Ocurrió un error',
          });
        },
        complete: () => {
          this.hiladoTejido.unshift({ nombre: "Otro", id: "Otro" })
        }
      }),
      // concatMap(() => this.getDeposits()),
      concatMap(() => this.getHiladoUrdido()),
      concatMap(() => concat(of(this.startForm()))),
    ).subscribe();
  }

  getHiladoUrdido() {
    return this.dataService.getHiladosUrdidos().pipe(tap({
      next: (data) => {
        this.hiladoUrdido = data.list;
      }, error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: err.error ? err.error.message : 'Ups! Ocurrio un error',
        });
      },
      complete: () => {
        this.hiladoUrdido.unshift({ nombre: "Otro", id: "Otro" })
        this.spinner = false;
      }
    }))
  }

  startForm() {
    this.form = new FormGroup({
      id: new FormControl(this.fabric?.id || '', []),
      codigo: new FormControl(this.fabric?.codigo || "", []),
      codigoPigue: new FormControl(this.fabric?.codigoPigue || "", []),
      coeficienteCruda: new FormControl(this.fabric?.coeficienteCruda || "", []),
      coeficienteTerminada: new FormControl(this.fabric?.coeficienteTerminada || "", []),
      composicion: new FormControl(this.fabric?.composicion || "", [Validators.required]),                                                    //para tejido uniqueComponentNumberValidator()
      hiladoTejido: new FormArray(this.fabric?.hiladosTejido && this.fabric?.hiladosTejido.length > 0 ? [] : [this.createHiladoTejidoItem()], []),
      hiladoUrdido: new FormArray(this.fabric?.hiladosUrdido && this.fabric?.hiladosUrdido.length > 0 ? [] : [this.createHiladoUrdidoItem()], []),
      detalle: new FormControl(this.fabric?.detalle || "", []),
      linea: new FormControl(this.fabric?.linea || "", []),
      nombre: new FormControl(this.fabric?.nombre || "", [Validators.required]),
      tamanioDeLaPieza: new FormControl(this.fabric?.tamanioDeLaPieza || "", []),
      unidadDeMedida: new FormControl(this.fabric?.unidadDeMedida || "", []),
      colores: new FormArray(this.fabric?.procesos && this.fabric?.procesos.length > 0 ? [] : [this.createColorItem()], []),
    });



    this.setDataHiladoTejido();
    this.setDataHiladoUrdido();
    this.setDataColors();
  }

  setDataHiladoTejido() {
    this.fabric?.hiladosTejido.forEach(element => {
      this.addHiladoTejidoItem(element)
    });
  }

  setDataHiladoUrdido() {
    this.fabric?.hiladosUrdido.forEach(element => {
      this.addHiladoUrdidoItem(element)
    });
  }

  setDataColors() {
    this.fabric?.procesos.forEach((element) => {
      // Check if the color already exists in the form array
      const existingColorIndex = this.getColorsArray.controls.findIndex(control => control.get('color')?.value === element.proceso.colores);

      if (existingColorIndex === -1) {
        // If the color does not exist, add a new FormGroup to the FormArray
        let item = this.addColorItem(element.proceso);
        let processesArray = item.get('procesos') as FormArray;
        processesArray.push(this.createProcessItem(element.proceso));
      } else {
        // If the color already exists, add a new process to the existing FormGroup
        const processesArray = this.getColorsArray.at(existingColorIndex).get('procesos') as FormArray;
        processesArray.push(this.createProcessItem(element.proceso));
      }


    });
  }


  createColorItem(proceso?: any) {
    return new FormGroup({
      color: new FormControl(proceso?.colores || "", []),
      procesos: new FormArray(proceso?.nombre ? [] : [this.createProcessItem()], []),
    });
  }

  get getColorsArray(): FormArray {
    return this.form.get('colores') as FormArray;
  }

  addColorItem(proceso?: any) {
    let item = this.createColorItem(proceso)
    this.getColorsArray.push(item);
    return item;
  }

  deleteColorItem(index: any) {
    this.getColorsArray.removeAt(index);
  }

  createProcessItem(proceso?: any, index?: any) {
    return new FormGroup({
      proceso: new FormControl(proceso?.nombre || "", []),
      id: new FormControl(proceso?.id || "", []),
    });
  }

  getProcessesArray(index: any): FormArray {
    return this.getColorsArray.controls[index].get('procesos') as FormArray;
  }

  addProcessItem(index?: any) {
    this.getProcessesArray(index).push(this.createProcessItem(null, index));
  }

  deleteProcessItem(indexColor: any, indexProcess) {
    this.getProcessesArray(indexColor).removeAt(indexProcess);
  }

  createHiladoTejidoItem(hiladoTejido?: any) {
    console.log(hiladoTejido);
    
    let find = this.findHiladoTejido(hiladoTejido);

    let form = new FormGroup({
      exist: new FormControl(find ? {value : find, disabled : true} :
        { id: 'Otro', nombre: 'Otro' }, []),
      id: new FormControl(find ? hiladoTejido?.id : "", []),
      codigo: new FormControl(!find ? {value : hiladoTejido?.codigo, disabled : true} : "", []),
      nombre: new FormControl(!find ? {value : hiladoTejido?.nombre, disabled : true}  : "", []),
      pesoPorBob: new FormControl(       hiladoTejido?.pesoPorBob         ? {value : hiladoTejido?.pesoPorBob , disabled : true}  : "", []),
      porcentaje: new FormControl(       hiladoTejido?.porcentaje         ? {value : hiladoTejido?.porcentaje, disabled : true} : "", []),
      numeroComponente: new FormControl( hiladoTejido?.numeroDeComponente ? {value : hiladoTejido?.numeroDeComponente, disabled : true} : "", []),
      kgXm: new FormControl(             hiladoTejido?.kgXm               ? {value :hiladoTejido?.kgXm, disabled : true} : "", []),
      consumoXMetro: new FormControl(    hiladoTejido?.consumoXMetro      ? {value :hiladoTejido?.consumoXMetro, disabled : true} : "", []),
    });

    // this.setEventFormHiladoTejido('codigo', form)
    // this.setEventFormHiladoTejido('nombre', form)
    // this.setEventFormHiladoTejido('pesoPorBob', form)
    // this.setEventFormHiladoTejido('porcentaje', form)
    return form;
  }

  changeTejidoAll(event:any){
    this.getHiladoTejidoArray.controls.forEach((hilado:FormGroup) => {
      hilado.get('codigo').setValue('')
      hilado.get('nombre').setValue('')
      hilado.get('pesoPorBob').setValue('')
      hilado.get('porcentaje').setValue('')
      hilado.get('numeroComponente').setValue('')
      hilado.get('kgXm').setValue('')
      hilado.get('consumoXMetro').setValue('')
    });
    
  }

  changeHilado(hilado:any){
    hilado.get('codigo').setValue('')
    hilado.get('nombre').setValue('')
    hilado.get('pesoPorBob').setValue('')
    hilado.get('porcentaje').setValue('')
    hilado.get('numeroComponente').setValue('')
    hilado.get('kgXm').setValue('')
    hilado.get('consumoXMetro').setValue('')
  }


  findHiladoTejido(hiladoTejido: any) {
    let find = null;
    if (hiladoTejido) {
      find = this.hiladoTejido.find((element) => {
        return hiladoTejido?.idHilado == element?.idHilado
      })
    }
    return find;
  }

  setEventFormHiladoTejido(controlName: any, form: FormGroup) {
    form.get(controlName).valueChanges.subscribe({
      next: (selectedValue) => {
        if (
          form.get('codigo').value
          || form.get('nombre').value
          || form.get('pesoPorBob').value
          || form.get('porcentaje').value
        ) {
          form.get('porcentaje').setValidators([Validators.required])
          if (controlName != 'porcentaje' && !form.get('porcentaje').value) {
            form.get('porcentaje').setValue("")
          }
        } else {
          form.get('porcentaje').setErrors(null)
          form.get('porcentaje').setValidators([])
        }

      }
    });
  }



  get getHiladoTejidoArray(): FormArray {
    return this.form.get('hiladoTejido') as FormArray;
  }

  addHiladoTejidoItem(hiladoTejido?: any) {
    this.getHiladoTejidoArray.push(this.createHiladoTejidoItem(hiladoTejido));
  }

  deleteHiladoTejidoItem(index: any) {
    this.getHiladoTejidoArray.removeAt(index);
    if(this.getHiladoTejidoArray.controls.length == 0){
      this.addHiladoTejidoItem();
    }
  }

  createHiladoUrdidoItem(hiladoUrdido?: any) {

    let find = this.findHiladoUrdido(hiladoUrdido);
    let form =
      new FormGroup({
        exist: new FormControl(find ? {value : find , disabled : true} :
          { id: 'Otro', nombre: 'Otro' }, []),
        id: new FormControl(find ? hiladoUrdido?.id : "", []),
        codigo: new FormControl(find &&  hiladoUrdido?.codigo ? {value : hiladoUrdido?.codigo, disabled : true} : "", []),
        nombre: new FormControl(find &&  hiladoUrdido?.nombre ? {value : hiladoUrdido?.nombre, disabled : true} : "", []),
        pesoPorBob: new FormControl(hiladoUrdido?.pesoPorBob ? {value : hiladoUrdido?.pesoPorBob, disabled : true} : "", []),
        numeroComponente: new FormControl(hiladoUrdido?.numeroDeComponente ? {value : hiladoUrdido?.numeroDeComponente, disabled : true} : "", []),
      });

    this.setEventFormHiladoUrdido('nombre', form)
    this.setEventFormHiladoUrdido('pesoPorBob', form)
    this.setEventFormHiladoUrdido('codigo', form)
    return form;
  }


  setEventFormHiladoUrdido(controlName: any, form: FormGroup) {

    // form.get(controlName).valueChanges.subscribe({
    //   next: (selectedValue) => {
    //     if (
    //       form.get('codigo').value
    //       || form.get('nombre').value
    //       || form.get('pesoPorBob').value
    //     ) {
    //       form.get('porcentaje').setValidators([Validators.required])
    //       if (controlName != 'porcentaje') {
    //         form.get('porcentaje').setValue("")
    //       }
    //     } else {
    //       form.get('porcentaje').setErrors(null)
    //       form.get('porcentaje').setValidators([])
    //     }

    //   }
    // });



  }

  findHiladoUrdido(hiladoUrdido: any) {
    let find = null;
    if (hiladoUrdido) {
      find = this.hiladoUrdido.find((element) => {
        return hiladoUrdido?.idHilado == element?.idHilado
      })
    }
    return find;
  }

  get getHiladoUrdidoArray(): FormArray {
    return this.form.get('hiladoUrdido') as FormArray;
  }

  addHiladoUrdidoItem(hiladoUrdido?: any) {
    this.getHiladoUrdidoArray.push(this.createHiladoUrdidoItem(hiladoUrdido));
  }

  deleteHiladoUrdidoItem(index: any) {
    this.getHiladoUrdidoArray.removeAt(index);
    if(this.getHiladoUrdidoArray.controls.length == 0){
      this.addHiladoUrdidoItem()
    }
  }

  sendForm() {
    let procesos = [];
    let tela = {
      codigo: this.form.get('codigo').value,
      codigoPigue: this.form.get('codigoPigue').value,
      coeficienteCruda: this.form.get('coeficienteCruda').value as Number,
      coeficienteTerminada: this.form.get('coeficienteTerminada').value as Number,
      composicion: this.form.get('composicion').value,
      detalle: this.form.get('detalle').value,
      linea: this.form.get('linea').value,
      nombre: this.form.get('nombre').value,
      tamanioDeLaPieza: this.form.get('tamanioDeLaPieza').value,
      unidadDeMedida: this.form.get('unidadDeMedida').value,
    };

    if (this.form.get('id').value) {
      tela['id'] = this.form.get('id').value;
    }

    let hiladoUrdido = this.getHiladoUrdidoArray.controls.map((element) => {
      const codigo = element.get('exist').value?.id != 'Otro' ? element.get('exist').value?.codigo : element.get('codigo').value;
      const nombre = element.get('exist').value?.id != 'Otro' ? element.get('exist').value?.nombre : element.get('nombre').value;
      const pesoPorBob = element.get('pesoPorBob').value;
      const numeroDeComponente = element.get('numeroComponente').value;
      const id = element.get('exist').value?.id != 'Otro' && element.get('id').value ? element.get('id')?.value : null


      // Verificar si al menos una propiedad tiene valor
      if (codigo || nombre || pesoPorBob || numeroDeComponente || id) {
        return {
          id,
          codigo: codigo,
          nombre: nombre,
          pesoPorBob: pesoPorBob,
          numeroDeComponente : numeroDeComponente
        };
      } else {
        return null; // No agregar al array si todas las propiedades son vacías
      }
    }).filter((item) => item !== null); // Filtrar para eliminar elementos nulos del array


    // Si todos los elementos son vacíos, se asigna un array vacío
    hiladoUrdido = hiladoUrdido.length > 0 ? hiladoUrdido : [];

    let hiladoTejido = this.getHiladoTejidoArray.controls.map((element) => {
      const codigo = element.get('exist').value?.id != 'Otro' ? element.get('exist').value?.codigo : element.get('codigo').value;
      const nombre = element.get('exist').value?.id != 'Otro' ? element.get('exist').value?.nombre : element.get('nombre').value;
      const pesoPorBob = element.get('pesoPorBob').value as Number;
      const porcentaje = element.get('porcentaje').value as number;
      const kgXm = element.get('kgXm').value;
      const consumoXMetro = element.get('consumoXMetro').value;
      const numeroDeComponente = element.get('numeroComponente').value as number;
      const id = element.get('exist').value?.id != 'Otro' && element.get('id').value ? element.get('id').value : null

      // Verificar si al menos una propiedad tiene valor
      if (codigo || nombre || pesoPorBob || porcentaje !== null || numeroDeComponente || id) {
        return {
          id : id,
          codigo: codigo,
          nombre: nombre,
          pesoPorBob: pesoPorBob,
          porcentaje: porcentaje,
          kgXm : kgXm,
          consumoXMetro : consumoXMetro,
          numeroDeComponente: numeroDeComponente,
        };
      } else {
        return null; // No agregar al array si todas las propiedades son vacías
      }
    }).filter((item) => item !== null); // Filtrar para eliminar elementos nulos del array

    // Si todos los elementos son vacíos, se asigna un array vacío
    hiladoTejido = hiladoTejido.length > 0 ? hiladoTejido : [];

    if (hiladoTejido.length > 0) {
      tela['hiladoTejido'] = hiladoTejido;
    }
    if (hiladoUrdido.length > 0) {
      tela['hiladoUrdido'] = hiladoUrdido;
    }

    this.getColorsArray.controls.forEach((element, index) => {
      const procesosArray = element.get('procesos').value;

      procesosArray.forEach((elementProcess, subIndex) => {
        const id = elementProcess.id || null;

        const procesoItem = {
          orden: subIndex + 1,
          proceso: {
            colores: element.get('color').value,
            nombre: elementProcess.proceso,
            orderIndex: subIndex + 1,
          },
        };

        // Verificar si al menos una propiedad tiene valor
        if (procesoItem.proceso.colores || procesoItem.proceso.nombre || id !== null) {
          // Condición para incluir la propiedad id solo si existe
          if (id) {
            procesoItem.proceso['id'] = id;
          }
          procesos.push(procesoItem);
        }
      });
    });

    // Si todos los elementos son vacíos, se asigna un array vacío
    procesos = procesos.length > 0 ? procesos : [];



    if (procesos.length > 0) {
      tela['procesos'] = procesos;
    }
    
    console.log(tela);
    

    this.eventUser.emit(tela)

  }


}
