import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProductionCleaningRoutingModule } from './production-cleaning-routing.module';
import { PublicModule } from 'src/app/public/public.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { OrderModule } from '../../order/order.module';
import { TableProductionCleaningPlanningComponent } from './components/table-production-cleaning-planning/table-production-cleaning-planning.component';
import { ProductionCleaningPlanningComponent } from './pages/production-cleaning-planning/production-cleaning-planning.component';
import { ProductionCleaningPlanningOrderComponent } from './pages/production-cleaning-planning-order/production-cleaning-planning-order.component';
import { TableProductionCleaningPlanningOrderComponent } from './components/table-production-cleaning-planning-order/table-production-cleaning-planning-order.component';
import { AssignProductionCleaningPlanningModalComponent } from './modals/assign-production-cleaning-planning-modal/assign-production-cleaning-planning-modal.component';
import { AddOrderComponent } from './modals/add-order/add-order.component';
import { AssignMachineComponent } from './modals/assign-machine/assign-machine.component';


@NgModule({
  declarations: [
    TableProductionCleaningPlanningComponent,
    ProductionCleaningPlanningComponent,
    ProductionCleaningPlanningOrderComponent, 
    TableProductionCleaningPlanningOrderComponent,
    AssignProductionCleaningPlanningModalComponent,
    AddOrderComponent,
    AssignMachineComponent,
  ],
  imports: [ 
    CommonModule,
    ProductionCleaningRoutingModule,
    OrderModule,
    SharedModule,
    PublicModule
  ]
})
export class ProductionCleaningModule { }
