<app-banner [sizeTitleClass]="'h6'" [title]="'Máquinas'" [backgroundName]=""
    [fontSize]="1" [routerBack]="'/boms'"></app-banner>

<div class="card text-center" *ngIf="spinner">
    <span>
        <p-progressSpinner></p-progressSpinner>
    </span>
    <br />
    <span class="text-center">Cargando...</span>
</div>

<div class="container-fluid mt-1" *ngIf="!spinner">
    <!-- <div class="row">
      <div class="col-11">
        <app-filters [filters]="filters"></app-filters>
      </div>
      <div class="col-auto">
        <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success mr-2" pTooltip="Descargar Excel" tooltipPosition="bottom"></button>
      </div>
    </div> -->
    <div class="row mt-1">
        <div class="col-12">
            <app-table-machine-list (eventEmitterTable)="getMachines($event)" [size]="size" [machines]="machines">
            </app-table-machine-list>
        </div>
    </div>
</div>