import {
  AfterViewInit,
  Component,
  Inject,
  Input,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmEventType, ConfirmationService, MessageService, SortEvent } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MachineService } from '../../../../../api/services/automation/machine/machine.service';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ChangeDetectorRef } from '@angular/core';
import { totalKgsPlural, totalKgsSingular } from 'src/app/shared/validators/totalKgsWeaving.validator';
import { AssignYarnWeavingKettenComponent } from '../../modals/assign-yarn-weaving-ketten/assign-yarn-weaving-ketten.component';
import { SeeDetailsProductionKettenComponent } from '../../modals/see-details-production-ketten/see-details-production-ketten.component';
import { WeavingService } from 'src/app/api/services/production/weaving/weaving.service';

@Component({
  selector: 'app-table-production-weaving-ketten',
  templateUrl: './table-production-weaving-ketten.component.html',
  styleUrls: ['./table-production-weaving-ketten.component.css']
})
export class TableProductionWeavingKettenComponent implements OnInit,AfterViewInit {

  @Input()
  telas: any = [];

  selectTelas: any[] = [];

  showPopUpAddOrder = false;
  loadingSelectOrders = false;

  @Input()
  size = 10;

  machines: any = []

  ref: DynamicDialogRef | undefined;

  form: FormGroup;

  constructor(
    private messageService: MessageService,
    private router: Router,
    private dialogService: DialogService,
    private dialogRef: DynamicDialogRef,
    private machineService: MachineService,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(ConfirmationService) private confirmationService:ConfirmationService ,
    private weavingService: WeavingService
  ) { }

  ngOnInit(): void {
    this.telas = JSON.parse(JSON.stringify(this.telas));
    this.selectTelas = [...this.telas];
    this.startForm();
  }

  ngAfterViewInit(): void {
    this.getOrderArray.controls.forEach((element,index)=>{
      this.calcularTramaYCadena(element,index)
    })
  }

  startForm(refresh: boolean = false) {
    if (refresh) {
      this.telas = [...this.selectTelas]
    }

    this.form = new FormGroup(
      {
        orderArray: new FormArray([]),
        // originalQuantity: new FormControl(this.order.cantidad),
      },
    );

    for (let i = 0; i < this.selectTelas.length; i++) {
      this.getOrderArray.push(this.createItemForm(this.selectTelas[i], false));
    }
  }

  resetForm() {
    this.form.reset()
    this.startForm(true);
  }

  restTotalKgs(value: any, tela: any, index: any, control: AbstractControl) {
    let isCopy = tela.get('copy').value
    let formOrigin = this.getOrderArray.controls.find((elemento) => elemento.get('copy').value === false && elemento.get('id').value === tela.get('id').value)
    let formsCopys = this.getOrderArray.controls.filter((elemento) => elemento.get('copy').value === true && elemento.get('id').value === tela.get('id').value)
    let formCopy = this.getOrderArray.controls[index];

    if(!isCopy){
      formOrigin.get('totalKgsOrigin').setValue(value.value)
      formOrigin.get('totalKgs').setValue(value.value)
      // formOrigin.get('totalReal').setValue(value.value)
      return;
    }

    let totalOrigin = formOrigin.get('totalKgsOrigin').value;
    formCopy.get('totalKgs').setValue(value.value)
    // formCopy.get('totalReal').setValue(value.value)

    formsCopys.forEach((element: FormGroup) => {
      totalOrigin -= element.get('totalKgs').value
    });

  
    formOrigin.get('totalKgs').setValue(totalOrigin)
    // formOrigin.get('totalReal').setValue(totalOrigin)
  }

  enableChangeTotalKgsOrigin(tela: any, index: any) {
    let telaCopy = tela.get('copy').value
    let existCopy = this.getOrderArray.controls.find((elemento) => elemento.get('copy').value === true && elemento.get('id').value === tela.get('id').value)

    if (telaCopy && existCopy) {
      return true;
    }

    return !existCopy;
  }

  enableMachineSelect(index: any, event: any) {
    if (event?.value?.maquinas) {
      this.getOrderArray.controls[index].get('maquinaSelect').enable()
      this.getOrderArray.controls[index].get('maquina').setValue(event.value.maquinas)
    }
  }

  disabledMachineSelect(index: any) {
    this.getOrderArray.controls[index].get('maquina').setValue("")
    this.getOrderArray.controls[index].get('maquinaSelect').disable()
  }

  createItemForm(tela: any, copy: boolean, form?: any) {
    return new FormGroup({
      id: new FormControl(tela.id || form.controls.id.value, []),
      idTela: new FormControl(tela?.tela?.id || form.controls.idTela.value, []),
      objectComplete: new FormControl(tela.id ? tela : form.controls.objectComplete.value, []),
      nombre: new FormControl(tela?.tela?.nombre || form.controls.nombre.value, []),
      codigo: new FormControl(tela?.tela?.codigo || form.controls.codigo.value, []),
      tejedores: new FormControl(tela.tejeduriaList || form.controls.tejedores.value, [
        Validators.required,
      ]),
      tejedorSelect: new FormControl("", [
        Validators.required,
      ]),
      maquina: new FormControl(""),
      maquinaSelect: new FormControl({ value: "", disabled: true }, [Validators.required]),
      totalKgsOriginCopy: new FormControl(tela?.cantidad || form.controls.totalKgsOriginCopy.value, []),
      totalKgsOrigin: new FormControl(tela?.cantidad || form.controls.totalKgsOrigin.value, []),
      totalKgs: new FormControl(tela.cantidad || 0, [Validators.min(1)]),
      totalReal: new FormControl(tela?.cantidad || form.controls.totalReal.value, []),
      cargaPorMaquina: new FormControl('', []),
      composiciones: new FormControl('', []),
      sinClave: new FormControl('', []),
      claves: new FormControl('', []),
      estado: new FormControl(tela?.estado, []),
      tipo: new FormControl(tela?.tipo || form?.controls?.tipo.value, []),
      coeficiente: new FormControl(tela?.tela?.coeficienteCruda || form?.controls?.coeficiente.value, []),
      copy: new FormControl(copy, [])
    },
      { validators: [totalKgsSingular, totalKgsPlural('id', this.getOrderArray)] }
    );
  }

  get getOrderArray(): FormArray {
    return this.form.get('orderArray') as FormArray;
  }

  addItemForm(tela: any, index: any, copy: boolean) {
    this.getOrderArray.insert(index + 1, this.createItemForm(tela, copy, tela));
    this.changeDetectorRef.detectChanges();
  }

  deleteItemForm(tela: any, index: any) {
    let formOrigin = this.getOrderArray.controls.find((elemento) => elemento.get('copy').value === false && elemento.get('id').value === tela.get('id').value)
    formOrigin.get('totalKgs').setValue(formOrigin.get('totalKgs').value + tela.get('totalKgs').value)
    // formOrigin.get('totalReal').setValue(formOrigin.get('totalKgs').value) 
    this.getOrderArray.removeAt(index);
    this.telas.splice(index, 1)
  }

  restartItemForm(tela: any, index: any) {
    let formsCopys = this.getOrderArray.controls.filter((elemento) => elemento.get('copy').value === true && elemento.get('id').value === tela.value.id)

    for (let i = 0; i < formsCopys.length; i++) {
      let indexFormCopy = this.getOrderArray.controls.indexOf(formsCopys[i])
      this.deleteItemForm(formsCopys[i], indexFormCopy)
    }
  }

  calcularTramaYCadena(tela: any, index: any) {
    const composiciones = tela.get('objectComplete').value.tela.composicion;
    const hiladosTejido = tela.get('objectComplete').value.tela.hiladosTejido;
    const coeficiente = tela.get('coeficiente').value;
    const totalReal = tela.get('totalReal').value;


    const usesComposiciones = [];
    let porcentajeTotal = 0;

    while (porcentajeTotal < 100) {
      for (const composicion of composiciones) {
        let nameComposicion = "";
        switch (composicion) {
          case "POLIAMIDA":
            nameComposicion = "NYLON"
            break;
          default:
            nameComposicion = composicion
            break;
        }
        if (porcentajeTotal === 100) {
          break;
        }

        let porcentajeComposicion = 0;

        for (const hilado of hiladosTejido) {
          if (porcentajeTotal >= 100 || porcentajeComposicion >= 100) {
            break;
          }

          let find = usesComposiciones.find((comp) => { return comp.composicion == nameComposicion && comp.numeroDeComponente == hilado.numeroDeComponente })
          let isTotal = porcentajeComposicion + hilado.porcentaje
          // Verificar si el hilado contiene la composición actual y si no ha sido agregado previamente
          if (hilado.nombre.includes(nameComposicion) && !find && isTotal <= 100) {
            usesComposiciones.push({ composicion, hilado });
            porcentajeTotal += hilado.porcentaje;
            porcentajeComposicion += hilado.porcentaje;
          }
        }
      }
    }

    const composicionConMayorPorcentaje = usesComposiciones.reduce((max, item) =>
      item.hilado.porcentaje > max.hilado.porcentaje ? item : max, { hilado: { porcentaje: -1 } }
    );

    usesComposiciones.forEach(element => {
      let cantidad = (totalReal * element.hilado.porcentaje) / 100
      let cadena = cantidad * (coeficiente || 1);
      element.hilado.total = cadena;
    });

    const sortByComponent = (a: any, b: any) => {
      const componentA = a.hilado.numeroDeComponente;
      const componentB = b.hilado.numeroDeComponente;
      return componentA - componentB;
    }
    
    tela.get('composiciones').setValue(usesComposiciones.sort(sortByComponent));

    return usesComposiciones
  }

  showModalAssignYarn(tela: any, index: any) {

    const cantidadesNecesarias = this.calcularTramaYCadena(tela, index);

    this.ref = this.dialogService.open(AssignYarnWeavingKettenComponent, {
      header: '',
      width: '75%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      styleClass: 'modal-assign-order',
      data: {
        tela: tela,
        formItem: this.getOrderArray.controls[index],
        index,
        cantidadesNecesarias
      },
      maskStyleClass: ""
    });
    this.ref.onClose.subscribe((data: any) => {
      if (data && data.success) {
        let index = this.telas.indexOf(data.tela.get('objectComplete').value)
        let indexa = this.getOrderArray.controls.indexOf(data.tela)
        this.getOrderArray.controls[indexa].get('estado').setValue(data.status);
        this.getOrderArray.controls[indexa].get('claves').setValue(data.claves);
        this.telas[index].hilado = data.hilado
      }
    });
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) result = -1;
      else if (value1 != null && value2 == null) result = 1;
      else if (value1 == null && value2 == null) result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

      return event.order * result;
    });
  }

  showModalOrderProduction(tela: any, index: any) {
    this.ref = this.dialogService.open(SeeDetailsProductionKettenComponent, {
      header: '',
      width: '50%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      styleClass: 'modal-assign-order',
      data: {
        tela: tela,
        formItem: this.getOrderArray.controls[index],
      },
      maskStyleClass: ""
    });

    this.ref.onClose.subscribe((data: any) => {
      if (data && data.success) {
        // this.eventEmitterTable.emit(true)
      }
    });
  }

  navigateProductionOrder() {
    this.loadingSelectOrders = true;
    let faltantesExist = this.getOrderArray.controls.filter((element) => element.get('claves').value == "")

    if (faltantesExist.length > 0) {
      this.confirmationService.confirm({
        message: "Queda stock disponible por asignar antes de programar las máquinas ¿Desea continuar?",
        header: "Confirmar acción",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Aceptar",
        rejectLabel: "Cancelar",
        acceptButtonStyleClass: "p-button button-confirm-programmer-machine-weaving p-button-rounded",
        rejectButtonStyleClass: "p-button button-reject-programmer-machine-weaving p-button-rounded",
        accept: () => {
          this.asignarMaquinasWeaving();
        },
        reject: (type) => {
          switch (type) {
            case ConfirmEventType.REJECT:
              this.messageService.add({
                severity: "warn",
                summary: "Cancelado",
                detail: "Usted ha cancelado",
              });
              this.loadingSelectOrders = false;
              break;
            case ConfirmEventType.CANCEL:
              this.messageService.add({
                severity: "warn",
                summary: "Cancelado",
                detail: "Usted ha cancelado",
              });
              this.loadingSelectOrders = false;
              break;
          }
        },
      });
    } else {
      this.asignarMaquinasWeaving();
    }
  }

  calculateTotalRealTodosHiladosTejido(tela: any, index: any, machine: any) {
    const usesComposiciones = [];
    if (machine && machine.value) {
      const alimentadores = machine.value.alimentadores;

      const composiciones = tela.get('objectComplete').value.tela.composicion;
      const hiladosTejido = tela.get('objectComplete').value.tela.hiladosTejido;


        for (const composicion of composiciones) {
          let nameComposicion = "";
          switch (composicion) {
            case "POLIAMIDA":
              nameComposicion = "NYLON"
              break;
            default:
              nameComposicion = composicion
              break;
          }

          for (const hilado of hiladosTejido) {
              usesComposiciones.push({ composicion, hilado });
          }
        }

      const composicionConMayorPorcentaje = usesComposiciones.reduce((max, item) =>
        item.hilado.porcentaje > max.hilado?.porcentaje ? item : max, { hilado: { porcentaje: -1 } }
      );

      const pesoPorBobina = composicionConMayorPorcentaje.hilado.pesoPorBob;
      const totalComposicionPredominante = pesoPorBobina * alimentadores;

      usesComposiciones.forEach(item => {
        if (composicionConMayorPorcentaje === item) { 
          item.hilado.total = totalComposicionPredominante;
        } else {
          const totalComposicion = item.hilado.porcentaje * totalComposicionPredominante / composicionConMayorPorcentaje.hilado.porcentaje;
          item.hilado.total = totalComposicion;
        }
      });
    }

    return usesComposiciones
  } 

  asignarMaquinasWeaving() {
    let asignacionMaquinas = []

    this.getOrderArray.controls.forEach((element,index) => {
      asignacionMaquinas.push({
        cantidad: element.get('totalKgs').value,
        cantidadReal: element.get('totalReal').value,
        hilado1Id: element.get('claves').value[0]?.clave?.id || "",
        hilado2Id: element.get('claves').value[1]?.clave?.id || "",
        hilado3Id: element.get('claves').value[2]?.clave?.id || "",
        hiladoCantidad:  this.calculateTotalRealTodosHiladosTejido(element,index,element.get('maquinaSelect')).reduce((result, element) => {
          result[element.hilado.nombre] = element.hilado.total;
          return result;
        }, {}),
        maquinaId: element.get('maquinaSelect').value.id,
        tejeduriaId: element.get('tejedorSelect').value.id,
        telaId: element.get('idTela').value,
        tipo: element.get('tipo').value,
      });
    })
    
    this.weavingService.asignarMaquinasWeaving({ asignacionMaquinas }).subscribe({
      next: (data) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Programado',
        });
      }, error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: err.error ? err.error.message : 'Ups! Ocurrio un error',
        });
      },
      complete: () => {
        this.router.navigate(['/workplace/weaving'], {
          state: {},
        });
      }
    })
  }


}
