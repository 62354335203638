import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListFabricComponent } from './pages/list-fabric/list-fabric.component';
import { CreateFabricComponent } from './pages/create-fabric/create-fabric.component';
import { UpdateFabricComponent } from './pages/update-fabric/update-fabric.component';
import { DetailsFabricComponent } from './pages/details-fabric/details-fabric.component';

const routes: Routes = [
  
  {
    path : '',
    children : [
      {path : '', component : ListFabricComponent},
      {path : 'list-fabric', component : ListFabricComponent},
      {path : 'create-fabric', component : CreateFabricComponent},
      {path : 'update-fabric', component : UpdateFabricComponent},
      {path : 'detail-fabric', component : DetailsFabricComponent},
      {path : '**', redirectTo : ''}
    ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FabricsRoutingModule { }
