import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import {
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { concat, of, pairwise, startWith } from 'rxjs';
import { DepositService } from 'src/app/api/services/deposit/deposit.service';
import { OrderService } from 'src/app/api/services/order/order.service';
import { Order } from 'src/app/shared/models/order/order.class';

@Component({
  selector: 'app-assign-order-modal-note',
  templateUrl: './assign-order-modal-note.component.html',
  styleUrls: ['./assign-order-modal-note.component.css']
})
export class AssignOrderModalNoteComponent implements OnInit, OnDestroy {

  @Input()
  order: Order;

  spinner = true;

  depositsOriginal: any = [];
  depositsCopyOrder: any = [];

  ref: DynamicDialogRef | undefined;

  @Output()
  eventHiddenModal: EventEmitter<boolean> = new EventEmitter();

  stockPorTipoSelect: any = []

  form: FormGroup;



  constructor(
    private messageService: MessageService,
    public dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
    private depositService: DepositService,
    private orderService: OrderService
  ) {
    this.spinner = true;
    this.depositsCopyOrder = [];
  }

  ngOnInit(): void {
    this.order = this.dialogConfig.data.order;
    concat(of(
      this.getDeposits()
    ),
      concat(of(
        this.startForm()
      )),
      concat(of(

      ))

    ).subscribe()
    if (this.order.stockAsignado.length > 0) {
      this.addItemsExist()
    }

  }

  ngOnDestroy(): void {
    this.spinner = true;
    this.depositsOriginal = [];
    this.depositsCopyOrder = [];
  }

  startForm() {
    this.form = new FormGroup({
      items: new FormArray(this.order.stockAsignado.length > 0 ? [] : [this.createItemForm(null)]),
    });

  }

  addItemsExist() {
    for (let i = 0; i < this.order.stockAsignado.length; i++) {
      const encontrado = this.order.stock.find((stock)=>{return stock.deposito.id == this.order.stockAsignado[i].depositoId})
      this.addItemForm({asignado : this.order.stockAsignado[i],encontrado}, )
    }
  }

  ordenarDepositosPorNombre(depositos: any) {
    depositos.sort((a, b) => {
      const nombreA = a.deposito.nombre.toUpperCase();
      const nombreB = b.deposito.nombre.toUpperCase();
      if (nombreA < nombreB) {
        return -1;
      }
      if (nombreA > nombreB) {
        return 1;
      }
      return 0;
    });
  }

  createItemForm(deposits: any, data = null) {
    const form = new FormGroup({
      deposito: new FormControl(data?.encontrado ? data?.encontrado : "", [Validators.required]),
      cantidad: new FormControl(data?.asignado.cantidad ? data?.asignado.cantidad.toFixed(2) : "", [Validators.required]),
      depositos: new FormControl(deposits ? deposits : [...this.depositsOriginal], []),
    });

    form.get('deposito').valueChanges.pipe(
      startWith(''), // Emite un valor inicial para que pairwise funcione
      pairwise() // Emite un arreglo con los dos últimos valores emitidos
    ).subscribe(([valorAnterior, nuevoValor]) => {
      const controlsExceptCurrent = this.getItemsArrayForm.controls.filter(control => control !== form);
      const before: any = valorAnterior;
      const after: any = nuevoValor;
      if (before) {
        if (controlsExceptCurrent.length > 0) {
          controlsExceptCurrent.forEach(element => {
            let find = element.get('depositos').value.find((deposito) => { return deposito == before })
            if (!find) {
              element.get('depositos').value.push(before)
            }
            this.ordenarDepositosPorNombre(element.get('depositos').value)

          });
        }
      }
      if (after) {
        if (controlsExceptCurrent.length > 0) {
          controlsExceptCurrent.forEach(element => {
            let index = element.get('depositos').value.indexOf(after)
            if (index != -1) {
              element.get('depositos').value.splice(index, 1)
            }
            this.ordenarDepositosPorNombre(element.get('depositos').value)
          });
        }
      }
      // Aquí puedes realizar las acciones que necesites con el valor anterior y el nuevo valor
    });
    return form;
  }

  totalAssign() {
    let total: any = 0;
    this.getItemsArrayForm.controls.forEach((element) => {
      if (element.get('cantidad').value) {
        total += parseFloat(element.get('cantidad').value);
      }
    })
    return total.toFixed(1);
  }

  get getItemsArrayForm(): FormArray {
    return this.form.get('items') as FormArray;
  }

  addItemForm(data = null) {
    const depositLast = [...this.depositsOriginal]
    this.getItemsArrayForm.controls.forEach((element) => {
      let index = depositLast.indexOf(element.get('deposito').value)
      if (index != -1) {
        depositLast.splice(index, 1)
      }
    })

    this.getItemsArrayForm.push(this.createItemForm(depositLast, data));
  }

  deleteItemForm(index: any) {
    let form = this.getItemsArrayForm.controls[index];
    let depositoToDelete = form.get('deposito').value;

    this.getItemsArrayForm.controls.forEach((element) => {
      let depositos = element.get('depositos').value;
      let indexToDelete = depositos.indexOf(depositoToDelete)
      if (indexToDelete == -1 && depositoToDelete) {
        depositos.push(depositoToDelete);
        this.ordenarDepositosPorNombre(depositos)
      }
    })

    this.getItemsArrayForm.removeAt(index);
  }

  getDeposits() {
    this.spinner = false;
    this.depositsOriginal = this.order.stock;
    this.getDepositsAvailable();
  }

  quantityInputForm(event: any, index: any) {
    if (event && event.value) {
      this.getItemsArrayForm.controls[index].get('cantidad').setValue(event.value?.cantidad >= this.order.cantidad ? this.order.cantidad : event.value?.cantidad.toFixed(1))
      this.totalAssign()
    }
  }

  getDepositsAvailable() {
    if (this.order) {
      this.depositsOriginal.forEach((element) => {
        this.depositsCopyOrder.push(element);
      });
    }
  }

  sendForm(array = null) {
    let ordenes = []

    if(array){
      ordenes.push(
        {
          articuloId: this.order.id,
        }
      )
    }else{
      this.getItemsArrayForm.controls.forEach((element, i) => {
        ordenes.push(
          {
            depositoId: element.get('deposito').value.deposito.id,
            articuloId: this.order.id,
            cantidad: element.get('cantidad').value,
            order: i + 1
          }
        )
      })
    }
    
    this.orderService.assignOrderMultiple(ordenes).subscribe({
      next: (data) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Guardado',
        });
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: err.error ? err.error.message : 'Ups! Ocurrio un error',
        });
      },
      complete: () => {
        this.dialogRef.close({ success: true, order: this.order })
      },
    });

  }

  returnNumber(string) {
    return parseInt(string).toFixed(1);
  }



}
