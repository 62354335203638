import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AuthService } from 'src/app/api/services/auth/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent {
  progressBarValue = 100;
  keywords: any = [];
  formDatos = this.form.group({
    user: ['admin', Validators.required],
    pass: ['admin', Validators.required],
  });

  loginDisplay = false;
  username;
  constructor(
    private router: Router,
    private form: FormBuilder,
    private messageService: MessageService,
    private authServices: AuthService
  ) {}

  ngOnInit(): void {
    // if (localStorage.getItem("token")) {
    //   this.authServices.actual().subscribe(
    //     (actual) => {
    //       if (actual) {
    //         this.router.navigate(["/home"]);
    //       }
    //     },
    //     (error) => {
    //     }
    //   );
    // }
  }

  submit() {
    if (this.formDatos.invalid) {
      this.messageService.add({
        key: 'msg',
        severity: 'warn',
        summary: 'error',
        detail: 'Faltan completar campos',
      });
    } else {
      let user = this.formDatos.get('user').value;
      let pass = this.formDatos.get('pass').value;
      this.authServices.login({ password: pass, username: user }).subscribe({
        next: (res) => {
          localStorage.setItem('token', res.token);
          this.messageService.add({
            severity: 'success',
            summary: 'Hecho!',
            detail: 'Inicio de sesión exitoso! ',
          });
        },
        error: (err) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Usuario o contraseña incorrectos',
          });
        },
        complete : ()=>{
          this.router.navigate(['/home'])
        }
      });
    }
  }

  redirectTo(uri: string) {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([uri]));
  }
}
