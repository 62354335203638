<form [formGroup]="form" (ngSubmit)="navigateProductionOrder()">
  <p-table [value]="orders" dataKey="id" [tableStyle]="{ 'min-width': '40rem' }" styleClass="p-datatable-sm" [style]="{
    'max-height': '70vh',
    'overflow-y': 'auto',
  }" [scrollable]="true" [rows]="50" (sortFunction)="customSort($event)" [customSort]="true" scrollHeight="70vh"
    selectionMode="single">
    <ng-template pTemplate="header">
      <tr>
        <!-- <th></th> -->
        <th pSortableColumn="articulo" class="td m-0 p-0" style="width: 14%">
          Articulo <p-sortIcon field="articulo"></p-sortIcon>
        </th>
        <th pSortableColumn="color" class="td m-0 p-0" style="width:10%">
          Color <p-sortIcon field="color"></p-sortIcon>
        </th>
        <th pSortableColumn="tipo" class="td m-0 p-0" style="width:11%">
          Tipo <p-sortIcon field="tipo"></p-sortIcon>
        </th>
        <th pSortableColumn="cantidad" class="td m-0 p-0" style="width: 28%">
          Total Kgs <p-sortIcon field="cantidad"></p-sortIcon>
        </th>
        <th pSortableColumn="proceso" class="td m-0 p-0" style="width: 14%">
          Proceso <p-sortIcon field="proceso"></p-sortIcon>
        </th>
        <th class="td m-0 p-0" style="width: 14%">Maquina</th>
        <th class="td m-0 p-0" style="width: 7%">Acción</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-order let-rowIndex="rowIndex">
      <tr>
        <td class="td m-0 p-0 truncate fw-bold">{{ order.articulo }}</td>
        <td class="td m-0 p-0 truncate">{{ order.color }}</td>

        <td class="td m-0 p-0 fw-bold">
          <p-dropdown appendTo="body" class="" [(ngModel)]="order.tipo" [options]="tipos" styleClass="p-dropdown p-dropdown-items height"
            [style]="{width : '90%',border: '1px solid #694DA0' }" [placeholder]="'Tipo'" optionLabel="label"
            optionValue="value" [showClear]="true" [ngModelOptions]="{standalone: true}">
            <ng-template let-option pTemplate="item">
              <span [class]="'tipo -' + option.value">{{
                option.label
                }}</span>
            </ng-template>
          </p-dropdown> 
        </td>
        <td class="td m-0 p-0 truncate">
          <div *ngIf="order.maquinaMock && order.maquinaMock.length > 0">
            <div *ngFor="let item of order.maquinaMock">
              <div class="row m-0 p-0">
                <div class="col m-0 p-0">
                  <p>Máquina: {{ item.maquinaId.nombre }}</p>
                </div>
                <div class="col m-0 p-0">
                  <p class="fw-bold">Cantidad: {{ item.cantidad.toFixed(2) }}</p>
                </div>
                <div class="col m-0 p-0">
                  <p class="fw-bold" *ngIf="item.banios">Baños: {{ item.banios }} <span
                      *ngIf="item.kilosPorBanio">({{item.kilosPorBanio}}
                      Kgs)</span></p>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="order.maquinaMock.length <= 0">
            <div class="row m-0 p-0">
              <div class="col m-0 p-0">
                <p>
                  Máquina:
                  {{ order?.maquina?.nombre || "Sin asignar" }}
                </p>
              </div>
              <div class="col m-0 p-0">
                <p class="fw-bold">Cantidad: {{ order.cantidad.toFixed(2) }}</p>
              </div>
              <div class="col m-0 p-0">
                <p *ngIf="order?.maquina?.banios" class="fw-bold">Baños: {{ order.maquina.banios }} <span
                    *ngIf="order.maquina.kilosPorBanio">({{order.maquina.kilosPorBanio}} Kgs)</span></p>
              </div>
            </div>
          </div>
        </td>
        <td class="td m-0 p-0 fw-bold">{{ order.proceso || "Lavado" }}</td>
        <td class="td m-0 p-0" formArrayName="machineArray">
          <div class="td m-0 p-0" [formGroupName]="rowIndex">
            <p-dropdown styleClass="p-dropdown p-dropdown-items height"
              *ngIf="order.maquinasList && order.maquinasList.length > 1;else unaMaquina" appendTo="body"
              class="w-100 ml-5" [options]="order.maquinasList || []" [style]="{border: '1px solid #694DA0' }"
              placeholder="- Máquina -" optionLabel="nombre"
              [showClear]="order.maquinasList && order.maquinasList.length > 1 ? true : false" formControlName="machine"
              (onChange)="changeMachineOrder($event, rowIndex,order.cantidad)"
              (onClear)="changeMachineOrder($event, rowIndex,order.cantidad)">
              <ng-template let-option pTemplate="item">
                <span [class]="'machine-' + option.id">{{
                  option.nombre
                  }}</span>
              </ng-template>
            </p-dropdown>
            <ng-template #unaMaquina>
              <p class=" m-auto fw-bold">{{ getMachineArray?.controls[rowIndex]
                ?.get('machine').value?.nombre }}</p>
            </ng-template>
            <span class="danger" *ngIf="
                getMachineArray?.controls[rowIndex]
                  ?.get('machine')
                  ?.hasError('required') &&
                getMachineArray?.controls[rowIndex]?.get('machine')?.touched
              ">
              <small class="p-error">Este campo es <strong>requerido</strong></small>
            </span>
          </div>
        </td>
        <td class="td m-0 p-0">
          <p-button icon="bi bi-arrows-expand-vertical"
            styleClass="p-button-sm p-button-secondary p-button-outlined p-button-text padding-0" pTooltip="Dividir"
            tooltipPosition="top" (click)="assignMachinePopUp(order, rowIndex)"></p-button>
          <p-button *ngIf="order.tipo == 'Stock'" icon="bi bi-x-circle"
            styleClass="p-button-sm p-button-secondary p-button-outlined p-button-text padding-0" pTooltip="Eliminar"
            tooltipPosition="top" (click)="deleteOrder(order)"></p-button>
          <!-- bi bi-arrows-expand-vertical -->
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="loadingbody" let-rowData>
      <tr class="h-100 w-100">
        <td colspan="13">
          <div class="text-center">
            <span>
              <p-progressSpinner></p-progressSpinner>
            </span>
            <br />
            <span class="text-center">Cargando...</span>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" class="w-100">
      <tr>
        <td colspan="12">
          <p-messages class="w-100" [value]="[{ severity: 'info', detail: 'No hay ordenes' }]" [enableService]="false"
            [closable]="false">
          </p-messages>
        </td>
      </tr>
    </ng-template>
  </p-table>
</form>

<div class="row d-flex justify-content-center my-5">
  <div class="col-12 text-end">
    <p-button styleClass="p-button-rounded p-button-sm p-button-secondary button-programmer-machine" class="my-3"
      icon="pi pi-arrow-right" iconPos="right" tooltipPosition="bottom" label="Programar máquinas"
      (onClick)="form.invalid ? '' : navigateProductionOrder()" [disabled]="form.invalid"></p-button>
  </div>
</div>