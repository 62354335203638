import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProductionRoutingModule } from './production-routing.module';
import { ProductionItemsComponent } from './production-items.component';
import { PublicModule } from 'src/app/public/public.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { OrderModule } from '../order/order.module';


@NgModule({
  declarations: [
    ProductionItemsComponent,
  ],
  imports: [
    CommonModule,
    ProductionRoutingModule,
    OrderModule,
    SharedModule,
    PublicModule
  ]
})
export class ProductionModule { }
