import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { FilterEnum } from 'src/app/shared/filters/enum/filters.enum';
import { Method } from 'src/app/shared/filters/enum/method.enum';
import { Filters } from 'src/app/shared/filters/interface/filters.interface';
import { Item } from 'src/app/shared/models/model-forms/item.interface';
import * as FileSaver from 'file-saver';
import { OrderService } from 'src/app/api/services/order/order.service';

@Component({
  selector: 'app-production-cleaning-planning-order',
  templateUrl: './production-cleaning-planning-order.component.html',
  styleUrls: ['./production-cleaning-planning-order.component.css']
})
export class ProductionCleaningPlanningOrderComponent implements OnInit{

  spinner = true;

  items: any= [];

  size = 50;

  fabric: Item[] = [
    {
      label: 'TODOS',
      value: 'all',
    },
    {
      label: 'MALAGA',
      value: '1',
    },
    {
      label: 'MADRID FRISADO',
      value: '2',
    },
  ];

  states: Item[] = [
    {
      label: 'TODOS',
      value: 'all',
    },
    {
      label: 'TEÑIDO',
      value: '1',
    },
    {
      label: 'LAVADO',
      value: '2',
    },
    {
      label: 'FRIZADO',
      value: '3',
    },
  ];

  color: Item[] = [
    {
      label: 'TODOS',
      value: 'all',
    },
    {
      label: 'Rojo',
      value: 'Rojo',
    },
    {
      label: 'Azul',
      value: 'Azul',
    },
    {
      label: 'Amarillo',
      value: 'Amarillo',
    },
  ];

  filters: Filters = {
    autoSend: true,
    method: Method.GET,
    filtersCustom: [
      {
        type: FilterEnum.MULTISELECT,
        col: 'col-12 col-md-4 col-lg-2 my-3 my-lg-0',
        title: '',
        placeholder: 'Tela',
        titleClass: '',
        inputStyle: {
          width: '100%',
          'border-radius': '50rem',
        },
        nameFilter: 'fabric',
        valueFilter: '',
        order: 1,
        items: {
          label: 'label',
          value: 'value',
          search: true,
          items: this.fabric,
        },
      },
      {
        type: FilterEnum.MULTISELECT,
        col: 'col-12 col-md-4 col-lg-2 my-3 my-lg-0',
        title: '',
        placeholder: 'Color',
        titleClass: '',
        inputStyle: {
          width: '100%',
          'border-radius': '50rem',
        },
        nameFilter: 'fabric',
        valueFilter: '',
        order: 2,
        items: {
          label: 'label',
          value: 'value',
          search: true,
          items: this.color,
        },
      },
      {
        type: FilterEnum.MULTISELECT,
        col: 'col-12 col-md-4 col-lg-2 my-3 my-lg-0',
        title: '',
        placeholder: 'Proceso',
        titleClass: '',
        inputStyle: {
          width: '100%',
          'border-radius': '50rem',
        },
        nameFilter: 'fabric',
        valueFilter: '',
        order: 3,
        items: {
          label: 'label',
          value: 'value',
          search: true,
          items: this.states,
        },
      }
    ],
  };

  orderListAssign:any = [];

  constructor(private messageService:MessageService,private router:Router,private orderService:OrderService){

  }

  ngOnInit(): void {
    this.items = history.state.items
    this.spinner = false;
    this.getListAssignMachine();
  }

  getListAssignMachine(){
    this.orderService.assignMachines(this.items).subscribe({
      next : (res)=>{
        res.list.forEach(element => {
          element.maquinaAsignada = null;
          element.maquinaMock = [];
        });
        
        this.orderListAssign = res.list;
      },
      error : (err)=>{
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: err.error ? err.error.message : 'Ups! Ocurrio un error',
        });
      }
    })
  }

  exportExcel() {
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(this.items);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      this.saveAsExcelFile(excelBuffer, 'orders');
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
    );
  }

}
