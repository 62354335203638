import { AbstractControl, FormArray, ValidatorFn } from "@angular/forms";



export const totalKgsSingular: ValidatorFn = (
    control: AbstractControl
): { [key: string]: boolean } | null => {
    const totalKgsOrigin = control.get('totalKgsOriginCopy').value ? parseFloat(control.get('totalKgsOriginCopy').value.toFixed(1)) : 0;
    const totalKgs = control.get('totalKgs').value ? parseFloat(control.get('totalKgs').value.toFixed(1)) : 0;

    if (totalKgs > totalKgsOrigin) {
        return { totalExceeded: true };
    }
    return null;
};


export const totalKgsPlural = (parametroFormControl: string, array: FormArray): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: boolean } | null => {

        let id = control.get(parametroFormControl).value

        let formOrigin = array.controls.find((elemento) => elemento.get('copy').value === false && elemento.get('id').value === id)
        let formsCopys = array.controls.filter((elemento) => elemento.get('copy').value === true && elemento.get('id').value === id)

        if(formsCopys.length > 0){
            let totalOrigin = formOrigin.get('totalKgsOriginCopy').value;
            let totalCopys = 0;
            for (let i = 0; i < formsCopys.length; i++) {
                totalCopys+= formsCopys[i].get('totalKgs').value;
            }

            if(totalCopys > totalOrigin){
                return { totalMaxExceeded: true };
            }
        }
        // Devuelve los errores si es necesario
        // return { totalExceeded: true };

        // Si no hay errores, devuelve null
        return null;
    };
};