import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OrderRoutingModule } from './order-routing.module';
import { ListOrderComponent } from './pages/list-order/list-order.component';
import { TableOrderComponent } from './components/table-order/table-order.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { PublicModule } from 'src/app/public/public.module';
import { AssignOrderModalComponent } from './modals/assign-order-modal/assign-order-modal.component';
import { DetailsOrderModalComponent } from './modals/details-order-modal/details-order-modal.component';
import { DatePromiseModalComponent } from './modals/date-promise-modal/date-promise-modal.component';
import { AssignOrderModalNoteComponent } from './modals/assign-order-modal-note/assign-order-modal-note.component';


@NgModule({
  declarations: [
    ListOrderComponent,
    TableOrderComponent,
    AssignOrderModalComponent,
    DetailsOrderModalComponent,
    DatePromiseModalComponent,
    AssignOrderModalNoteComponent,
  ],
  imports: [
    CommonModule,
    OrderRoutingModule,
    SharedModule,
    PublicModule
  ],
  exports : [
    ListOrderComponent,
    TableOrderComponent
  ]
})
export class OrderModule { }
