import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListOrderComponent } from './pages/list-order/list-order.component';

const routes: Routes = [
  {
    path : '',
    children : [
      {path : 'list-order',component : ListOrderComponent},
      {path : '**', redirectTo : 'list-order'}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrderRoutingModule { }
