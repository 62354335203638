import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-production-warped-allocate-raw-material',
  templateUrl: './production-warped-allocate-raw-material.component.html',
  styleUrls: ['./production-warped-allocate-raw-material.component.css']
})
export class ProductionWarpedAllocateRawMaterialComponent implements OnInit {

  spinner = true;

  size = 10;

  articulos: any[] = []

  constructor(
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.articulos = history.state.articulos.list;
    this.spinner = false;

  }

}
