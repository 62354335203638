import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import {
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { finalize } from 'rxjs';
import { DepositService } from 'src/app/api/services/deposit/deposit.service';
import { Deposit } from 'src/app/shared/models/deposit/deposit.class';
import { Order } from 'src/app/shared/models/order/order.class';

@Component({
  selector: 'app-assign-production-cleaning-planning-modal',
  templateUrl: './assign-production-cleaning-planning-modal.component.html',
  styleUrls: ['./assign-production-cleaning-planning-modal.component.css'],
})
export class AssignProductionCleaningPlanningModalComponent
  implements OnInit, OnDestroy
{
  @Input()
  order: Order;

  spinner = true;

  depositsOriginal: Deposit[] = [];
  depositsCopyOrder: Deposit[] = [];

  ref: DynamicDialogRef | undefined;

  form: FormGroup;

  @Output()
  eventHiddenModal: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private depositService: DepositService,
    private messageService: MessageService,
    public dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig
  ) {
    this.spinner = true;
    this.depositsCopyOrder = [];
  }

  ngOnInit(): void {
    this.order = this.dialogConfig.data.order;
    this.getDeposits();
  }

  ngOnDestroy(): void {
    this.spinner = true;
    this.depositsOriginal = [];
    this.depositsCopyOrder = [];
  }

  startForm() {
    this.form = new FormGroup({
      deposito: new FormControl('', [Validators.required]),
      fechaEntrega: new FormControl(this.order.fechaDePedido, [
        Validators.required,
      ]),
    });
  }

  getDeposits() {
    this.depositService
      .getDeposits()
      .pipe(
        finalize(() => {
          this.spinner = false;
          this.startForm();
        })
      )
      .subscribe({
        next: (data) => {
          this.depositsOriginal = data.list;
          this.getDepositsAvailable();
        },
        error: (err) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: err.error ? err.error.message : 'Ups! Ocurrio un error',
          });
        },
      });
  }

  async getDepositsAvailable() {
    if (this.order) {
      this.depositsOriginal.forEach((element) => {
        if (parseInt(element.available) >= parseInt(this.order.cantidad)) {
          this.depositsCopyOrder.push(element);
        }
      });
    }
  }

  sendForm() {
    this.messageService.add({
      severity: 'success',
      summary: 'Guardado',
    });
    this.dialogRef.close({ success: true, order: this.order });
  }

  returnNumber(string) {
    return parseInt(string);
  }
}
