import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { MachineService } from 'src/app/api/services/automation/machine/machine.service';

@Component({
  selector: 'app-list-machine',
  templateUrl: './list-machine.component.html',
  styleUrls: ['./list-machine.component.css']
})
export class ListMachineComponent {

  
  machines: any = [];

  size = 10;
  page = 1;
  totalElements = 0;

  spinner = true;

  constructor(private messageService: MessageService, private machineService: MachineService) {

  }

  ngOnInit(): void {
    this.getMachines(true);
  }

  getMachines(refresh = false) {
    this.spinner = refresh;
    if (refresh) {
      this.machineService.getMachinesList().subscribe({
        next: (data) => {
          this.totalElements = data.totalElements;
          this.machines = data.list;
        }, error: (err) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: err.error ? err.error.message : 'Ups! Ocurrio un error',
          });
        }, complete: () => {
          this.spinner = false;
        }
      })
    }
  }

}
