<div class="card text-center" *ngIf="spinner && depositsCopyOrder.length <= 0">
  <span>
    <p-progressSpinner></p-progressSpinner>
  </span>
  <br />
  <span class="text-center">Cargando...</span>
</div>

<div *ngIf="order && !spinner && depositsCopyOrder.length > 0">
  <div class="row text-center">
    <div class="col">
      <h3 class="text-secondary">Asignar stock</h3>
    </div>
  </div>
  <div class="row text-center mt-3">
    <div class="col">
      <p class="text-dark">Articulo: {{ order.tela }}</p>
    </div>
    <div class="col">
      <p class="text-dark">Color: {{ order.color }}</p>
    </div>
    <div class="col">
      <p class="text-dark">Cantidad: {{ order.cantidad }}</p>
    </div>
  </div>
  <div class="row text-center mt-2">
    <div class="col">
      <h5 class="text-dark">Stock existente</h5>
    </div>
  </div>
  <div class="row text-center">
    <div class="col-4" *ngFor="let deposit of depositsOriginal">
      <p  class="text-dark">
        {{ deposit.deposito.nombre }}:
        <b [class]="{
            'text-danger':
              returnNumber(deposit.cantidad) < returnNumber(order.cantidad),
            'text-success':
              returnNumber(deposit.cantidad) >= returnNumber(order.cantidad)
          }">{{ deposit.cantidad.toFixed(1) }}</b>
      </p>
    </div>
  </div>
  <form [formGroup]="form" (ngSubmit)="sendForm()">
    <div class="row justify-content-center">
      <div class="col-12 col-md-4 mx-md-3">
        <label for="deposito" class="text-dark">Asignar</label>
        <p-dropdown [style]="{ width: '100%' }" class="" appendTo="body" [options]="depositsCopyOrder"
          [showClear]="true" optionValue="deposito" optionLabel="deposito.nombre" formControlName="deposito"
          placeholder="Seleccione">
          <ng-template let-option pTemplate="item">
            <span [class]="'customer-badge status-' + option.deposito.id">{{
              option.deposito.nombre
              }}</span>
          </ng-template>
        </p-dropdown>
        <span class="danger" *ngIf="
            form.get('deposito').hasError('required') &&
            form.get('deposito').touched
          ">
          <small class="p-error">Este campo es <strong>requerido</strong></small>
        </span>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col text-end">
        <p-button label="Guardar" styleClass="p-button p-button-secondary p-button-rounded assing-deposit-button"
          type="submit" [disabled]="form.invalid">
        </p-button>
      </div>
    </div>
  </form>
</div>