import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  DoCheck,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { MessageService, SortEvent } from 'primeng/api';
import { Item } from 'src/app/shared/models/model-forms/item.interface';
import { Order } from 'src/app/shared/models/order/order.class';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AssignMachineComponent } from '../../modals/assign-machine/assign-machine.component';
import { OrderService } from 'src/app/api/services/order/order.service';
import { MachineService } from '../../../../../api/services/automation/machine/machine.service';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-table-production-cleaning-planning-order',
  templateUrl: './table-production-cleaning-planning-order.component.html',
  styleUrls: ['./table-production-cleaning-planning-order.component.css'],
})
export class TableProductionCleaningPlanningOrderComponent
  implements OnInit, AfterViewChecked, DoCheck {
  @Input()
  orders: any = [];

  machines: Item[] = [
    {
      label: 'ATM',
      value: 'ATM',
    },
    {
      label: 'SOFT',
      value: 'SOFT',
    },
  ];

  tipos: Item[] = [
    {
      label: 'Pedido',
      value: 'Pedido',
    },
    {
      label: 'Desarrollo',
      value: 'Desarrollo',
    },
    {
      label: 'Stock',
      value: 'Stock',
    },
  ];

  assignMachinesOrderMock: any = [];
  assignMachinesOrderSend: any = [];

  form: FormGroup;

  @Output()
  eventEmitterTable = new EventEmitter<boolean>()

  constructor(
    private router: Router,
    private dialogService: DialogService,
    private dialogRef: DynamicDialogRef,
    private orderService: OrderService,
    private machineService: MachineService,
    private messageService: MessageService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }
  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngDoCheck() {
    this.changeDetectorRef.detectChanges();
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      machineArray: new FormArray([]),
    });

    this.orders.forEach((element: any,index:any) => {
      element.id = index;
      this.addItemMachine(element);
      if (element.maquina) {
        this.changeMachineOrderDefault(element.maquina, element.cantidad, element)
      }
    });
  }

  changeMachineOrder(event: any, index: any, cantidad: any) {
    if (event.value) {

      let banios = 1;
      let kilosPorBanio = event?.value?.kilosPorBanio

      if (kilosPorBanio) {
        while ((kilosPorBanio * banios) < cantidad) {
          banios++;
        }
      }

      this.orders[index].maquina = { id: event.value.id, nombre: event.value.nombre, banios, kilosPorBanio, cantidad }
      this.orders[index].maquinaMock = [];
      this.getMachineArray.controls[index].get('machine').setValidators([Validators.required])
    } else {
      this.orders[index].maquina = null;
      this.orders[index].maquinaMock = [];
      this.getMachineArray.controls[index].get('machine').setValidators([Validators.required])
    }
  }

  changeMachineOrderDefault(maquina: any, cantidad: any, order: any) {
    if (maquina) {
      let banios = 1;
      
      let kilosPorBanio = maquina?.kilosPorBanio
      if (kilosPorBanio) {
        while ((kilosPorBanio * banios) < cantidad) {
          banios++;
        }
      }

      order.maquina = { id: maquina.id, nombre: maquina.nombre, banios, kilosPorBanio, cantidad }
    } else {
      order.maquina = null;
      order.maquinaMock = [];
    }
  }

  createItemMachineRequired(order: any) {
    return new FormGroup({
      machine: new FormControl({value : order?.maquina ? order.maquina : '',disabled: false}, [
        Validators.required,
      ]),
    });
  }

  get getMachineArray(): FormArray {
    return this.form.get('machineArray') as FormArray;
  }

  addItemMachine(order: any) {
    this.getMachineArray.push(this.createItemMachineRequired(order));
  }

  deleteItemMachine(index: any) {
    this.getMachineArray.removeAt(index);
  }

  assignMachinePopUp(order: Order, index: any) {

    this.dialogRef = this.dialogService.open(AssignMachineComponent, {
      header: '',
      width: '50%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: false,
      data: {
        order: order,
        index,
      },
    });

    this.dialogRef.onClose.subscribe((result) => {
      if (result && result.success) {
        let index = this.orders.findIndex((element) => {
          return element.id === result.order.id;
        });

        if (index != -1) {
          this.orders[index].maquinaMock = result.assign;
          this.getMachineArray.controls[result.index].get('machine').setValidators([])
          this.getMachineArray.controls[result.index].get('machine').setErrors(null)

          // let findMock = this.assignMachinesOrderMock.findIndex((element) => {
          //   return element.items === result.items;
          // });
          // if (findMock != -1) {
          //   this.assignMachinesOrderMock.splice(findMock, 1);
          //   this.assignMachinesOrderMock.push(result.assign);
          // } else {
          //   this.assignMachinesOrderMock.push(result.assign);
          // }
        }
      }
    });
  }

  sonIguales(array1: any[], array2: any[]): boolean {
    // Verificar si ambos arrays tienen la misma longitud
    if (array1.length !== array2.length) {
      return false;
    }

    // Verificar si cada elemento de array1 está presente en array2
    return array1.every((element) => array2.includes(element));
  }

  deleteOrder(order: Order) {
    let index = this.orders.indexOf(order);
    if (index != -1) {
      this.orders.splice(index, 1);
    }
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) result = -1;
      else if (value1 != null && value2 == null) result = 1;
      else if (value1 == null && value2 == null) result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

      return event.order * result;
    });
  }

  navigateProductionOrder() {
    if (this.form.invalid) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Aún faltan máquinas por asignar!',
      });
      return;
    }
    let asignacionMaquinas = [];

    this.orders.forEach((element) => {
      if (element.maquinaMock.length > 0) {
        element.maquinaMock.forEach((element) => {
            asignacionMaquinas.push({
              cantidad: element.cantidad,
              colorId: element.colorId,
              maquinaId: element.maquinaId.id,
              procesoId: element.procesoId,
              telaId: element.telaId,
              tipo: element.tipo,
              items: element.tipo == "Pedido" ? element.items : [],
            });
        });
      } else {
        for (let i = 0; i < element.maquina.banios; i++) {
          asignacionMaquinas.push({
            cantidad: element.cantidad / element.maquina.banios,
            colorId: element.colorId,
            maquinaId: element?.maquina?.id,
            procesoId: element.procesoId,
            telaId: element.telaId,
            tipo: element.tipo,
            items: element.tipo == "Pedido" ? element.items : [],
          });
        }
      }
    });


    this.machineService.assignMachines({ asignacionMaquinas }).subscribe({
      next: (data) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Programadas!',
        });
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: err.error ? err.error.message : 'Ups! Ocurrio un error',
        });
      },
      complete : ()=> { 
        this.router.navigate(['/workplace/cleaning'], {
          state: {},
        });
      },
    });


  }
}
