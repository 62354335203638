<div class="card text-center" *ngIf="spinner">
  <span>
    <p-progressSpinner></p-progressSpinner>
  </span>
  <br />
  <span class="text-center">Cargando...</span>
</div>

<div *ngIf="tela && !spinner">
  <div class="row text-center">
    <div class="col">
      <h3 class="text-secondary">Ver detalle</h3>
    </div>
  </div>
  <div class="row text-center mt-3">
    <div class="col">
      <h5 class="text-dark"> {{tela.get('tejedorSelect').value.nombre}} - {{tela.get('maquinaSelect').value.nombre}}</h5>
    </div>
    <div class="col">
      <h5 class="text-dark fw-bold"> {{tela.controls.nombre.value}} - {{tela.controls.codigo.value}}</h5>
    </div>
    <div class="col">
      <h5 class="text-dark fw-bold"> {{tela.get('totalKgs').value.toFixed(2)}} {{tela.get('objectComplete').value.tela.unidadDeMedida == 'METRO' ? 'Mts' : 'Kgs'}}</h5>
    </div>
  </div>
  <div class="row mt-3 text-center">
      <div [ngClass]="{
        'col-12'  : tela.controls.claves.value && tela.controls.claves.value.length <= 1,
        'col-6'  : tela.controls.claves.value && tela.controls.claves.value.length > 1,
      }"*ngFor="let clave of tela.controls.claves.value">
              <h6 class="fw-bold">{{clave.composicion}}</h6>
              <p *ngIf="clave.clave.descripcion.includes('CAD')" class="fw-bold">{{clave.clave.descripcion || 'Sin clave'}} {{clave.clave.cantidad.toFixed(2)}} Mts</p>
              <p *ngIf="!clave.clave.descripcion.includes('CAD')" class="fw-bold">{{clave.clave.descripcion || 'Sin clave'}} {{clave.clave.cantidad.toFixed(2)}} Kgs</p>
      </div>
  </div>
</div>
