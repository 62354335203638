import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProcessRoutingModule } from './process-routing.module';
import { ListProcessComponent } from './pages/list-process/list-process.component';
import { CreateProcessComponent } from './pages/create-process/create-process.component';
import { UpdateProcessComponent } from './pages/update-process/update-process.component';
import { DetailProcessComponent } from './pages/detail-process/detail-process.component';
import { FormProcessComponent } from './components/form-process/form-process.component';
import { TableProcessListComponent } from './components/table-process-list/table-process-list.component';
import { PublicModule } from 'src/app/public/public.module';
import { SharedModule } from 'src/app/shared/shared.module';


@NgModule({
  declarations: [
    ListProcessComponent,
    CreateProcessComponent,
    UpdateProcessComponent,
    DetailProcessComponent,
    FormProcessComponent,
    TableProcessListComponent
  ],
  imports: [
    CommonModule, 
    ProcessRoutingModule,
    PublicModule,
    SharedModule
  ]
})
export class ProcessModule { }
