import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BomsItemsComponent } from './boms-items.component';

const routes: Routes = [
  
  {
    path : '',
    children : [
      {path : '', component : BomsItemsComponent},
      {path : 'boms', component : BomsItemsComponent},
      {
        path : 'fabrics',
        loadChildren : ()=>import('./fabrics/fabrics.module').then(m=>m.FabricsModule)
      },
      {
        path : 'machines',
        loadChildren : ()=>import('./machines/machine.module').then(m=>m.MachineModule)
      },
      {
        path : 'processes',
        loadChildren : ()=>import('./processes/process.module').then(m=>m.ProcessModule)
      },
      {path : '**', redirectTo : ''}
    ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BomsRoutingModule { }
