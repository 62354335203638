import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ProductionService } from 'src/app/api/services/production/production.service';
import { WarpedService } from 'src/app/api/services/production/warped/warped.service';

@Component({
  selector: 'app-production-warped-planning',
  templateUrl: './production-warped-planning.component.html',
  styleUrls: ['./production-warped-planning.component.css']
})
export class ProductionWarpedPlanningComponent implements OnInit {

  spinner = true;

  articulos: any = [
    // {
    //   id: 1,
    //   codigo: "0230",
    //   articulo: "BURGOS",
    //   tejeduria: "Tejedor 1",
    //   cantidad : 500,
    //   maquinas: [
    //     {
    //       id: 1,
    //       nombre: "Urdidor 1",
    //       cantidad: 2000,
    //     },
    //     {
    //       id: 2,
    //       nombre: "Urdidor 2",
    //       cantidad: 3000,
    //     }
    //   ],
    //   urdidoSelect: "",
    //   stock : [
    //     {
    //       id: 1,
    //       nombre: "Stock 1",
    //       cantidad: 500
    //     }
    //   ],
    //   claves: [
    //     {
    //       id: 1,
    //       nombre: "Clave 1",
    //       cantidad: 500
    //     }
    //   ]
    // },
    // {
    //   id: 2,
    //   codigo: "0230",
    //   articulo: "GRANADA",
    //   tejeduria: "Tejedor 2",
    //   cantidad : 500,
    //   maquina: "",
    //   maquinas: [
    //     {
    //       id: 3,
    //       nombre: "Urdidor 3",
    //       cantidad: 1600,
    //     },
    //     {
    //       id: 4,
    //       nombre: "Urdidor 4",
    //       cantidad: 2100,
    //     }
    //   ],
    //   urdidoSelect: "",
    //   stock : [
    //     {
    //       id: 2,
    //       nombre: "Stock 2",
    //       cantidad: 1000
    //     }
    //   ],
    //   claves: [
    //     {
    //       id: 2,
    //       nombre: "Clave 2",
    //       cantidad: 500
    //     }
    //   ]
    // },
    // {
    //   id: 3,
    //   codigo: "0230",
    //   articulo: "GIRONA",
    //   tejeduria            : "Tejedor 3",
    //   cantidad : 500,
    //   maquina: "",
    //   maquinas: [
    //     {
    //       id: 5,
    //       nombre: "Urdidor 5",
    //       cantidad: 1300,
    //     },
    //     {
    //       id: 6,
    //       nombre: "Urdidor 6",
    //       cantidad: 700,
    //     }
    //   ],
    //   urdidoSelect: "",
    //   stock : [
    //     {
    //       id: 3,
    //       nombre: "Stock 3",
    //       cantidad: 1500
    //     }
    //   ],
    //   claves: [
    //     {
    //       id: 3,
    //       nombre: "Clave 3",
    //       cantidad: 566
    //     }
    //   ]
    // }
  ]

  paginate: any = 1;

  totalRecords = 1;

  size = 50;

  constructor(
    private productionService: ProductionService,
    private messageService: MessageService,
    private warpedService: WarpedService
  ) { }

  ngOnInit(): void {
    this.getPedidosUrdido();

  }

  getPedidosUrdido() {
    this.warpedService.getPedidosUrdido().subscribe(({
      next: (data) => {
        this.size = data.totalElements;
        this.articulos = data.list;
      }, error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: err.error ? err.error.message : 'Ups! Ocurrio un error',
        });
      },
      complete: () => {
        this.spinner = false;
      }
    }))
  }


  paginateOrders(event: any) {
    this.paginate = event.page + 1;
    this.size = event.rows;
    this.spinner = true;
  }


}
